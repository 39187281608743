import React, { useEffect, useState } from "react";
import axios from "axios";
import RegistrationForm from "../globleForms/RegistrationForm";
import BusinessDetails from "../globleForms/BusinessDetails";
import TermsAndConditionsOfSale from "../globleForms/TermsAndConditionsOfSale";
import TemperatureRecordConfirmation from "./TemperatureRecordConfirmation";
import PharmacyRegistrationTeamForm from "./PharmacyRegistrationTeamForm";
import AdditionalForm from "./AdditionalForm";
import { toast } from "react-toastify";
import { api } from "../../../config/api";
import Navbar from "../../../globleComponents/Navbar";
import Footer from "../../../globleComponents/Footer";
import { token } from "../../../config/globleData";
import { useNavigate, useParams } from "react-router-dom";

// Validation function
const validateFormData = (data) => {
  const errors = {};
  const requiredFields = [
    "RegisteredCompanyName",
    "vatNo",
    "signature",
    "City",
    "CountryRegion",
    "Postcode",
    "wdl_number",
    "companyRegistrationNo",
    "businessCompanyType",
    "businessTelephoneNo",
    "businessAccountContactDept",
    "businessEmail",
    "superintendentPharmacistName",
    "superintendentPharmacistGPHCNumber",
    "residentPharmacistName",
    "residentPharmacistGPHCNumber",
    "residentPharmacistEmail",
    "completionDate",
    "termsAndConditionCheckbox",
    "signatureEmailAddress",
    "websiteURL",
    "directorFullName",
  ];

  requiredFields.forEach((field) => {
    if (!data[field]) {
      errors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
    }
  });

  return errors;
};

const PharmacyRegistration = () => {
  const [formData, setFormData] = useState({
    RegisteredCompanyName: "",
    Tradingname: "",
    Address1: "",
    Address2: "",
    City: "",
    CountryRegion: "",
    Postcode: "",
    wdl_number: "",
    DeliveryAddress: "",
    DeliveryAddress2: "",
    DeliveryCity: "",
    DeliveryPostcode: "",
    ApplicationFaxNo: "",
    ApplicantTelephoneNo: "",
    DeliveryPrimaryContactNo: "",
    CollectionAddress: "",
    CollectionAddress2: "",
    CollectionCity: "",
    CollectionPostcode: "",
    CollectionFaxNo: "",
    CollectionTelephoneNo: "",
    CollectionPrimaryContactNo: "",
    companyRegistrationNo: "",
    vatNo: "",
    businessCompanyType: "",
    businessTelephoneNo: "",
    shareholders: [""],
    directors: [""],
    businessAccountContactDept: "",
    businessEmail: "",
    signature: "",
    signatureEmailAddress: "",
    termsAndConditionCheckbox: false,
    superintendentPharmacistName: "",
    superintendentPharmacistGPHCNumber: "",
    residentPharmacistName: "",
    residentPharmacistGPHCNumber: "",
    residentPharmacistEmail: "",
    completionDate: "",
    files: {
      temperatureRecordDraft: null,
    },
    websiteURL: "",
    directorFullName: "",
    recentChangesToOwnership: "",
    detailsOfChanges: "",
    type: "pharmacy",
  });
  console.log("first")

  const [originalFiles, setOriginalFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api.pharmacyRegistrationsList}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFormData(response.data);
        setOriginalFiles(response.data.files || {}); // Save original file data
      } catch (error) {
        toast.error(
          "Error fetching data: " +
            (error.response?.data?.message || error.message)
        );
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate form data
  const validationErrors = validateFormData(formData);
  if (Object.keys(validationErrors).length > 0) {
    setErrors(validationErrors);
    setTouched({
      ...Object.keys(validationErrors).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {}),
    });
    return;
  }

  setIsSubmitting(true);

  try {
    const formDataappend = new FormData();

    // Append regular form data
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "files") {
        // Handle files separately
        Object.entries(value).forEach(([fileKey, file]) => {
          // Check if a new file is provided or use the original file
          if (file instanceof File && file.size > 0) {
            formDataappend.append(fileKey, file);
          } else if (originalFiles[fileKey]) {
            formDataappend.append(fileKey, originalFiles[fileKey]);
          }
        });
      } else if (Array.isArray(value)) {
        // Handle arrays (e.g., shareholders, directors)
        value.forEach((item, index) => {
          formDataappend.append(`${key}[${index}]`, item);
        });
      } else if (typeof value === "object" && value !== null) {
        // Handle nested objects
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (subValue instanceof File && subValue.size > 0) {
            formDataappend.append(`${key}[${subKey}]`, subValue);
          } else {
            formDataappend.append(`${key}[${subKey}]`, subValue);
          }
        });
      } else {
        formDataappend.append(key, value);
      }
    });

    // For debugging: log FormData entries
    for (let pair of formDataappend.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    // Determine API endpoint and method
    const apiEndpoint = id
      ? `${api.pharmacyRegistrationsList}/${id}`
      : api.CreatePharmacyRegistrations;
    const method = id ? "put" : "post";

    // Make the request with FormData
    const response = await axios({
      method,
      url: apiEndpoint,
      data: formDataappend,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Handle successful response
    toast.success(response.data.message);

    // Reset form data
    setFormData({
      RegisteredCompanyName: "",
      Tradingname: "",
      Address1: "",
      Address2: "",
      City: "",
      CountryRegion: "",
      Postcode: "",
      wdl_number: "",
      DeliveryAddress: "",
      DeliveryAddress2: "",
      DeliveryCity: "",
      DeliveryPostcode: "",
      DeliveryPrimaryContactNo: "",
      CollectionAddress: "",
      CollectionAddress2: "",
      CollectionCity: "",
      CollectionPostcode: "",
      CollectionFaxNo: "",
      CollectionTelephoneNo: "",
      CollectionPrimaryContactNo: "",
      companyRegistrationNo: "",
      vatNo: "",
      businessCompanyType: "",
      businessTelephoneNo: "",
      shareholders: [""],
      directors: [""],
      businessAccountContactDept: "",
      businessEmail: "",
      signature: "",
      signatureEmailAddress: "",
      termsAndConditionCheckbox: false,
      superintendentPharmacistName: "",
      superintendentPharmacistGPHCNumber: "",
      residentPharmacistName: "",
      residentPharmacistGPHCNumber: "",
      residentPharmacistEmail: "",
      completionDate: "",
      websiteURL: "",
      directorFullName: "",
      recentChangesToOwnership: "",
      detailsOfChanges: "",
      files: {
        temperatureRecordDraft: null,
      },
    });

    // Navigate after successful submission
    navigate(
      `/pharmacy-register-details/${response.data.pharmacyRegistration._id}`
    );
  } catch (error) {
    toast.error(
      "Error submitting form: " +
        (error.response?.data?.message || error.message)
    );
  } finally {
    setIsSubmitting(false);
  }
};

  
  return (
    <>
      <Navbar />
      <div className="background-container">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <RegistrationForm
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              title="Pharmacy Registration"
            />
            <BusinessDetails
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
            />
            <TermsAndConditionsOfSale
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              setErrors={setErrors}
              setTouched={setTouched}
            />
            <PharmacyRegistrationTeamForm
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
            />
            <AdditionalForm
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
            />
            <TemperatureRecordConfirmation
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
            />

            <div className="step-content-foot">
              <button
                type="submit"
                className="active out1"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Finish"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PharmacyRegistration;
