import React, { useState } from "react";

const Products = ({ handleChange }) => {
  return (
    <div>
      <div
        className="products-details-section tabs-edits-section"
        style={{ display: "block" }}
      >
        <h3 className="form-main-title">Products</h3>
        <p className="form-body-title">
          Propharma-UK Ltd is Only Licenced to Wholesale the Following Product
          Categories: P, POM, CD and GSL.
        </p>
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <div className="sops-title-group">
              <span>Yes</span>
              <span>No</span>
              <span>N/A</span>
            </div>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              1. Please confirm that you only supply products which are not
              restricted for export from the original country of origin and that
              any of your suppliers are authorised suppliers within the EEA who
              themselves maintain a good product traceability? (Supplier Use
              Only)
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL16"
                  className="radio-check"
                  id="product_yes_1"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="product_yes_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL16"
                  className="radio-check"
                  id="product_no_1"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="product_no_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL16"
                  className="radio-check"
                  id="product_na_1"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="product_na_1"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              2. Are all products checked for counterfeit issues?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL17"
                  className="radio-check"
                  id="product_yes_2"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="product_yes_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL17"
                  className="radio-check"
                  id="product_no_2"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="product_no_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL17"
                  className="radio-check"
                  id="product_na_2"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="product_na_2"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              3. Do you have a procedure in place for the recognition and
              documentation of identified or suspected falsified medicinal
              products?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL18"
                  className="radio-check"
                  id="product_yes_3"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="product_yes_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL18"
                  className="radio-check"
                  id="product_no_3"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="product_no_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL18"
                  className="radio-check"
                  id="product_na_3"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="product_na_3"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              4. Do you operate a quarantine system?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL19"
                  className="radio-check"
                  id="product_yes_4"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="product_yes_4"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL19"
                  className="radio-check"
                  id="product_no_4"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="product_no_4"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL19"
                  className="radio-check"
                  id="product_na_4"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="product_na_4"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              5. Do you maintain a product traceability system?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL20"
                  className="radio-check"
                  id="product_yes_5"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="product_yes_5"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL20"
                  className="radio-check"
                  id="product_no_5"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="product_no_5"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL20"
                  className="radio-check"
                  id="product_na_5"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="product_na_5"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
