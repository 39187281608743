import { message } from "antd";
import { downloadPDF, formatDate, token } from "../../../../../config/globleData";
import { api } from "../../../../../config/api";
import { Link } from "react-router-dom";

export const privateClinicColumn = [
    // {
    //   title: "Sr No.",
    //   key: "srNo",
    //   render: (text, record, index) =>
    //     (currentPage - 1) * entriesPerPage + index + 1,
    //   align: "center",
    // },
    {
      title: "Clinic Name",
      dataIndex: "clinicName",
      key: "clinicName",
      render: (text, record) => (
        <div>
          <Link style={{ color: "#408dfb" }} to={`/admin/private-clinics/${record._id}`}>
            {record.clinicName}
          </Link>
        </div>
      ),  
      sorter: (a, b) => a.clinicName?.localeCompare(b.clinicName),
      align: "center",
    },
    {
      title: "Trading Name",
      dataIndex: "tradingAs",
      key: "tradingAs",
      sorter: (a, b) => a.tradingAs?.localeCompare(b.tradingAs),
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "accountsEmail",
      key: "accountsEmail",
      sorter: (a, b) => a.accountsEmail?.localeCompare(b.accountsEmail),
      align: "center",
    },
    {
      title: "Date Registered",
      key: "dateRegistered",
      render: (text, record) => <div>{formatDate(record.completionDate)}</div>,
      sorter: (a, b) => new Date(a.completionDate) - new Date(b.completionDate),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <div>
          <span
            onClick={() => {
              message.open({
                type: "loading",
                content: "Wait for the download of Private Clinic Registration",
              });
              downloadPDF(
                `${api.privateClinicsList}/${text._id}/download`,
                `Private_Clinic_Registration_${text._id}.pdf`,
                token
              ).finally(() => {
                setTimeout(() => {
                  message.open({
                    type: "success",
                    content: "Downloaded Successfully",
                    duration: 2,
                  });
                }, 1000);
              });
            }}
            style={{ cursor: "pointer", marginRight: "8px" }}
          >
          <span style={{ color: "#408dfb" }}>Download</span>
            {/* <i className="fa fa-file-pdf-o" aria-hidden="true"></i> */}
          </span>
          {/* <Link to={`/admin/private-clinics/${text._id}`}>
            <i className="fa fa-eye"></i>
          </Link> */}
        </div>
      ),
      align: "center",
    },
  ];
