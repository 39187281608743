import React from "react";
import Navbar from "../globleComponents/Navbar";
import Services from "../globleComponents/Services";
import CarouselSlider from "../globleComponents/CarouselSlider";
import ContactUS from "../globleComponents/ContactUS";
import Footer from "../globleComponents/Footer";

const Home = () => {
  return (
    <div class="overflow-x-h">
      <Navbar />
      <Services />
      <CarouselSlider />
      <ContactUS />
      <Footer />
    </div>
  );
};

export default Home;
