import axios from "axios";
import { api } from "./api";

// export const removeToken = localStorage.removeItem("token");
export const token = localStorage.getItem("token");

export const countries = [
  { value: "UK", label: "United Kingdom" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "AT", label: "Austria" },
  { value: "AU", label: "Australia" },
  { value: "BE", label: "Belgium" },
  { value: "BG", label: "Bulgaria" },
  { value: "BR", label: "Brazil" },
  { value: "CA", label: "Canada" },
  { value: "CH", label: "Switzerland" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DE", label: "Germany" },
  { value: "DK", label: "Denmark" },
  { value: "DZ", label: "Algeria" },
  { value: "EE", label: "Estonia" },
  { value: "EL", label: "Greece" },
  { value: "ES", label: "Spain" },
  { value: "EU", label: "EU countries" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GB", label: "United Kingdom" },
  { value: "GH", label: "Ghana" },
  { value: "GR", label: "Greece" },
  { value: "HR", label: "Croatia" },
  { value: "HU", label: "Hungary" },
  { value: "ID", label: "Indonesia" },
  { value: "IE", label: "Ireland" },
  { value: "IN", label: "India" },
  { value: "IS", label: "Iceland" },
  { value: "IT", label: "Italy" },
  { value: "KE", label: "Kenya" },
  { value: "LT", label: "Lithuania" },
  { value: "LV", label: "Latvia" },
  { value: "MO", label: "Morocco" },
  { value: "MT", label: "Malta" },
  { value: "MX", label: "Mexico" },
  { value: "MY", label: "Malaysia" },
  { value: "MZ", label: "Mozambique" },
  { value: "NG", label: "Nigeria" },
  { value: "NL", label: "Netherlands / Holland" },
  { value: "NO", label: "Norway" },
  { value: "NTH", label: "Netherlands" },
  { value: "NZ", label: "New Zealand" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "SE", label: "Sweden" },
  { value: "SG", label: "Singapore" },
  { value: "SI", label: "Slovenia" },
  { value: "SK", label: "Slovakia" },
  { value: "SZ", label: "Switzerland" },
  { value: "TH", label: "Thailand" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TZ", label: "Tanzania" },
  { value: "UG", label: "Uganda" },
  { value: "US", label: "USA" },
  { value: "ZA", label: "South Africa" },
];

export const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle cases where dateString is undefined or empty

  const dateObj = new Date(dateString);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero indexed
  const year = dateObj.getFullYear();

  return `${day}/${month}/${year}`;
};

// Define the link data
export const registerLinks = [
  // {
  //   to: "/wholesaler-register-form",
  //   imgSrc: "https://proph.co.uk/public/assets/images/donation.png",
  //   altText: "Customer",
  //   text: "Customer",
  // },
  // {
  //   to: "/wholesaler-supplier-register-form",
  //   imgSrc: "https://proph.co.uk/public/assets/images/donation-(1).png",
  //   altText: "Supplier",
  //   text: "Supplier",
  // },
  // {
  //   to: "/wholesaler-customer-supplier-register-form",
  //   imgSrc: "https://proph.co.uk/public/assets/images/group.png",
  //   altText: "Customer/Supplier",
  //   text: "Customer/Supplier",
  // },
  // {
  //   to: "/wholesale-validation-questionnaire",
  //   imgSrc: "https://proph.co.uk/public/assets/images/customer-service.png",
  //   altText: "Wholesale Validation Questionnaire",
  //   text: "Wholesale Validation Questionnaire",
  // },
  {
    to: "/pharmacy-registration",
    imgSrc: "https://proph.co.uk/public/assets/images/pharmacy.png",
    altText: "Pharmacy",
    text: "Pharmacy",
  },
  // {
  //   to: "/wholesaler-gdp-register-form",
  //   imgSrc: "https://proph.co.uk/public/assets/images/gdp-registration.png",
  //   altText: "GDP Registration",
  //   text: "GDP Registration",
  // },
  // {
  //   to: "/travel-clinic-register-form",
  //   imgSrc: "https://proph.co.uk/public/assets/images/travel.png",
  //   altText: "Travel Clinics",
  //   text: "Travel Clinics",
  // },
  // {
  //   to: "/aesthetic-clinic-register-form",
  //   imgSrc: "https://proph.co.uk/public/assets/images/aesthetic.png",
  //   altText: "Aesthetic Clinics",
  //   text: "Aesthetic Clinics",
  // },
  // {
  //   to: "/aesthetic-stock-register-form",
  //   imgSrc: "https://proph.co.uk/public/assets/images/aesthetic-stock.png",
  //   altText: "Aesthetic Clinics (Stock)",
  //   text: "Aesthetic Clinics (Stock)",
  // },
  {
    to: "/GP-surgery-registration",
    imgSrc: "https://proph.co.uk/public/assets/images/gdp-registration.png",
    altText: "GP Surgery Registration",
    text: "GP Surgery",
  },
  {
    to: "/private-clinics",
    imgSrc: "https://proph.co.uk/public/assets/images/travel.png",
    altText: "Private Clinics",
    text: "Private Clinics",
  },
];

//Carousel Slider data

export const options = {
  items: 4,
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  navText: [
    "<img src='https://proph.co.uk/public/front/assets/images/br_prev.png' alt='Prev' />",
    "<img src='https://proph.co.uk/public/front/assets/images/br_next.png' alt='Next' />",
  ],
};

export const clientLogos = [
  "https://proph.co.uk/public/front/assets/images/clients-logo/464006bd.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/581654teva.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/727126sanofi.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/737771fresenius-kabi.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/779746bayer-.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/914097convatec.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/123621roche.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/127255pfizer.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/131792welch-allyn.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/141906ABBOTT-LOGO.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/212831bristol.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/283433allergan.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/301854astra.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/302282novartis.png",
  "https://proph.co.uk/public/front/assets/images/clients-logo/422062bard.png",
];

// Define the service data
export const services = [
  {
    className: "service1",
    title: "Wholesale Pharmaceuticals",
    description:
      "We offer a range of products and services to wholesale pharmaceutical companies. from our full product range through to sourcing products.",
    link: "/services",
    linkText: "Read More",
    isExternal: false,
  },
  {
    className: "service2",
    title: "Retail Medicines & Vaccines",
    description:
      "We work alongside and supply a number of independent pharmacies, those with a wholesale operation and pharmacy groups.",
    link: "/services",
    linkText: "Read More",
    isExternal: false,
  },
  {
    className: "service3",
    title: "Aesthetic Medical Devices & Consumables",
    description:
      "From Nursing Supplies, wound care and surgical consumables to Dermal fillers, Botox and Mesotherapy products. Propharma supplies a number of clinics",
    link: "/services",
    linkText: "Read More",
    isExternal: false,
  },
  {
    className: "service4",
    title: "MyLocalTest - Blood Testing",
    description:
      "Experience a seamless and efficient blood testing journey in the UK with our innovative digital platform, revolutionizing the way you manage your health and well-being.",
    link: "https://mylocaltest.co.uk",
    linkText: "Visit Site",
    isExternal: true,
  },
];

export const downloadPDF = async (url, fileName, token) => {
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "application/pdf" });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(downloadUrl); // Clean up
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};

export const viewPDF = async (url) => {
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "application/pdf" });
    const viewUrl = window.URL.createObjectURL(blob);
    window.open(viewUrl, "_blank"); // Open PDF in a new tab
    window.URL.revokeObjectURL(viewUrl); // Clean up
  } catch (error) {
    console.error("Error viewing PDF:", error);
  }
};

export const getFileByType = async (id, type) => {
  try {
    const response = await axios.get(
      `${api.baseUrl}/api/registrations/private-clinics/${id}/files/${type}`,
      {
        responseType: "blob", // important for file downloads
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching file:", error);
    throw error;
  }
};

export const getRegionDisplayName = (regionCode) => {
  try {
    // Create an instance of Intl.DisplayNames for regions
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

    // Get the display name for the specified region code
    const displayName = regionNames.of(regionCode);

    if (!displayName) {
      throw new Error(`No display name found for region code: ${regionCode}`);
    }

    return displayName;
  } catch (error) {
    console.error("Error retrieving display name:", error);
    return null;
  }
};
