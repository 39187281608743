import React from "react";
import Navbar from "../globleComponents/Navbar";
import ContactUS from "../globleComponents/ContactUS";
import Footer from "../globleComponents/Footer";

const About = () => {
  return (
    <div class="overflow-x-h">
      <Navbar />
      <div class="container" style={{ marginTop: "30px" }}>
        <div class="row aboutusdv">
          <div class="col-lg-12">
            <h1>About ProPharma</h1>

            <p>
              Propharma based in London, UK is a pharmaceutical wholesaler with
              over 35 years of market expertise in the pharmaceuticals sector.
            </p>

            <p>
              <strong>
                Our operation is committed to supply the needs of medical
                professionals in the industry, from covering UK Ethicals,
                Parallel Import, Branded and Generic medicines, through to Wound
                Care, Diagnostics and Hospital equipment and most recently
                Aesthetic meical devices such as Dermal Fillers, Mesotherapy,
                PDO Threads and Orthopedic products, also Botox.
              </strong>
            </p>

            <p>
              We cater to the needs of several types of client and our
              geographic reach is the UK, EU and beyond. Propharma is open for
              business, please feel free to contact us.
            </p>

            <h1>Our Mission</h1>

            <p>
              <strong>
                "Our vision is to create an entity that evolves with an ever
                changing marketplace where knowledge of our products and
                services is a benefit to our customers.
              </strong>{" "}
              By working with an ethos of honesty, integrity, trust, innovation
              and dependability, which are at the heart of our core values and
              shared by each and every staff member, individually and as a
              corporate identity.{" "}
              <strong>We are here to serve our clients."</strong>
            </p>
          </div>
        </div>
      </div>
      <ContactUS />
      <Footer />
    </div>
  );
};

export default About;
