import React from "react";

const GPSurgeryRegistrationForm = ({
  formData,
  handleChange,
  errors,
  touched,
}) => {
  return (
    <div className="formdv container">
      <p className="formdetailstitle">
        <span>GP Surgery Registration</span>
      </p>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="surgeryName" className="mat-label">
            Surgery Name
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.surgeryName && touched.surgeryName ? "is-invalid" : ""
            }`}
            id="surgeryName"
            name="surgeryName"
            placeholder=""
            value={formData.surgeryName}
            onChange={handleChange}
          />
          {errors.surgeryName && touched.surgeryName && (
            <span className="loaddata">{errors.surgeryName}</span>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 form-group right_col">
          <label htmlFor="Address1" className="mat-label">
            Address 1
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.Address1 && touched.Address1 ? "is-invalid" : ""
            }`}
            id="Address1"
            name="Address1"
            placeholder=""
            value={formData.Address1}
            onChange={handleChange}
          />
          {errors.Address1 && touched.Address1 && (
            <span className="loaddata">{errors.Address1}</span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="Postcode" className="mat-label">
            Postcode
          </label>
          <input
            type="number"
            className={`form-control mat-input ${
              errors.Postcode && touched.Postcode ? "is-invalid" : ""
            }`}
            id="Postcode"
            name="Postcode"
            placeholder=""
            value={formData.Postcode}
            onChange={handleChange}
          />
          {errors.Postcode && touched.Postcode && (
            <span className="loaddata">{errors.Postcode}</span>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 form-group right_col">
          <label htmlFor="ownedBy" className="mat-label">
            Owned By
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.ownedBy && touched.ownedBy ? "is-invalid" : ""
            }`}
            id="ownedBy"
            name="ownedBy"
            placeholder=""
            value={formData.ownedBy}
            onChange={handleChange}
          />
          {errors.ownedBy && touched.ownedBy && (
            <span className="loaddata">{errors.ownedBy}</span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="tradingAs" className="mat-label">
            Trading As
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.tradingAs && touched.tradingAs ? "is-invalid" : ""
            }`}
            id="tradingAs"
            name="tradingAs"
            placeholder=""
            value={formData.tradingAs}
            onChange={handleChange}
          />
          {errors.tradingAs && touched.tradingAs && (
            <span className="loaddata">{errors.tradingAs}</span>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 form-group right_col">
          <label htmlFor="websiteURL" className="mat-label">
            Website URL
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.websiteURL && touched.websiteURL ? "is-invalid" : ""
            }`}
            id="websiteURL"
            name="websiteURL"
            placeholder=""
            value={formData.websiteURL}
            onChange={handleChange}
          />
          {errors.websiteURL && touched.websiteURL && (
            <span className="loaddata">{errors.websiteURL}</span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="vatNo" className="mat-label">
            VAT No
          </label>
          <input
            type="number"
            className={`form-control mat-input ${
              errors.vatNo && touched.vatNo ? "is-invalid" : ""
            }`}
            id="vatNo"
            name="vatNo"
            placeholder=""
            value={formData.vatNo}
            onChange={handleChange}
          />
          {errors.vatNo && touched.vatNo && (
            <span className="loaddata">{errors.vatNo}</span>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 form-group right_col">
          <label htmlFor="companyRegistrationNo" className="mat-label">
            Company Registration No
          </label>
          <input
            type="number"
            className={`form-control mat-input ${
              errors.companyRegistrationNo && touched.companyRegistrationNo
                ? "is-invalid"
                : ""
            }`}
            id="companyRegistrationNo"
            name="companyRegistrationNo"
            placeholder=""
            value={formData.companyRegistrationNo}
            onChange={handleChange}
          />
          {errors.companyRegistrationNo && touched.companyRegistrationNo && (
            <span className="loaddata">{errors.companyRegistrationNo}</span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="CQCRegistrationName" className="mat-label">
            CQC Registration Name
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.CQCRegistrationName && touched.CQCRegistrationName
                ? "is-invalid"
                : ""
            }`}
            id="CQCRegistrationName"
            name="CQCRegistrationName"
            placeholder=""
            value={formData.CQCRegistrationName}
            onChange={handleChange}
          />
          {errors.CQCRegistrationName && touched.CQCRegistrationName && (
            <span className="loaddata">{errors.CQCRegistrationName}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GPSurgeryRegistrationForm;
