import React from "react";

const BusinessDetails = (props) => {
  const { formData, setFormData, errors, touched, handleChange } = props;

  const handleShareholderChange = (index, value) => {
    const updatedShareholders = [...formData.shareholders];
    updatedShareholders[index] = value;
    setFormData({
      ...formData,
      shareholders: updatedShareholders,
    });
  };

  const handleDirectorChange = (index, value) => {
    const updatedDirectors = [...formData.directors];
    updatedDirectors[index] = value;
    setFormData({
      ...formData,
      directors: updatedDirectors,
    });
  };

  const handleAddShareholder = () => {
    setFormData({
      ...formData,
      shareholders: [...formData.shareholders, ""],
    });
  };

  const handleRemoveShareholder = (index) => {
    const updatedShareholders = [...formData.shareholders];
    updatedShareholders.splice(index, 1);
    setFormData({
      ...formData,
      shareholders: updatedShareholders,
    });
  };

  const handleAddDirector = () => {
    setFormData({
      ...formData,
      directors: [...formData.directors, ""],
    });
  };

  const handleRemoveDirector = (index) => {
    const updatedDirectors = [...formData.directors];
    updatedDirectors.splice(index, 1);
    setFormData({
      ...formData,
      directors: updatedDirectors,
    });
  };

  return (
    <div id="step4" className="step-content-body out1">
      <div className="formdv container">
        <p className="formdetailstitle">
          <span>Business Details</span>
        </p>
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="CompanyRegistrationNo" className="mat-label">
              Ltd Company Registration No
            </label>
            <input
              className="form-control mat-input"
              value={formData.companyRegistrationNo}
              onChange={handleChange}
              id="CompanyRegistrationNo"
              name="companyRegistrationNo"
              type="text"
              placeholder=""
            />
            {errors.companyRegistrationNo && touched.companyRegistrationNo && (
              <span className="loaddata">{errors.companyRegistrationNo}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="VatNo" className="mat-label">
              VAT No
            </label>
            <input
              className="form-control mat-input"
              name="vatNo"
              value={formData.vatNo}
              onChange={handleChange}
              id="VatNo"
              type="text"
              placeholder=""
            />
            {errors.vatNo && touched.vatNo && (
              <span className="loaddata">{errors.vatNo}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div
            className="col-xs-12 col-sm-6 form-group left_col is-completed"
            id="BusinessCompanyType"
          >
            <label htmlFor="CountryRegion" className="mat-label">
              Business Company Type
            </label>
            <select
              className="form-control mat-input select_opt"
              id="CountryRegion"
              name="businessCompanyType"
              value={formData.businessCompanyType}
              onChange={handleChange}
            >
              <option value="PLC">PLC</option>
              <option value="Sole Trader">Sole Trader</option>
              <option value="Limited Company">Limited Company</option>
              <option value="Partnership">Partnership</option>
            </select>
            {errors.businessCompanyType && touched.businessCompanyType && (
              <span className="loaddata">{errors.businessCompanyType}</span>
            )}
          </div>
          <div
            className="col-xs-12 col-sm-6 form-group right_col"
            id="regBusinessTelephoneNo"
          >
            <label htmlFor="BusinessTelephoneNo" className="mat-label">
              Telephone No*
            </label>
            <input
              type="tel"
              className="form-control mat-input"
              value={formData.businessTelephoneNo}
              onChange={handleChange}
              id="BusinessTelephoneNo"
              name="businessTelephoneNo"
              placeholder=""
            />
            {errors.businessTelephoneNo && touched.businessTelephoneNo && (
              <span className="loaddata">{errors.businessTelephoneNo}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12" style={{ padding: "0px" }}>
            <div className="col-md-6 col-sm-6 input_fields_wrap form-group left_col">
              <i
                className="glyphicon glyphicon-plus add_field_button"
                onClick={handleAddShareholder}
              ></i>{" "}
              <span
                style={{ color: "#008000", cursor: "pointer" }}
                onClick={handleAddShareholder}
              >
                Add More
              </span>
              <label
                htmlFor="BusinessFullNamesShareHolders"
                className="mat-label"
              >
                Full Name of Shareholder / Partner / Proprietor
              </label>
              <input
                type="text"
                placeholder=""
                id="BusinessFullNamesShareHolders"
                className="form-control mat-input"
                value={formData.shareholders[0]}
                onChange={(e) => handleShareholderChange(0, e.target.value)}
              />
              {errors.shareholders && touched.shareholders && (
                <span className="loaddata">{errors.shareholders[0]}</span>
              )}
            </div>
            <div className="col-md-6 col-sm-6 input_fields_wrap1 form-group right_col">
              <i
                className="glyphicon glyphicon-plus add_field_button1"
                onClick={handleAddDirector}
              ></i>
              <span
                style={{ color: "#008000", cursor: "pointer" }}
                onClick={handleAddDirector}
              >
                Add More
              </span>
              <label htmlFor="BusinessFullNamesDirectors" className="mat-label">
                Full Name of Director
              </label>
              <input
                type="text"
                placeholder=""
                className="form-control mat-input"
                value={formData.directors[0]}
                onChange={(e) => handleDirectorChange(0, e.target.value)}
                name="BusinessFullNamesDirectors[]"
                id="BusinessFullNamesDirectors"
              />
              {errors.directors && touched.directors && (
                <span className="loaddata">{errors.directors[0]}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 col-sm-12" style={{ padding: "0px" }}>
            <div className="col-md-6 col-sm-6 input_fields_wrap0 form-group">
              {formData.shareholders.slice(1).map((shareholder, index) => (
                <div
                  key={index}
                  className="col-md-6 col-sm-6 right-inner-addon is-active"
                >
                  <label
                    htmlFor={`BusinessFullNamesShareHolders_${index}`}
                    className="mat-label"
                  >
                    Full Name of Shareholder / Partner / Proprietor
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    id={`BusinessFullNamesShareHolders_${index}`}
                    className="form-control mat-input mat-input2"
                    name="BusinessFullNamesShareHolders"
                    value={shareholder}
                    onChange={(e) =>
                      handleShareholderChange(index + 1, e.target.value)
                    }
                  />
                  <i
                    className="glyphicon glyphicon-remove remove_field"
                    title="Remove This Name"
                    onClick={() => handleRemoveShareholder(index + 1)}
                  ></i>
                  {errors.shareholders && touched.shareholders && (
                    <span className="loaddata">
                      {errors.shareholders[index + 1]}
                    </span>
                  )}
                </div>
              ))}
            </div>

            <div className="col-md-6 col-sm-6 input_fields_wrap2 form-group">
              {formData.directors.slice(1).map((director, index) => (
                <div
                  key={index}
                  className="col-md-6 col-sm-6 right-inner-addon my_addon is-active"
                >
                  <label
                    htmlFor={`BusinessFullNamesDirectors_${index}`}
                    className="mat-label"
                  >
                    Full Name of Director
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    className="form-control mat-input"
                    id={`BusinessFullNamesDirectors_${index}`}
                    value={director}
                    onChange={(e) =>
                      handleDirectorChange(index + 1, e.target.value)
                    }
                  />
                  <i
                    className="glyphicon glyphicon-remove remove_field1"
                    title="Remove This Name"
                    onClick={() => handleRemoveDirector(index + 1)}
                  ></i>
                  {errors.directors && touched.directors && (
                    <span className="loaddata">
                      {errors.directors[index + 1]}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-sm-6 form-group left_col"
            id="regBusinessAccountContactDept"
          >
            <label htmlFor="BusinessAccountContactDept" className="mat-label">
              Contact Name in Accounts Department*
            </label>
            <input
              type="text"
              id="BusinessAccountContactDept"
              className="form-control mat-input"
              value={formData.businessAccountContactDept}
              onChange={handleChange}
              name="businessAccountContactDept"
              data-bvalidator="required"
              data-bvalidator-msg="Please enter account department name"
              placeholder=""
            />
            {errors.businessAccountContactDept &&
              touched.businessAccountContactDept && (
                <span className="loaddata">
                  {errors.businessAccountContactDept}
                </span>
              )}
          </div>
          <div className="col-sm-6 form-group right_col">
            <label htmlFor="BusinessEmail" className="mat-label">
              Email
            </label>
            <input
              type="text"
              className="form-control mat-input"
              id="BusinessEmail"
              value={formData.businessEmail}
              onChange={handleChange}
              name="businessEmail"
              placeholder=""
            />
            {errors.businessEmail && touched.businessEmail && (
              <span className="loaddata">{errors.businessEmail}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
