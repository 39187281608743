import React from "react";
import Navbar from "../globleComponents/Navbar";
import Footer from "../globleComponents/Footer";

const CookieAndWebsitePolicy = () => {
  return (
    <>
      <Navbar />
      <div class="container">
        <div class="row aboutusdv">
          <div class="col-lg-12">
            <h1>Cookie and Website Usage Policy </h1>
            <p>
              The following statement discloses the privacy practices of
              Propharma-UK Limited (“Propharma-UK”) for the ProPharma-UK Web
              Site (this “Site”). By using this Site, you are consenting to our
              privacy practices. If you do not consent to these privacy
              practices, do not use this Site or download any materials from it.{" "}
            </p>
            <h4>Your Privacy</h4>
            <h4>
              Information we collect when you visit ProPharma-UK – Use of
              Cookies
            </h4>
            <p>
              We may collect information automatically when you visit the
              Website, using cookies.{" "}
            </p>
            <p>
              Cookies are small files of information which save and retrieve
              information about your visit to our site – for example, how you
              entered our site, how you navigated through the site, and what
              information was of interest to you. The cookies we use identify
              you merely as a number. (If you are uncomfortable regarding
              cookies use, please keep in mind you can disable cookies on your
              computer by changing the settings in preferences or options menu
              in your browser, however your login ability on the website may be
              impaired.) We also collect information when you give it to us. For
              example the registration details you have entered in order to
              access the member area of the website.{" "}
            </p>
            <h4>Controlling the use of cookies </h4>
            <p>
              These links explain how you can control cookies via your browser –
              remember that if you turn off cookies in your browser then these
              settings apply to all websites not just this one:{" "}
            </p>
            <ul>
              <li>
                <strong>Internet Explorer </strong>
                <a
                  href="http://support.microsoft.com/kb/278835"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  http://support.microsoft.com/kb/278835
                </a>{" "}
                (this page links to further information for different versions
                of IE – the mobile version is at{" "}
                <a
                  href="http:/ /www.microsoft.com/windowsphone/en-us/howto/wp7/web/changing-privacy-and-otherbrowser-settings.aspx"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  http:/
                  /www.microsoft.com/windowsphone/en-us/howto/wp7/web/changing-privacy-and-otherbrowser-settings.aspx
                </a>
                ).
              </li>
              <li>
                <strong>Chrome:</strong>
                <a
                  href="https://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647
                </a>
              </li>
              <li>
                <strong>Safari:</strong>
                <a
                  href="http://docs.info.apple.com/article.html?path=Safari/5.0/en/9277.html"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://docs.info.apple.com/article.html?path=Safari/5.0/en/9277.html
                </a>{" "}
                ( or{" "}
                <a
                  href="http://support.apple.com/kb/HT1677"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  http://support.apple.com/kb/HT1677
                </a>{" "}
                for mobile versions){" "}
              </li>
              <li>
                <strong>Firefox:</strong>
                <a
                  href="http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies
                </a>
              </li>
              <li>
                <strong>Blackberries:</strong>
                <a
                  href="http://docs.blackberry.com/en/32004/Turn_off_cookies_in_the_browser_60_1072866_11.jsp"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://docs.blackberry.com/en/32004/Turn_off_cookies_in_the_browser_60_1072866_11.jsp
                </a>
              </li>
              <li>
                <strong>Android:</strong>
                <a
                  href="https://support.google.com/mobile/bin/answer.py?hl=en&amp;answer=169022"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://support.google.com/mobile/bin/answer.py?hl=en&amp;answer=169022
                </a>
              </li>
              <li>
                <strong>Opera:</strong>
                <a
                  href="http://www.opera.com/browser/tutorials/security/privacy/"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://www.opera.com/browser/tutorials/security/privacy/
                </a>
              </li>
            </ul>
            <p>
              For more information visit{" "}
              <a
                href="www.allaboutcookies.org/manage-cookies"
                style={{ color: "#008000" }}
                data-original-title=""
                title=""
              >
                www.allaboutcookies.org/manage-cookies
              </a>
            </p>
            <h4>
              Types of cookie that may be used during your visit to the Website:
            </h4>
            <p>The following types of cookie are used on this site. </p>
            <h4>1. Analytics cookies</h4>
            <p>
              These monitor how visitors move around the Website and how they
              reached it. This is used so that we can see total (not individual)
              figures on which types of content users enjoy most, for instance.{" "}
            </p>
            <p>You can opt out of these if you choose: </p>
            <ul>
              <li>
                Google:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
              </li>
            </ul>
            <h4>2. Third-party service cookies </h4>
            <p>
              Social sharing, video and other services we offer are run by other
              companies. These companies may drop cookies on your computer when
              you use them on our site or if you are already logged in to them.{" "}
            </p>
            <p>
              Here is a list of places where you can find out more about
              specific services that we may use and their use of cookies:{" "}
            </p>
            <ul>
              <li>
                <strong>Facebook’s data use policy:</strong>
                <a
                  href=" http://www.facebook.com/about/privacy/your-info-on-other"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://www.facebook.com/about/privacy/your-info-on-other
                </a>
              </li>
              <li>
                <strong>Twitter’s privacy policy:</strong>
                <a
                  href=" https://twitter.com/privacy"
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  https://twitter.com/privacy
                </a>
              </li>
              <li>
                <strong>YouTube video player cookie policy:</strong>
                <a
                  href="https://www.google.com/intl/en/policies/privacy/faq/#toc-cookies(Google’s standard terms)."
                  style={{ color: "#008000" }}
                  data-original-title=""
                  title=""
                >
                  {" "}
                  http://www.google.com/intl/en/policies/privacy/faq/#toc-cookies(Google’s
                  standard terms).{" "}
                </a>
              </li>
            </ul>

            <h4>3.Site management cookies </h4>
            <p>
              These are used to maintain your identity or session on the
              Website. For instance, where our websites run on more than one
              server, we use a cookie to ensure that you are sent information by
              one specific server (otherwise you may log in or out
              unexpectedly). We may use similar cookies when you vote in opinion
              polls to ensure that you can only vote once, and to ensure that
              you can use our commenting functionality when not logged in (to
              ensure you don’t see comments you’ve reported as abusive, for
              instance, or don’t vote comments up/down more than once).{" "}
            </p>
            <p>
              These cookies cannot be turned off individually, but you could
              change your browser setting to refuse all cookies (see above) if
              you do not wish to accept them.{" "}
            </p>

            <h4>How do we use this information? </h4>
            <p>
              You can browse our sites without telling us who you are or
              revealing any personal information about yourself. Once you give
              us your personal information, you are not anonymous to us. If you
              choose to provide us with personal information and continue to use
              this website, you consent to the transfer and storage of that
              information on our servers located in the United Kingdom.{" "}
            </p>
            <p>We may collect and store the following personal information: </p>

            <ul>
              <li>
                Email, physical contact information, and (depending on
                the service used) sometimes financial information, such as
                credit card or bank account numbers;{" "}
              </li>
              <li>
                transactional information based on your activities on the sites
                (such as buying insurance or items from our webshop);{" "}
              </li>
              <li>
                shipping, billing and other information you provide to purchase
                or ship an item;{" "}
              </li>
              <li>
                community discussions, chats, feedback, correspondence through
                our sites, and correspondence sent to us;{" "}
              </li>
              <li>
                other information from your interaction with our sites,
                services, content and advertising, including computer and
                connection information, statistics on page views, traffic to and
                from the sites, ad data, and the IP address.{" "}
              </li>
              <li>
                information from other companies, such as demographic and
                navigation data.{" "}
              </li>
            </ul>

            <h4>
              Will we disclose your information to or share it with other
              organisations?
            </h4>
            <p>
              We may use personal data collected from our website from time to
              time, please read our{" "}
              <a
                href="https://www.goog.com/"
                style={{ color: "#008000" }}
                data-original-title=""
                title=""
              >
                data protection notice
              </a>{" "}
              for full details.{" "}
            </p>
            <p>
              We may also combine your information with information on other
              users and information we collect from other companies and use it
              to improve and personalise our services, content and advertising.{" "}
            </p>

            <h4>Use</h4>
            <p>
              Our primary purpose in collecting personal information is to
              provide you with a smooth, efficient, and customised experience.
              You agree that we may use your personal information to:{" "}
            </p>
            <ul>
              <li>provide the services and customer support you request;</li>
              <li>
                customise, measure and improve our services, content and
                advertising;{" "}
              </li>
              <li>
                tell you about our services, targeted marketing, service
                updates, and promotional offers based on your communication
                preferences; and{" "}
              </li>
              <li>
                compare information for accuracy, and verify it with third
                parties.{" "}
              </li>
            </ul>

            <h4>Use of Cookies</h4>
            <p>
              A cookie is a small piece of data sent by our computer to yours so
              that information can be recalled.{" "}
            </p>
            <p>
              A cookie is stored by your web browser in a text file on your
              computer with the word ‘cookie’ in its name. Each time your web
              browser requests a page from our web server, the cookie’s message
              is sent back. Only ProPharma-UK web server may read the contents
              of a cookie you accept from us.{" "}
            </p>
            <p>ProPharma-UK uses cookies in the following ways: </p>
            <ul>
              <li>
                one of our security measures to ensure your login session is
                secure. This is a non-persistent cookie while you are signed on
                to the ProPharma-UK website.
              </li>
            </ul>
            <p>You are offered this cookie on login page.</p>
            <ul>
              <li>
                to track how users reach our site and the path they take through
                it to help us to improve our service to you.{" "}
              </li>
            </ul>
            <p>
              When we use a cookie for this purpose, we do not record any
              information that will uniquely identify you. We put together the
              information from a very large number of users of our website to
              give us an overall picture of how people visit our site.{" "}
            </p>

            <ul>
              <li>
                from time to time we may wish to record personal information
                about you using cookies. This can only be done once you have
                registered and logged in to our site.
              </li>
            </ul>

            <p>
              You may choose to set your browser to not accept any cookies.
              Please be aware that in doing this, the site may not operate
              correctly.
            </p>

            <h4>Page Tagging</h4>
            <p>
              We use JavaScript page tags to aggregate and analyse customer
              usage patterns on our website. This provides us with data on your
              site usage and behaviour. We use this information to make changes
              to the layout of the site and to the information in it. This
              process does not collect any personal or identifiable information
              about users. You can disable JavaScript on your computer however,
              disabling JavaScript may affect your ability to access some of our
              online tools and services.{" "}
            </p>
            <p>
              Sometimes we tag pages on the site when we are promoting various
              campaigns. These tags recognise a unique cookie on your browser,
              which in turn enables us to learn which campaigns bring users to
              our site. With this technology, the information that we collect
              and share is anonymous and not personally identifiable. It does
              not contain your name, address, telephone number, or email
              address.{" "}
            </p>
            <p>
              From time to time we may wish to record personal information about
              you by using the page tagging analytics to track your behaviour on
              the site, this can be done once you have registered or logged in
              to the site.{" "}
            </p>

            <h4>Surveys/Polls</h4>
            <p>
              From time to time, the ProPharma-UK will feature surveys and user
              polls on its site. Participation is voluntary. Information
              requested may include, name, contact information, demographic
              information, opinions and survey/poll related information. Such
              information will be used for improving the use of the
              ProPharma-UKs services and product offerings.{" "}
            </p>

            <h4>Special Offers and opting out of communications</h4>
            <p>
              Users of the ProPharma-UK website who no longer wish to receive
              communications from the ProPharma-UK about its services, products
              or special offers may opt out of receiving these. Please read our{" "}
              <a
                href="https://www.google.com/"
                style={{ color: "#008000" }}
                data-original-title=""
                title=""
              >
                data protection notice
              </a>{" "}
              for more information.
            </p>

            <h4>Links to Third Party Web Sites </h4>
            <p>
              At this Site, there may be links to companies with whom we have a
              relationship. ProPharma-UK is not responsible for the privacy
              practices of external links and websites. We encourage you to read
              their privacy statements, as they may differ from ours.
            </p>

            <h4>Account Protection </h4>
            <p>
              Your password is the key to your account. If you do share your
              password or your personal information with others, remember that
              you are responsible for all actions taken in the name of your
              account. If you lose control of your password, you may lose
              substantial control over your personal information and may be
              subject to legally binding actions taken on your behalf.
              Therefore, if your password has been compromised for any reason,
              you should immediately notify the ProPharma-UK and change your
              password.
            </p>

            <h4>Security</h4>
            <p>
              Your information is stored on our servers located in the United
              Kingdom, in accordance with our{" "}
              <a
                href="https://www.google.com/"
                style={{ color: "#008000" }}
                data-original-title=""
                title=""
              >
                Data Protection Notice
              </a>{" "}
              and our role as a Data Controller. We treat data as an asset that
              must be protected and use lots of tools (encryption, passwords,
              physical security, etc.) to protect your personal information
              against unauthorised access and disclosure. However, as you
              probably know, third parties may unlawfully intercept or access
              transmissions or private communications, and other users may abuse
              or misuse your personal information that they collect from the
              site. Therefore, although we work very hard to protect your
              privacy, we do not promise, and you should not expect, that your
              personal information or private communications will always remain
              private.{" "}
            </p>

            <h4>General</h4>
            <p>
              We may amend this Privacy Policy at any time by posting the
              amended terms on this site. If you have any questions or comments
              about this Privacy Policy please contact:{" "}
            </p>

            <p>
              The webmaster
              <br />
              ProPharma-UK Limited
              <br />
              unit 1 Windsor Park,
              <br />
              50 Windsor Avenue,
              <br />
              London, SW19 2TJ, UK
              <br />
              Email:{" "}
              <a
                href="mailto:regulatory@pro-pharma.co.uk"
                data-original-title=""
                title=""
              >
                regulatory@pro-pharma.co.uk
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CookieAndWebsitePolicy;
