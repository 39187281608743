import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { api, baseURL } from "../../../config/api";
import { token } from "../../../config/globleData";
import { Button } from "antd";
import Navbar from "../../../globleComponents/Navbar";

const ViewEditPagePharmacy = () => {
  const { id } = useParams();
  const [pharmacyData, setPharmacyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [certificates, setCertificates] = useState({
    temperatureRecordDraft: null,
  });
  const navigate = useNavigate();
  const getFileByType = async (id, type) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/registrations/registration/${id}/file/${type}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching file:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch pharmacy data
        const response = await axios.get(
          `${api.pharmacyRegistrationsList}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPharmacyData(response.data);
        // Fetch certificates if available
        // const [tempResponse] = await Promise.all([
        //   getFileByType(id, "temperatureRecordDraft"),
        // ]);

        // setCertificates({
        //   temperatureRecordDraft: URL.createObjectURL(tempResponse.data),
        // });
      } catch (error) {
        setError("Failed to fetch pharmacy data or certificates");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleEditClick = () => {
    navigate(`/pharmacy-registration/${id}`); // Navigate to the edit page with the ID
  };

  return (
    <div>
      <Navbar />
      <main role="main" className="mt-4 col-lg-12 justify-content-center">
        <div className="container card mb-4 mt-4 border-success details-card">
          <div className="card-header bg-success text-white">
            <div class="d-flex justify-content-between">
              <h2 className="card-title mb-0">
                <u>Pharmacy Register Details</u>
              </h2>
              <h2
                className="card-title mb-0"
                role="button"
                onClick={handleEditClick}
              >
                <u>Edit</u>
              </h2>
            </div>
          </div>
          <div className="card-body">
            {/* Company Details */}
            <div className="row mb-4">
              <div className="col-12">
                <h4 className="text-success">Company Details</h4>
                <table className="table table-bordered table-striped details-table">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Registered Company Name:</strong>
                      </td>
                      <td>{pharmacyData?.RegisteredCompanyName}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Trading Name:</strong>
                      </td>
                      <td>{pharmacyData?.Tradingname}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Address 1:</strong>
                      </td>
                      <td>{pharmacyData?.Address1}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Address 2:</strong>
                      </td>
                      <td>{pharmacyData?.Address2}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>City:</strong>
                      </td>
                      <td>{pharmacyData?.City}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Postcode:</strong>
                      </td>
                      <td>{pharmacyData?.Postcode}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>VAT No:</strong>
                      </td>
                      <td>{pharmacyData?.vatNo}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Company Registration No:</strong>
                      </td>
                      <td>{pharmacyData?.companyRegistrationNo}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Website URL:</strong>
                      </td>
                      <td>{pharmacyData?.websiteURL}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Contact Details */}
            <div className="row mb-4">
              <div className="col-12">
                <h4 className="text-success">Contact Details</h4>
                <table className="table table-bordered table-striped details-table">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Business Email:</strong>
                      </td>
                      <td>{pharmacyData?.businessEmail}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Business Telephone No:</strong>
                      </td>
                      <td>{pharmacyData?.businessTelephoneNo}</td>
                    </tr>

                    {pharmacyData?.ApplicationFaxNo && (
                      <tr>
                        <td>
                          <strong>Application Fax No:</strong>
                        </td>
                        <td>{pharmacyData?.ApplicationFaxNo}</td>
                      </tr>
                    )}
                    {pharmacyData?.ApplicantTelephoneNo && (
                      <tr>
                        <td>
                          <strong>Applicant Telephone No:</strong>
                        </td>
                        <td>{pharmacyData?.ApplicantTelephoneNo}</td>
                      </tr>
                    )}
                    {pharmacyData?.DeliveryPrimaryContactNo && (
                      <tr>
                        <td>
                          <strong>Delivery Primary Contact No:</strong>
                        </td>
                        <td>{pharmacyData?.DeliveryPrimaryContactNo}</td>
                      </tr>
                    )}
                    {pharmacyData?.CollectionPrimaryContactNo && (
                      <tr>
                        <td>
                          <strong>Collection Primary Contact No:</strong>
                        </td>
                        <td>{pharmacyData?.CollectionPrimaryContactNo}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Directors & Shareholders */}
            <div className="row mb-4">
              <div className="col-12">
                <h4 className="text-success">Directors & Shareholders</h4>
                <table className="table table-bordered table-striped details-table">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Directors:</strong>
                      </td>
                      <td>{pharmacyData?.directors.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Shareholders:</strong>
                      </td>
                      <td>{pharmacyData?.shareholders.join(", ")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pharmacists Details */}
            <div className="row mb-4">
              <div className="col-12">
                <h4 className="text-success">Pharmacists Details</h4>
                <table className="table table-bordered table-striped details-table">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Superintendent Pharmacist:</strong>
                      </td>
                      <td>{pharmacyData?.superintendentPharmacistName}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>GPHC Number:</strong>
                      </td>
                      <td>
                        {pharmacyData?.superintendentPharmacistGPHCNumber}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Resident Pharmacist:</strong>
                      </td>
                      <td>{pharmacyData?.residentPharmacistName}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>GPHC Number:</strong>
                      </td>
                      <td>{pharmacyData?.residentPharmacistGPHCNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Resident Pharmacist Email:</strong>
                      </td>
                      <td>{pharmacyData?.residentPharmacistEmail}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Additional Information */}
            <div className="row mb-4">
              <div className="col-12">
                <h4 className="text-success">Additional Information</h4>
                <table className="table table-bordered table-striped details-table">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Business Account Contact Department:</strong>
                      </td>
                      <td>{pharmacyData?.businessAccountContactDept}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Signature:</strong>
                      </td>
                      <td>{pharmacyData?.signature}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Signature Email:</strong>
                      </td>
                      <td>{pharmacyData?.signatureEmailAddress}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Company Registration No:</strong>
                      </td>
                      <td>{pharmacyData?.companyRegistrationNo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Attachments Section */}
            <div className="row mb-4">
              <div className="col-12">
                <h4 className="text-success">Attachments</h4>
                {pharmacyData?.files?.temperatureRecordDraft && (
                  <div>
                    <a
                      href={pharmacyData?.files?.temperatureRecordDraft}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Temperature Record Draft
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div class="btn_section">
              <div class="container">
                <div class="btn_back_submit">
                  <button
                    type="button"
                    class="btn_back"
                    onClick={handleEditClick}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    class="btn_submit"
                    onClick={() => navigate("/thank-you")}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewEditPagePharmacy;
