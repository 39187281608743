import React, { useEffect, useState } from "react";
import Navbar from "../globleComponents/Navbar";
import Footer from "../globleComponents/Footer";
import axios from "axios";

const News = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRSSFeed = async () => {
      try {
        const response = await axios.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://pharmaphorum.com/rssfeed/news-and-features'));
        const text = response.data.contents;

        const parser = new DOMParser();
        const xml = parser.parseFromString(text, 'text/xml');
        const items = xml.querySelectorAll('item');

        const feedItems = Array.from(items).map(item => {
          const title = item.querySelector('title') ? item.querySelector('title').textContent : 'No title';
          const link = item.querySelector('link') ? item.querySelector('link').textContent : '#';
          const description = item.querySelector('description') ? item.querySelector('description').textContent : 'No description';
          const pubDate = item.querySelector('pubDate') ? new Date(item.querySelector('pubDate').textContent).toLocaleDateString() : 'Unknown date';
          const imageElement = item.querySelector('enclosure');
          let imageUrl = imageElement ? imageElement.getAttribute('url') : 'default-image-url';

          // Update the image URL if it contains the word "thumbnail"
          if (imageUrl.includes('thumbnail')) {
            imageUrl = imageUrl.replace('thumbnail', 'x_large');
          }

          return {
            title,
            link,
            description,
            pubDate,
            image: imageUrl
          };
        });

        setData(feedItems);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch RSS feed. Error: ' + err.message);
        setLoading(false);
      }
    };

    fetchRSSFeed();
    setTimeout(() => {
      fetchRSSFeed();
    }, 1000);
  }, []);


  return (
    <div>
      <Navbar />
      <div className="news-details-section">
        <div className="news-detail-box">
          <div className="container">
            <div className="row news-first-row">
              {/* Display the first item */}
              {data.length > 0 && (
                <div className="col-md-7">
                  <a href={data[0].link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={data[0].image}
                      alt="News Image"
                      className="img-news-image"
                    />
                  </a>
                </div>
              )}
              {data.length > 0 && (
                <div className="col-md-5">
                  <div className="news-content-box">
                    <div className="author-name">
                      <p>By {data[0].author || 'Unknown'}</p>
                      {/* <span>{data[0].pubDate}</span> */}
                    </div>
                    <a href={data[0].link} target="_blank" rel="noopener noreferrer">
                      <h3 className="news-title short_main_title">
                        {data[0].title}
                      </h3>
                    </a>
                    <p className="news-description short_desc">
                      {data[0].description}
                    </p>
                    <a href={data[0].link} className="btn-continue-reading" target="_blank" rel="noopener noreferrer">
                      Continue Reading
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div className="row news-second-row">
              {/* Display the next items */}
              {data.slice(1, 3).map((item, index) => (
                <div className="col-md-6" key={index}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={item.image}
                      alt="News Image"
                      className="img-news-image"
                    />
                  </a>
                  <div className="news-box">
                    <div className="author-name">
                      <p>By {item.author || 'Unknown'}</p>
                      {/* <span>{item.pubDate}</span> */}
                    </div>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <h3 className="news-title short_main_title">
                        {item.title}
                      </h3>
                    </a>
                    <p className="news-description short_news_desc">
                      {item?.description?.length > 100 ? item?.description?.substring(0, 100) + '...' : item?.description}
                    </p>
                    <a href={item.link} className="btn-continue-reading" target="_blank" rel="noopener noreferrer">
                      Continue Reading
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="popular-news-section">
          <div className="container">
            <h2 className="title-main-popular-news">Popular News</h2>
            <div className="row">
              {/* Display more items for popular news */}
              {data.slice(3, 5).map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className="popular-news-box">
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={item.image}
                        alt="Popular News"
                        className="img-popular-news"
                      />
                    </a>
                    <div className="popular-content">
                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                        <h3 className="popular-news-title short_main_title">
                          {item.title}
                        </h3>
                      </a>
                      <p className="popular-news-description short_news_desc">
                        {item?.description?.length > 100 ? item?.description?.substring(0, 100) + '...' : item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default News;
