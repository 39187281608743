import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { api } from "../../../config/api"; // Ensure this path is correct
import { formatDate, token } from "../../../config/globleData";
import Navbar from "../../../globleComponents/Navbar";

const ViewEditPagePrivateClinic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [clinicData, setClinicData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api.privateClinicsList}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClinicData(response.data);
      } catch (error) {
        setError("Failed to fetch clinic data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleEditClick = () => {
    navigate(`/private-clinics/${id}`); // Navigate to the edit page with the ID
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <main role="main" className="mt-4 col-lg-12 justify-content-center">
          <div className="container card mb-4 mt-4 border-success details-card">
            <div className="card-header bg-success text-white">
              <div class="d-flex justify-content-between">
                <h2 className="card-title mb-0">
                  <u> Private Clinic Register Details</u>
                </h2>
                <h2
                  className="card-title mb-0"
                  role="button"
                  onClick={handleEditClick}
                >
                  <u>Edit</u>
                </h2>
              </div>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h5>
                    Clinic Name:{" "}
                    <span className="text-success">
                      {clinicData?.clinicName}
                    </span>
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5>
                    Type: <span className="text-info">{clinicData?.type}</span>
                  </h5>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Company Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Delivery Address:</strong>
                        </td>
                        <td>{clinicData?.deliveryAddress}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Postcode:</strong>
                        </td>
                        <td>{clinicData?.postcode}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Owned By:</strong>
                        </td>
                        <td>{clinicData?.ownedBy}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Trading As:</strong>
                        </td>
                        <td>{clinicData?.tradingAs}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Website URL:</strong>
                        </td>
                        <td>{clinicData?.websiteURL}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>VAT No:</strong>
                        </td>
                        <td>{clinicData?.vatNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Registration No:</strong>
                        </td>
                        <td>{clinicData?.companyRegistrationNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>CQC Registration Name:</strong>
                        </td>
                        <td>{clinicData?.CQCRegistrationName}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Contact Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Contact Person:</strong>
                        </td>
                        <td>{clinicData?.contactPerson}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Contact Number:</strong>
                        </td>
                        <td>{clinicData?.contactNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Contact Email:</strong>
                        </td>
                        <td>{clinicData?.contactEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Professionals</h4>
                  {clinicData?.professionals?.length ? (
                    <table className="table table-bordered table-striped details-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Authority</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clinicData.professionals.map((prof, index) => (
                          <tr key={index}>
                            <td>{prof.name}</td>
                            <td>{prof.authority}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="text-muted">No professionals listed.</p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Accounts Information</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Accounts Email:</strong>
                        </td>
                        <td>{clinicData?.accountsEmail}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Accounts Contact Person:</strong>
                        </td>
                        <td>{clinicData?.accountsContactPerson}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Additional Information</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Form Completed By:</strong>
                        </td>
                        <td>{clinicData?.formCompletedBy}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Completion Date:</strong>
                        </td>
                        <td>{formatDate(clinicData?.completionDate)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* New Table for Certificates */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Certificates</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>WDA Certificate:</strong>
                        </td>
                        <td>
                          {clinicData?.files?.WDACertificate ? (
                            <a
                              href={clinicData.files.WDACertificate}
                              download="WDA_Certificate.webp"
                              target="_blank"
                            >
                              View WDA Certificate
                            </a>
                          ) : (
                            "No WDA Certificate available"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>GDP Certificate:</strong>
                        </td>
                        <td>
                          {clinicData?.files?.WDACertificate ? (
                            <a
                              href={clinicData?.files?.GDPCertificate}
                              download="GDP_Certificate.webp"
                              target="_blank"
                            >
                              View GDP Certificate
                            </a>
                          ) : (
                            "No GDP Certificate available"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="btn_section">
                <div class="container">
                  <div class="btn_back_submit">
                    <button
                      type="button"
                      class="btn_back"
                      onClick={handleEditClick}
                    >
                      Back
                    </button>

                    <button
                      type="button"
                      class="btn_submit"
                      onClick={() => navigate("/thank-you")}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ViewEditPagePrivateClinic;
