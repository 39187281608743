import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div class="row">
        <div class="col-md-12 foot-add1">
          <div class="container">
            <div class="col-md-3">
              <h2 class="widget-title">Important Links</h2>

              <ul className="footermenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div class="col-md-5" id="blog">
              <h2 class="widget-title">Recent Posts</h2>
              <ul class="footermenu">
                <li>
                  <a href="https://proph.co.uk/blog/medicines/">Medicines </a>
                </li>
                <li>
                  <a href="https://proph.co.uk/blog/cma-publishes-recommendations-to-improve-pcr-testing-market/">
                    CMA publishes recommendations to improve PCR testing market{" "}
                  </a>
                </li>
                <li>
                  <a href="https://proph.co.uk/blog/polymerase-chain-reaction-market-size-worth-16-2-billion-by-2027-grand-view-research-inc/">
                    Polymerase Chain Reaction Market Size Worth $16.2 Billion By
                    2027: Grand View Research, Inc.{" "}
                  </a>
                </li>
                <li>
                  <a href="https://proph.co.uk/blog/as-the-first-covid-19-vaccine-gets-approval-are-we-finally-turning-a-corner/">
                    As the first COVID-19 vaccine gets approval, are we finally
                    turning a corner?{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-md-4">
              <h2 class="widget-title">Propharma UK</h2>
              <ul class="footermenu">
                <li style={{ marginBottom: "-6px" }}>Unit 1 Windsor Park</li>

                <li style={{ marginBottom: "-6px" }}>50 Windsor Avenue</li>

                <li>London, SW19 2TJ, UK</li>

                <li>020 3857 4765</li>
                <li>
                  <a class="add-btn" href="mailto:sales@pro-pharma.co.uk">
                    sales@pro-pharma.co.uk
                  </a>
                </li>

                <li style={{ marginBottom: "-6px" }}>
                  Company Registration Number :{" "}
                  <span class="company_number">10155095</span>
                </li>

                <li style={{ marginBottom: "-6px" }}>
                  Company VAT Number :{" "}
                  <span class="company_number">240248045</span>
                </li>

                <li style={{ marginBottom: "-6px" }}>
                  Wholesale Dealer Licence Number :{" "}
                  <span class="company_number">46277</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="hdr-social">
                  <div className="hdr-scl">
                    <span className="fb-ftr">
                      <a href="https://www.facebook.com/Propharma-UK-313953155638741/">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/propharma-uk-limited">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="terms-condition-section">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-4">
                      <p>
                        <Link
                          to="/terms-and-condition"
                          className="terms"
                          style={{ textAlign: "center" }}
                        >
                          Terms &amp; Conditions
                        </Link>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <Link
                          to="/cookie-and-website-policy"
                          className="terms"
                          style={{ textAlign: "center" }}
                        >
                          Cookie and Website Policy
                        </Link>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <Link
                          to="/data-protection-notice"
                          className="terms data_notice"
                          style={{ textAlign: "center" }}
                        >
                          Data Protection Notice
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
            <p style={{ textAlign: "center", color: "#fff" }}>
              Copyright © 2024 Pharmaceutical Wholesaler UK
            </p>
            <div id="designer-details" className="develop">
              <div className="container">
                <div className="created-info">
                  Designed &amp; Developed by{" "}
                  <a
                    className="link-bottom-footer"
                    href="https://www.cre8ivelabs.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cre8ive Labs
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
