import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../globleComponents/Navbar";
import RegistrationForm from "../globleForms/RegistrationForm";
import Footer from "../../../globleComponents/Footer";
import ContactUS from "../../../globleComponents/ContactUS";
import BusinessDetails from "../globleForms/BusinessDetails";
import Attachment from "../globleForms/Attachment";
import TermsAndConditionsOfSale from "../globleForms/TermsAndConditionsOfSale";
import axios from "axios";
import { toast } from "react-toastify";
import { api } from "../../../config/api";
import { useNavigate, useParams } from "react-router-dom";
import { token } from "../../../config/globleData";

// Validation function
const validateFormData = (data) => {
  const errors = {};
  const requiredFields = [
    "RegisteredCompanyName",
    "Tradingname",
    "Address1",
    "Address2",
    "City",
    "CountryRegion",
    "Postcode",
    "wdl_number",
    "termsAndConditionCheckbox",
    "companyRegistrationNo",
    "vatNo",
    "businessCompanyType",
    "businessTelephoneNo",
    "businessAccountContactDept",
    "businessEmail",
  ];

  requiredFields.forEach((field) => {
    if (!data[field]) {
      errors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
    }
  });

  return errors;
};

const CustomerRegistration = () => {
  const [formData, setFormData] = useState({
    RegisteredCompanyName: "",
    Tradingname: "",
    Address1: "",
    Address2: "",
    City: "",
    CountryRegion: "",
    Postcode: "",
    wdl_number: "",
    DeliveryAddress: "",
    DeliveryAddress2: "",
    DeliveryCity: "",
    DeliveryPostcode: "",
    DeliveryPrimaryContactNo: "",
    CollectionAddress: "",
    CollectionAddress2: "",
    CollectionCity: "",
    CollectionPostcode: "",
    CollectionFaxNo: "",
    CollectionTelephoneNo: "",
    CollectionPrimaryContactNo: "",
    companyRegistrationNo: "",
    vatNo: "",
    businessCompanyType: "",
    businessTelephoneNo: "",
    shareholders: [""],
    directors: [""],
    businessAccountContactDept: "",
    businessEmail: "",
    files: {
      WDACertificate: null,
      GDPCertificate: null,
    },
    signature: "",
    signatureEmailAddress: "",
    termsAndConditionCheckbox: false,
    superintendentPharmacistName: "",
    superintendentPharmacistGPHCNumber: "",
    residentPharmacistName: "",
    residentPharmacistGPHCNumber: "",
    residentPharmacistEmail: "",
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const attachmentRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${api.customerRegistrationsList}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const fetchedData = response.data;
          setFormData({
            ...fetchedData,
            files: {
              WDACertificate: fetchedData.files?.WDACertificate || null,
              GDPCertificate: fetchedData.files?.GDPCertificate || null,
            },
          });
        } catch (error) {
          toast.error("Failed to fetch data");
        }
      };
      if (id) {
        fetchData();
      }
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateFormData(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setTouched({
        ...Object.keys(validationErrors).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {}),
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSubmit = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            formDataToSubmit.append(`${key}[${index}]`, item);
          });
        } else if (typeof value === "object" && value !== null) {
          Object.entries(value).forEach(([fileKey, fileValue]) => {
            if (fileValue) {
              formDataToSubmit.append(fileKey, fileValue);
            }
          });
        } else {
          formDataToSubmit.append(key, value);
        }
      });

      const url = id
        ? `${api.customerRegistrationsList}/${id}` // URL for edit
        : api.CreateCustomerRegistrations; // URL for create
      const method = id ? "put" : "post"; // Method for edit or create

      const response = await axios[method](url, formDataToSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success(response.data.message);

      setFormData({
        RegisteredCompanyName: "",
        Tradingname: "",
        Address1: "",
        Address2: "",
        City: "",
        CountryRegion: "",
        Postcode: "",
        wdl_number: "",
        DeliveryAddress: "",
        DeliveryAddress2: "",
        DeliveryCity: "",
        DeliveryPostcode: "",
        DeliveryPrimaryContactNo: "",
        CollectionAddress: "",
        CollectionAddress2: "",
        CollectionCity: "",
        CollectionPostcode: "",
        CollectionFaxNo: "",
        CollectionTelephoneNo: "",
        CollectionPrimaryContactNo: "",
        companyRegistrationNo: "",
        vatNo: "",
        businessCompanyType: "",
        businessTelephoneNo: "",
        shareholders: [""],
        directors: [""],
        businessAccountContactDept: "",
        businessEmail: "",
        files: {
          WDACertificate: null,
          GDPCertificate: null,
        },
        signature: "",
        signatureEmailAddress: "",
        termsAndConditionCheckbox: false,
        superintendentPharmacistName: "",
        superintendentPharmacistGPHCNumber: "",
        residentPharmacistName: "",
        residentPharmacistGPHCNumber: "",
        residentPharmacistEmail: "",
      });

      if (attachmentRef.current) {
        attachmentRef.current.clearFileInputs();
      }

      navigate(
        `/wholesaler-register-details/${response.data.customerRegistration._id}`
      );
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="background-container">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <RegistrationForm
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              title="Customer Registration"
            />
            <BusinessDetails
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
            <Attachment
              formData={formData}
              setFormData={setFormData}
              ref={attachmentRef}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
            <TermsAndConditionsOfSale
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setErrors={setErrors}
              errors={errors}
              touched={touched}
            />
            <div className="step-content-foot">
              <button
                type="submit"
                className="active out1"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Finish"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ContactUS />
      <Footer />
    </>
  );
};

export default CustomerRegistration;
