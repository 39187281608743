import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import TechnicalAgreement from '../../../config/assets/TechnicalAgreement.pdf'

const TemperatureRecordConfirmation = forwardRef(
  ({ formData, setFormData }, ref) => {
    const [selectedFile, setSelectedFile] = useState(
      formData?.files?.temperatureRecordDraft || null
    );
    const tempRecordRef = useRef(null);

    // Path to the draft document provided by the client
    const draftDocumentUrl = "../../../config/assets/TechnicalAgreement.pdf";

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
      setFormData((prev) => ({
        ...prev,
        files: {
          ...prev.files,
          temperatureRecordDraft: file,
        },
      }));
    };

    // Expose the clearFileInputs method to the parent component
    useImperativeHandle(ref, () => ({
      clearFileInputs: () => {
        if (tempRecordRef.current) {
          tempRecordRef.current.value = null;
        }
        setSelectedFile({
          temperatureRecordDraft: null,
        });
      },
    }));

    return (
      <div>
        <div id="temperature-record-section" className="step-content-body out1">
          <div className="formdv container">
            <p className="formdetailstitle">
              <span>Temperature Record Confirmation</span>
            </p>
            <div className="row">
              <div
                className="col-md-12 form-group cursor_p upload_section"
                id="temperature-record"
              >
                <div
                  className="col-md-6"
                  style={{
                    border: "1px solid #cbd6e2",
                    borderRadius: "3px",
                    minHeight: "60px",
                  }}
                >
                  <div className="fileUpload" id="temp-record-upload">
                    <span className="fileuploadimg">
                      <img
                        src="https://proph.co.uk/public/front/assets/images/fileupload.png"
                        alt="file upload icon"
                        onClick={() => tempRecordRef.current?.click()}
                      />
                    </span>
                    {selectedFile && (
                      <span className="success" id="temp-record-success">
                        <img
                          src="https://proph.co.uk/public/front/assets/images/success.png"
                          alt="success icon"
                        />
                      </span>
                    )}
                  </div>
                  <input
                    type="file"
                    name="temperatureRecordDraft"
                    className="upload cursor_p form-control com-image"
                    ref={tempRecordRef}
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="temp-record-file"
                    className="lbl-image form-control attachment-label upload-label-sec"
                  >
                    {selectedFile
                      ? selectedFile.name.slice(0, 20)
                      : formData?.files?.temperatureRecordDraft ||
                        "Upload Temperature Record Draft *"}
                  </label>
                </div>
              </div>

              <div className="col-sm-6 sizetextdv hide">
                <span>Maximum upload file size is 10MB</span>
              </div>
            </div>
            <div class="row technical_doc">
              <div
                class="col-sm-12 form-group"
                style={{
                  fontFamily: "Blogger Sans, sans-serif",
                }}
              >
                {/* Technical agreement */}
                 {/* :{" "} */}
                <a
                  class="technicaldoc"
                  style={{
                    textDecoration: "none",
                    fontFamily: "Blogger Sans, sans-serif",
                  }}
                  href="https://cre8ivelabs07-my.sharepoint.com/:w:/g/personal/puja_gujarathi_cre8ivelabs_com/ERXw2UQztERLvB8uk4HRAcoBYfBGjsm7AvIZ2WqTPnIDjw?e=dG8a5s&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIxNDE1LzI0MDcxMTI4ODI1IiwiSGFzRmVkZXJhdGVkVXNlciI6ZmFsc2V9"
                >
                  Click Here To Download Technical Agreement
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default TemperatureRecordConfirmation;
