import React from "react";

const YourTeamForm = ({
  formData,
  setFormData,
  errors,
  touched,
  setTouched,
  handleChange,
}) => {
  // Handle change for professionals fields
  const handleProfessionalChange = (index, event) => {
    const { name, value } = event.target;
    const updatedProfessionals = [...formData.professionals];
    updatedProfessionals[index] = {
      ...updatedProfessionals[index],
      [name]: value,
    };
    setFormData((prev) => ({ ...prev, professionals: updatedProfessionals }));
    // Mark field as touched
    setTouched((prev) => ({
      ...prev,
      [`professionals[${index}].${name}`]: true,
    }));
  };

  // Add a new professional field
  const handleAddProfessional = () => {
    setFormData((prev) => ({
      ...prev,
      professionals: [
        ...prev.professionals,
        { name: "", authority: "", registrationNumber: "" },
      ],
    }));
  };

  // Remove a professional field
  const handleRemoveProfessional = (index) => {
    setFormData((prev) => ({
      ...prev,
      professionals: prev.professionals.filter((_, i) => i !== index),
    }));
  };

  return (
    <div id="yourTeamForm" className="step-content-body out1">
      <div className="formdv container">
        <p className="formdetailstitle">
          <span>Your Team</span>
        </p>

        {/* Contact Person */}
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="contactPerson" className="mat-label">
              Contact Person
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.contactPerson && touched.contactPerson
                  ? "is-invalid"
                  : ""
              }`}
              id="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
              name="contactPerson"
            />
            {errors.contactPerson && touched.contactPerson && (
              <span className="loaddata">{errors.contactPerson}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="contactNumber" className="mat-label">
              Contact Number
            </label>
            <input
              type="tel"
              className={`form-control mat-input ${
                errors.contactNumber && touched.contactNumber
                  ? "is-invalid"
                  : ""
              }`}
              id="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              name="contactNumber"
            />
            {errors.contactNumber && touched.contactNumber && (
              <span className="loaddata">{errors.contactNumber}</span>
            )}
          </div>
        </div>

        {/* Contact Email */}
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="contactEmail" className="mat-label">
              Contact Email address
            </label>
            <input
              type="email"
              className={`form-control mat-input ${
                errors.contactEmail && touched.contactEmail ? "is-invalid" : ""
              }`}
              id="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              name="contactEmail"
            />
            {errors.contactEmail && touched.contactEmail && (
              <span className="loaddata">{errors.contactEmail}</span>
            )}
          </div>
        </div>

        {/* Partner Name */}
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="partnerName" className="mat-label">
              Name of Partner
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.partnerName && touched.partnerName ? "is-invalid" : ""
              }`}
              id="partnerName"
              value={formData.partnerName}
              onChange={handleChange}
              name="partnerName"
            />
            {errors.partnerName && touched.partnerName && (
              <span className="loaddata">{errors.partnerName}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="directorName" className="mat-label">
              Name of Director
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.directorName && touched.directorName ? "is-invalid" : ""
              }`}
              id="directorName"
              value={formData.directorName}
              onChange={handleChange}
              name="directorName"
            />
            {errors.directorName && touched.directorName && (
              <span className="loaddata">{errors.directorName}</span>
            )}
          </div>
        </div>

        {/* Director Email */}
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="directorEmail" className="mat-label">
              Contact Email for Director
            </label>
            <input
              type="email"
              className={`form-control mat-input ${
                errors.directorEmail && touched.directorEmail
                  ? "is-invalid"
                  : ""
              }`}
              id="directorEmail"
              value={formData.directorEmail}
              onChange={handleChange}
              name="directorEmail"
            />
            {errors.directorEmail && touched.directorEmail && (
              <span className="loaddata">{errors.directorEmail}</span>
            )}
          </div>
        </div>

        {/* Professionals Fields */}
        <div className="row">
          {formData.professionals.map((professional, index) => (
            <div
              key={index}
              className={
                index % 2 === 0
                  ? "col-xs-12 col-sm-6 form-group left_col added"
                  : "col-xs-12 col-sm-6 form-group right_col added"
              }
            >
              {index === 0 && (
                <>
                  <i
                    className="glyphicon glyphicon-plus add_field_button1"
                    onClick={handleAddProfessional}
                  ></i>
                  <span
                    style={{ color: "#008000", cursor: "pointer" }}
                    onClick={handleAddProfessional}
                  >
                    Add More
                  </span> &nbsp;
                </>
              )}
              <label
                htmlFor={`medicalProfessional-${index}`}
                className="mat-label1"
              >
                Medical Professional/Site GP name
              </label>
              <div className="professional-item">
                <input
                  type="text"
                  className={`form-control-mat-input1 mt-2 ${
                    errors.professionals &&
                    errors.professionals[index] &&
                    errors.professionals[index].name
                      ? "is-invalid"
                      : ""
                  }`}
                  value={professional.name}
                  onChange={(e) => handleProfessionalChange(index, e)}
                  name="name"
                />
                <input
                  type="text"
                  className={`form-control-mat-input1 mt-2 ${
                    errors.professionals &&
                    errors.professionals[index] &&
                    errors.professionals[index].authority
                      ? "is-invalid"
                      : ""
                  }`}
                  value={professional.authority}
                  onChange={(e) => handleProfessionalChange(index, e)}
                  name="authority"
                />
                <input
                  type="text"
                  className={`form-control-mat-input1 mt-2 ${
                    errors.professionals &&
                    errors.professionals[index] &&
                    errors.professionals[index].registrationNumber
                      ? "is-invalid"
                      : ""
                  }`}
                  value={professional.registrationNumber}
                  onChange={(e) => handleProfessionalChange(index, e)}
                  name="registrationNumber"
                />
                {index > 0 && (
                  <i
                    className="glyphicon glyphicon-remove remove_field1"
                    title="Remove This Name"
                    onClick={() => handleRemoveProfessional(index)}
                  ></i>
                )}
                {errors.professionals && errors.professionals[index] && (
                  <div className="form-errors">
                    {errors.professionals[index].name && (
                      <span className="loaddata">
                        {errors.professionals[index].name}
                      </span>
                    )}
                    {errors.professionals[index].authority && (
                      <span className="loaddata">
                        {errors.professionals[index].authority}
                      </span>
                    )}
                    {errors.professionals[index].registrationNumber && (
                      <span className="loaddata">
                        {errors.professionals[index].registrationNumber}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Accounts Email and Contact Person */}
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="accountsEmail" className="mat-label">
              Accounts Department email address
            </label>
            <input
              type="email"
              className={`form-control mat-input ${
                errors.accountsEmail && touched.accountsEmail
                  ? "is-invalid"
                  : ""
              }`}
              id="accountsEmail"
              value={formData.accountsEmail}
              onChange={handleChange}
              name="accountsEmail"
            />
            {errors.accountsEmail && touched.accountsEmail && (
              <span className="loaddata">{errors.accountsEmail}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="accountsContactPerson" className="mat-label">
              Accounts Department contact person
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.accountsContactPerson && touched.accountsContactPerson
                  ? "is-invalid"
                  : ""
              }`}
              id="accountsContactPerson"
              value={formData.accountsContactPerson}
              onChange={handleChange}
              name="accountsContactPerson"
            />
            {errors.accountsContactPerson && touched.accountsContactPerson && (
              <span className="loaddata">{errors.accountsContactPerson}</span>
            )}
          </div>
        </div>

        {/* Form Completed By and Completion Date */}
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="formCompletedBy" className="mat-label">
              Form Completed By
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.formCompletedBy && touched.formCompletedBy
                  ? "is-invalid"
                  : ""
              }`}
              id="formCompletedBy"
              value={formData.formCompletedBy}
              onChange={handleChange}
              name="formCompletedBy"
            />
            {errors.formCompletedBy && touched.formCompletedBy && (
              <span className="loaddata">{errors.formCompletedBy}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="completionDate" className="mat-label">
              Completion Date of the Form
            </label>
            <input
              type="date"
              className={`form-control mat-input ${
                errors.completionDate && touched.completionDate
                  ? "is-invalid"
                  : ""
              }`}
              id="completionDate"
              value={formData.completionDate ? new Date(formData.completionDate).toISOString().split('T')[0] : ''}
              onChange={handleChange}
              name="completionDate"
            />
            {errors.completionDate && touched.completionDate && (
              <span className="loaddata">{errors.completionDate}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourTeamForm;
