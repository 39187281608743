import React, { useRef, useState } from "react";
import Navbar from "../../globleComponents/Navbar";
import RegistrationForm from "./globleForms/RegistrationForm";
import Footer from "../../globleComponents/Footer";
import ContactUS from "../../globleComponents/ContactUS";
import BusinessDetails from "./globleForms/BusinessDetails";
import Attachment from "./globleForms/Attachment";
import GDPQuestionnaire from "./globleForms/GDPQuestionnaire";
import TermsAndConditionsOfSaleSupplier from "./globleForms/TermsAndConditionsOfSaleSupplier";
import BankDetails from "./globleForms/BankDetails";

const Supplier = () => {
  const [formData, setFormData] = useState({
    // Registration form

    RegisteredCompanyName: "",
    Tradingname: "",
    Address1: "",
    Address2: "",
    City: "",
    CountryRegion: "",
    Postcode: "",
    wdl_number: "",
    DeliveryAddress: "",
    DeliveryAddress2: "",
    DeliveryCity: "",
    DeliveryPostcode: "",
    DeliveryPrimaryContactNo: "",
    CollectionAddress: "",
    CollectionAddress2: "",
    CollectionCity: "",
    CollectionPostcode: "",
    CollectionFaxNo: "",
    CollectionTelephoneNo: "",
    CollectionPrimaryContactNo: "",

    companyRegistrationNo: "",
    vatNo: "",
    businessCompanyType: "",
    businessTelephoneNo: "",
    shareholders: [""],
    directors: [""],
    businessAccountContactDept: "",
    businessEmail: "",
    files: {
      WDACertificate: null,
      GDPCertificate: null,
    },

    // GDP Questionnaire
    GENERAL1: "",
    GENERAL2: "",
    GENERAL3: "",
    GENERAL4: "",
    GENERAL5: "",
    GENERAL6: "",
    GENERAL7: "",
    GENERAL8: "",
    GENERAL9: "",
    GENERAL10: "",
    GENERAL11: "",
    GENERAL12: "",
    GENERAL13: "",
    GENERAL14: "",
    GENERAL15: "",
    GENERAL16: "",
    GENERAL17: "",
    GENERAL18: "",
    GENERAL19: "",
    GENERAL20: "",
    GENERAL21: "",
    GENERAL22: "",
    GENERAL23: "",
    GENERAL24: "",
    GENERAL25: "",
    GENERAL26: "",
    GENERAL27: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const attachmentRef = useRef(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleFileChange = (fileType, file) => {
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        [fileType]: file,
      },
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  return (
    <>
      <Navbar />
      <RegistrationForm
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        title="Supplier Registration"
      />
      <BusinessDetails
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
      />
      <BankDetails />
      <Attachment
        formData={formData}
        setFormData={setFormData}
        handleFileChange={handleFileChange}
        ref={attachmentRef}
      />

      <GDPQuestionnaire formData={formData} setFormData={setFormData} />
      <TermsAndConditionsOfSaleSupplier />
      <div class="step-content-foot">
        <button
          type="submit"
          class="active out1"
          value="Submit"
          name="finish"
          id="changebutton"
        >
          Finish
        </button>
      </div>
      <ContactUS />
      <Footer />
    </>
  );
};

export default Supplier;
