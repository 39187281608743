import React from "react";

const ContactDetails = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <div
        className="contacts-details-section tabs-edits-section"
        style={{ display: "block" }}
      >
        <h3 className="form-main-title">Contact Details</h3>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Sales Contact Name<span className="reuired-sign">*</span>
              </label>
              <input
                type="text"
                name="salesName"
                value={formData.salesName}
                onChange={handleChange}
                className="form-control validation"
              />
              <span className="error_message"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Sales Tel No.<span className="reuired-sign">*</span>
              </label>
              <input
                type="text"
                name="salesTel"
                value={formData.salesTel}
                onChange={handleChange}
                className="form-control validation tel_number"
                onKeyPress={(e) => {
                  // Allow only numbers
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                  }
                }}
              />
              <span className="error_message"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Sales Email<span className="reuired-sign">*</span>
              </label>
              <input
                type="email"
                name="salesEmail"
                value={formData.salesEmail}
                onChange={handleChange}
                className="form-control validation email"
              />
              <span className="error_message"></span>
            </div>
          </div>
        </div>
        <span className="border-saprate-row"></span>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Accounts Contact Name<span className="reuired-sign">*</span>
              </label>
              <input
                type="text"
                name="accountsName"
                value={formData.accountsName}
                onChange={handleChange}
                className="form-control validation"
              />
              <span className="error_message"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Accounts Tel No.<span className="reuired-sign">*</span>
              </label>
              <input
                type="text"
                name="accountsTel"
                value={formData.accountsTel}
                onChange={handleChange}
                className="form-control validation tel_number"
                onKeyPress={(e) => {
                  // Allow only numbers
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                  }
                }}
              />
              <span className="error_message"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Accounts Email<span className="reuired-sign">*</span>
              </label>
              <input
                type="email"
                name="accountsEmail"
                value={formData.accountsEmail}
                onChange={handleChange}
                className="form-control validation email"
              />
              <span className="error_message"></span>
            </div>
          </div>
        </div>
        <span className="border-saprate-row"></span>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Warehouse Contact Name<span className="reuired-sign">*</span>
              </label>
              <input
                type="text"
                name="warehouseName"
                value={formData.warehouseName}
                onChange={handleChange}
                className="form-control validation"
              />
              <span className="error_message"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Warehouse Tel No.<span className="reuired-sign">*</span>
              </label>
              <input
                type="text"
                name="warehouseTel"
                value={formData.warehouseTel}
                onChange={handleChange}
                className="form-control validation tel_number"
                onKeyPress={(e) => {
                  // Allow only numbers
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                  }
                }}
              />
              <span className="error_message"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label-title">
                Warehouse Email<span className="reuired-sign">*</span>
              </label>
              <input
                type="email"
                name="warehouseEmail"
                value={formData.warehouseEmail}
                onChange={handleChange}
                className="form-control validation email"
              />
              <span className="error_message"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
