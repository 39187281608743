import React, { useState } from "react";
import CompanyDetails from "./CompanyDetails";
import ContactDetails from "./ContactDetails";
import RegulatoryContactDetails from "./RegulatoryContactDetails";
import SopsGDPTraining from "./SopsGDPTraining";
import SiteVisitsAudits from "./SiteVisitsAudits";
import LicencedWarehouseOperations from "./LicencedWarehouseOperations";
import Products from "./Products";
import Agreement from "./Agreement";

const WholesaleValidationForm = () => {
  const [currentTab, setCurrentTab] = useState(1);

  const [formData, setFormData] = useState({
    registeredcompanyname: "",
    Address1: "",
    Address2: "",
    salesName: "",
    salesTel: "",
    salesEmail: "",
    accountsName: "",
    accountsTel: "",
    accountsEmail: "",
    warehouseName: "",
    warehouseTel: "",
    warehouseEmail: "",
    responsiblePersonName: "",
    responsiblePersonEmail: "",
    responsiblePersonTel: "",
    wda_number: "",
    wda_date_granted: "",
    wda_expiry_date: "",
    gdp_number: "",
    gdp_date_granted: "",
    gdp_expiry_date: "",
    wdaAttachedOption: "",
    gdpCertOption: "",
    cdAttachedOption: "",
    propharma_UKOption: "",
    MHRA: "",
    ISO: "",
    GENERAL1: "",
    GENERAL2: "",
    GENERAL3: "",
    GENERAL4: "",
    GENERAL5: "",
    GENERAL6: "",
    GENERAL7: "",
    GENERAL8: "",
    GENERAL9: "",
    GENERAL10: "",
    GENERAL11: "",
    GENERAL12: "",
    GENERAL13: "",
    GENERAL14: "",
    GENERAL15: "",
    GENERAL16: "",
    GENERAL17: "",
    GENERAL18: "",
    GENERAL19: "",
    GENERAL20: "",
    name: "",
    date: "",
    position: "",
    signature: "",
    iAgree: false,
  });

  console.log("formdata", formData);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    console.log("formData", formData);
  };

  const stepNames = [
    "Company Details",
    "Contacts",
    "Regulatory Contact Details",
    "Sops / GDP / Training",
    "Site Visits / Audits",
    "Licensed Warehouse",
    "Products",
    "Agreement",
  ];

  const handleNext = () => {
    setCurrentTab(currentTab + 1);
  };

  const handleBack = () => {
    if (currentTab > 1) {
      setCurrentTab(currentTab - 1);
    }
  };

  return (
    <div>
      {/* Activity Bar */}
      <div className="booking-tabs-topbar">
        <div className="container">
          <div className="booking-test-group">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((tabNumber) => (
              <div
                key={tabNumber}
                className={`tabs-steps-group ${
                  currentTab >= tabNumber ? "active_tabs" : ""
                }`}
              >
                <div className={`round-steps-box tabs_${tabNumber}`}>
                  <i
                    className="fa fa-check icon-checked"
                    aria-hidden="true"
                  ></i>
                </div>
                <h4>{stepNames[tabNumber - 1]}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Form Container */}
      <div className="admin container">
        <div
          className="company-details-section tabs-edits-section"
          style={{ display: "block" }}
        >
          {/* Each form section can be conditionally rendered based on currentTab state */}
          {currentTab === 1 && (
            <CompanyDetails formData={formData} setFormData={setFormData} />
          )}
          {currentTab === 2 && (
            <ContactDetails formData={formData} setFormData={setFormData} />
          )}
          {currentTab === 3 && (
            <RegulatoryContactDetails
              formData={formData}
              handleChange={handleChange}
            />
          )}
          {currentTab === 4 && (
            <SopsGDPTraining formData={formData} handleChange={handleChange} />
          )}
          {currentTab === 5 && (
            <SiteVisitsAudits formData={formData} handleChange={handleChange} />
          )}

          {currentTab === 6 && (
            <LicencedWarehouseOperations
              formData={formData}
              handleChange={handleChange}
            />
          )}
          {currentTab === 7 && (
            <Products formData={formData} handleChange={handleChange} />
          )}
          {currentTab === 8 && (
            <Agreement formData={formData} setFormData={setFormData} />
          )}
          {/* Add more conditionally rendered sections for each step */}
          {/* Navigation Buttons */}
          <div className="form-footer-main">
            {currentTab === 1 ? (
              ""
            ) : (
              <button
                type="button"
                className="btn-cust-back btn-back-tab"
                onClick={handleBack}
                disabled={currentTab === 1}
              >
                Back
              </button>
            )}
            {currentTab < 8 ? (
              <button
                type="button"
                className="btn-cust-next btn-next-tab"
                onClick={handleNext}
                data-id={currentTab}
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                className="btn-cust-next"
                onClick={() => console.log("Submit logic here")}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WholesaleValidationForm;
