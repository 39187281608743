import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

const Attachment = forwardRef(({ formData, setFormData }, ref) => {
  const [selectedFiles, setSelectedFiles] = useState({
    WDACertificate: formData?.files?.WDACertificate,
    GDPCertificate: formData?.files?.GDPCertificate,
  });

  const wdaRef = useRef(null);
  const gdpRef = useRef(null);

  const handleFileChange = (event, documentType) => {
    const file = event.target.files[0];
    setSelectedFiles((prev) => ({
      ...prev,
      [documentType]: file,
    }));
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        [documentType]: file,
      },
    }));
  };

  // Expose the clearFileInputs method to the parent component
  useImperativeHandle(ref, () => ({
    clearFileInputs: () => {
      if (wdaRef.current) {
        wdaRef.current.value = null;
      }
      if (gdpRef.current) {
        gdpRef.current.value = null;
      }
      setSelectedFiles({
        WDACertificate: null,
        GDPCertificate: null,
      });
    },
  }));

  return (
    <div>
      <div id="step6" className="step-content-body out1">
        <div className="formdv container">
          <p className="formdetailstitle">
            <span>Attachment</span>
          </p>
          <div className="row">
            <div
              className="col-md-6 col-sm-12 form-group cursor_p left_col_upload"
              id="wda-cerificate"
            >
              <div className="fileUpload" id="wdlupload">
                <span className="fileuploadimg">
                  <img
                    src="https://proph.co.uk/public/front/assets/images/fileupload.png"
                    alt="file upload icon"
                    onClick={() => wdaRef.current.click()}
                  />
                </span>
                <span className="success" id="wdl">
                  <img
                    src="https://proph.co.uk/public/front/assets/images/success.png"
                    alt="success icon"
                  />
                </span>
                {/* Validation spans */}
              </div>
              <input
                type="file"
                name="WDACertificate"
                className="upload cursor_p form-control com-image"
                ref={wdaRef}
                onChange={(e) => handleFileChange(e, "WDACertificate")}
                accept=".pdf, image/*" // Accept PDFs and images
              />
              <label
                htmlFor="new-image"
                className="lbl-image form-control attachementlable upload-label-sec"
              >
                {selectedFiles.WDACertificate
                  ? selectedFiles.WDACertificate.name.slice(0, 20)
                  : formData?.files?.WDACertificate || "Upload WDA Certificate *"}
              </label>
            </div>

            <div
              className="col-md-6 col-sm-12 form-group cursor_p right_col_upload"
              id="gdp-cerificate"
            >
              <div className="fileUpload" id="gdpupload">
                <span className="fileuploadimg">
                  <img
                    src="https://proph.co.uk/public/front/assets/images/fileupload.png"
                    alt="file upload icon"
                    onClick={() => gdpRef.current.click()}
                  />
                </span>
                <span className="success" id="gdp">
                  <img
                    src="https://proph.co.uk/public/front/assets/images/success.png"
                    alt="success icon"
                  />
                </span>
                {/* Validation spans */}
              </div>
              <input
                type="file"
                name="GDPCertificate"
                className="upload cursor_p form-control com-image"
                ref={gdpRef}
                onChange={(e) => handleFileChange(e, "GDPCertificate")}
                accept=".pdf, image/*" // Accept PDFs and images
              />
              <label
                htmlFor="new-image1"
                className="lbl-image form-control attachementlable upload-label-sec"
              >
                {selectedFiles.GDPCertificate
                  ? selectedFiles.GDPCertificate.name.slice(0, 20)
                  : formData?.files?.GDPCertificate ||  "Upload GDP Certificate *"}
              </label>
            </div>

            <div className="col-sm-6 sizetextdv hide">
              <span>Maximum upload file size is 10MB</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Attachment;
