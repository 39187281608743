import React from "react";
import { Link } from "react-router-dom";
import { services } from "../config/globleData";

const Services = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="row text-center servicesrow">
          {services.map((service, index) => (
            <div className={`col-md-3 ${service.className}`} key={index}>
              <div className="row antibiotic"></div>
              <h4 className="service-heading">{service.title}</h4>
              <div className="descdv row">
                <p className="text-muted">{service.description}</p>
              </div>
              <div
                className="readmoredv row"
                style={{ justifyContent: "center" }}
              >
                {service.isExternal ? (
                  <a
                    style={{ textDecoration: "none" }}
                    href={service.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="readmore"
                  >
                    {service.linkText}
                  </a>
                ) : (
                  <Link to={service.link} className="readmore">
                    {service.linkText}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
