import React, { useState } from "react";

const LicencedWarehouseOperations = ({ formData, handleChange }) => {
  return (
    <div>
      <div
        className="warehouse-details-section tabs-edits-section"
        style={{ display: "block" }}
      >
        <h3 className="form-main-title">Licenced Warehouse Operations</h3>
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <div className="sops-title-group">
              <span>Yes</span>
              <span>No</span>
              <span>N/A</span>
            </div>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              1. Is all temperature monitoring equipment appropriately
              calibrated?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL11"
                  className="radio-check"
                  id="warehouse_yes_1"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_yes_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL11"
                  className="radio-check"
                  id="warehouse_no_1"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_no_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL11"
                  className="radio-check"
                  id="warehouse_na_1"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_na_1"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              2. Is the ambient storage of pharmaceuticals controlled and
              monitored to stay between +8°C to +25°C?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL12"
                  className="radio-check"
                  id="warehouse_yes_2"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_yes_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL12"
                  className="radio-check"
                  id="warehouse_no_2"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_no_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL12"
                  className="radio-check"
                  id="warehouse_na_2"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_na_2"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              3. Is the cold storage of pharmaceuticals controlled and monitored
              to stay between +2°C to +8°C?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL13"
                  className="radio-check"
                  id="warehouse_yes_3"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_yes_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL13"
                  className="radio-check"
                  id="warehouse_no_3"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_no_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL13"
                  className="radio-check"
                  id="warehouse_na_3"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_na_3"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              4. Are the goods received / dispatch areas secure and suitably
              protected from adverse weather?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL14"
                  className="radio-check"
                  id="warehouse_yes_4"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_yes_4"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL14"
                  className="radio-check"
                  id="warehouse_no_4"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_no_4"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL14"
                  className="radio-check"
                  id="warehouse_na_4"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_na_4"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group mb-0">
          <div className="col-md-10">
            <label className="form-label-title">
              5. Do you have pest control procedures in place?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL15"
                  className="radio-check"
                  id="warehouse_yes_5"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_yes_5"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL15"
                  className="radio-check"
                  id="warehouse_no_5"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_no_5"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL15"
                  className="radio-check"
                  id="warehouse_na_5"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="warehouse_na_5"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicencedWarehouseOperations;
