import React, { useState } from "react";

const CommentSection = ({ formData, setFormData }) => {
  // State to manage all form data

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      showDisclaimer: name === "comment" ? false : prevData.showDisclaimer,
    }));
  };

  return (
    <div>
      <div className="step-content-body out1">
        <div className="formdv container">
          <p className="formdetailstitle">
            <span>Comment Section</span>
          </p>
          {formData.showDisclaimer && (
            <div className="row">
              <div className="col-md-12 disclaimer">
                <p>
                  Include any other information you think will be important for
                  registration/returns/payment/delivery site timings.
                  (Information to insert is Optional in this field)
                </p>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-xs-12 col-sm-12 form-group left_col">
              <textarea
                className="form-control mat-input"
                rows="5"
                name="comment"
                placeholder={
                  formData.showDisclaimer
                    ? "Include any other information you think will be important for registration/returns/payment/delivery site timings."
                    : "Include your comments here..."
                }
                onFocus={() =>
                  handleInputChange({ target: { name: "comment" } })
                }
                onChange={handleInputChange}
                value={formData.comment}
              />
            </div>
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
