import React from "react";
import Navbar from "../globleComponents/Navbar";
import ContactUS from "../globleComponents/ContactUS";
import Footer from "../globleComponents/Footer";

const ServicesPage = () => {
  return (
    <div class="overflow-x-h">
      <Navbar />
      <div class="container" style={{ marginTop: "30px" }}>
        <div class="row servicedv" id="servicetxt">
          <div class="col-lg-12">
            <h1>Services</h1>

            <div class="row">
              <div class="col-sm-2 service-image">
                <img
                  src="https://proph.co.uk/public/front/assets/images/trucking1-150x150.jpg"
                  alt="Wholesalers"
                  title="Wholesalers"
                />
              </div>

              <div class="col-sm-10" id="wholesaler">
                <h4>WHOLESALE PHARMACEUTICALS </h4>
                <p>
                  Whether you are a full line or short line wholesaler, there
                  are thousands of products we can offer. Our expertise in
                  working with wholesalers is identifying key products and
                  purchasing these on a volume basis thereby allowing our
                  customers to benefit from economies of scale in our purchasing
                  power. From UK Ethicals, Generics and a plethora of surgical
                  consumables, we have the ability to source products at the
                  most competitive prices.
                </p>
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image" id="pharmacy">
                <img
                  src="https://proph.co.uk/public/front/assets/images/service2-150x150.jpg"
                  alt="Pharmacy Groups &amp; Independent Chemists"
                  title="Pharmacy Groups &amp; Independent Chemists"
                />
              </div>

              <div class="col-sm-10">
                <h4>Retail Medicines &amp; Vaccines </h4>
                <p>
                  Propharma works with a number of groups and independent
                  pharmacies supplying a number of generic and branded medicines
                  through to Over the Counter type products and with a low
                  minimum spend, we cater to the needs of the local high street
                  pharmacy to multiples operating across the country.
                </p>
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image">
                <img
                  src="https://proph.co.uk/public/front/assets/images/service4-150x150.jpg"
                  alt="Hospitals &amp; NHS"
                  title="Hospitals &amp; NHS"
                />
              </div>

              <div class="col-sm-10">
                <h4>Hospitals &amp; NHS </h4>
                <p>
                  Propharma is working with a number of overseas pharmaceutical
                  manufacturers from generic medicines to branded medical
                  devices and aesthetic products. We carefully choose partners
                  that operate to EuGMP standards with exemplary products backed
                  by either UK MHRA Marketing Authorisations to ISO/CE Compliant
                  medical devices suitable for the UK and European public
                  market.
                </p>
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image">
                <img
                  src="https://proph.co.uk/public/front/assets/images/care-home.png"
                  alt="Care Homes"
                  title="Care Homes"
                />
              </div>

              <div class="col-sm-10">
                <h4>Care Homes </h4>
                <p>
                  Propharma caters for a variety of care home and care at home
                  businesses, supplying medical disposables to wound care both
                  branded and generic versions available, weekly delvieries
                  available and urgent requests can be accommodated.
                  <br />
                  More recently, Propharma are offering incontinence products
                  and nutritional drinks to dysphagia supplements and nursing
                  supplies.
                </p>
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image">
                <img
                  src="https://proph.co.uk/public/front/assets/images/Service5-150x150.jpg"
                  alt="Aesthetic/Orthopedic"
                  title="Aesthetic/Orthopedic"
                />
              </div>

              <div class="col-sm-10" id="clinic">
                <h4>Aesthetic Medical Devices &amp; Consumables </h4>
                <p>
                  Our newest addition is the range of products aimed at back and
                  leg pains to facial aesthetics. Working with leading brands
                  like Orthovisc and Synvisc for Orthopedic clinics and for the
                  aesthetic market, botox, dysport to dermal fillers from
                  Juvederm and many other brands.
                </p>
                <p>
                  We hope that we can cater for your needs, please fill in an
                  application and one of our consultants will be in touch to
                  discuss your needs and how best we can serve for a win win
                  business relationship.
                </p>
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image">
                <img
                  src="https://proph.co.uk/public/front/assets/images/service3-150x150.jpg"
                  alt="Online Doctors"
                  title="Online Doctors"
                />
              </div>

              <div class="col-sm-10">
                <h4>Online Doctors </h4>
                <p>
                  A growing number of online pharmacies and a opening up of
                  certain conditions to be treated by an online Doctor has
                  opened up a new era in prescribing and dispensing. We are able
                  to offer these businesses a wide range of branded and generic
                  medicines along with competitive pricing to ensure success
                  against their local marketplace.
                </p>
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image">
                <img src="https://proph.co.uk/public/front/assets/images/service1-150x149.jpg" />
              </div>

              <div class="col-sm-10">
                <h4>Travel Clinics </h4>
                <p>
                  The UK is the by far the largest in Europe for travellers
                  going abroad and not just hopping over to the mainland but,
                  trekking, mountain climbing and many more exotic type
                  adventures beyond the horizon.
                </p>
                <p>
                  Our Travel division has access to all the major vaccinations
                  with 48 hour delivery available in urgent circumstances,
                  Propharma sells thousands of Anti Malarial drugs and offer our
                  customers fantastic prices on these and many other medicines
                  for this market. together with Over the Counter products
                  specifically for travellers from Water treatment tablets to
                  diarrhoea packs.
                </p>{" "}
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image_new">
                <img src="https://proph.co.uk/public/front/assets/images/mylocaltest_ covid_&amp;_blood_testing.jpg" />
              </div>

              <div class="col-sm-10" id="mylocaltest_covid">
                <h4>MyLocalTest - Covid &amp; Blood Testing </h4>
                <p>
                  Pro-Pharma's advanced solution that allows users to book
                  appointments at local pharmacies based on their location and
                  time preference.
                </p>{" "}
                <p>
                  For the regular travelers who fly to or from UK, this platform
                  is introduced, to provide them more convenience in searching
                  for the nearest pharmacies based on their geo location so that
                  they can book appointment for RT PCR and Lateral Flow (LFT)
                  COVID-19 tests to comply with the UK government’s guidelines.{" "}
                </p>{" "}
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image_new">
                <img src="https://proph.co.uk/public/front/assets/images/vyasalab_nutritional_supplements.jpg" />
              </div>

              <div class="col-sm-10" id="vyasalab">
                <h4>VyasaLab - Nutritional Supplements </h4>
                <p>
                  This is Pro-Pharma's advanced supplement with proven and old
                  age recipes using natural ingredients like turmeric, ginger,
                  honey, and lemon. An effective way to boost up the immunity
                  and protect your health from cold especially.{" "}
                </p>{" "}
                <br />
                <p>
                  This is beneficial for the consumers of all age groups. People
                  in different parts of the world are using these ingredients
                  from many years, we combined all of them together to make it
                  easy for the consumers.{" "}
                </p>
              </div>
            </div>
            <hr class="service-hr" />

            <div class="row">
              <div class="col-sm-2 service-image_new">
                <img src="https://proph.co.uk/public/front/assets/images/pharmaceutical_exports.jpg" />
              </div>

              <div class="col-sm-10" id="pharmaceutical_exports">
                <h4>Pharmaceutical Exports </h4>
                <p>
                  Pro-Pharma is a leading wholesaler and distributor of
                  health-related products around the world. We have specialized
                  in providing healthcare products like prescriptions medicines,
                  over-the-counter medicines, medical equipment and supplies
                  produced under the standards of authorities. Our customers
                  include pharmacies, hospitals, clinics, doctors, health care
                  providers and other wholesalers.
                </p>
              </div>
            </div>
            <hr class="service-hr" />
          </div>
        </div>
      </div>
      <ContactUS />
      <Footer />
    </div>
  );
};

export default ServicesPage;
