import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../config/assets/navbar-logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="container" style={{ width: "100%", display: "block" }}>
        <div className="header_sec">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              id="icon-mobile"
              onClick={handleMenuToggle}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link to="/">
              <img
                src={logo}
                alt="Pharmaceutical Wholesaler UK"
                title="Pharmaceutical Wholesaler UK"
                className="img-responsive"
              />
            </Link>
          </div>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
            id="menubar"
          >
            <ul
              className="nav navbar-nav navbar-left menu_margin"
              style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}
            >
              <li>
                <Link style={{ textDecoration: "none" }} to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link style={{ textDecoration: "none" }} to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link style={{ textDecoration: "none" }} to="/services">
                  Services
                </Link>
              </li>
              <li>
                <Link style={{ textDecoration: "none" }} to="/compliance">
                  Compliance
                </Link>
              </li>
              <li className="news_list">
                <Link style={{ textDecoration: "none" }} to="/news">
                  News
                </Link>
              </li>
              <li className="contact-menu">
                <Link style={{ textDecoration: "none" }} to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
            <div id="myNavbar" className="new_myNavbar new_bar">
              <ul className="righ-bar-menu" style={{ marginTop: "37px" }}>
                <li className="hover-dropdown login-dropdown">
                  <a href="#">
                    Login
                    <i className="fa fa-angle-down down-arrow" aria-hidden="true"></i>
                  </a>
                  <ul className="dropdown-menubar">
                    <li>
                      <a href="https://pro-pharmacy.co.uk/login" target="_blank">
                        Login to Filler Store
                      </a>
                    </li>
                    <li>
                      <a href="https://pro-vaccine.co.uk" target="_blank">
                        Login to Vaccine Store
                      </a>
                    </li>
                    <li>
                      <a href="https://mylocaltest.co.uk/login" target="_blank">
                        Login to MyLocalTest
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
