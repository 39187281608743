import React from "react";
import TechnicalAgreement from "../../../config/assets/TechnicalAgreement.pdf";

const TermsAndConditionsOfSaleSupplier = () => {
  return (
    <div>
      <div id="step8" class="step-content-body out1">
        <div class="formdv container">
          <p class="formdetailstitle">
            <span>Terms &amp; Conditions Of Sale</span>
          </p>
          <div class="row technical_doc">
            <div class="col-sm-12 form-group">
              Technical agreement :{" "}
              <a href="../../../config/assets/TechnicalAgreement.pdf" class="technicaldoc">
                Click Here To Download Technical Agreement
              </a>
            </div>
          </div>
          <div class="row technical_doc_upload">
            <div class="col-md-6 col-sm-12 form-group cursor_p left_col_upload">
              <div class="fileUpload" id="technicalupload">
                <span class="fileuploadimg">
                  <img
                    onclick="javascript:document.getElementById('technicalagreement').click();"
                    src="https://proph.co.uk/public/front/assets/images/fileupload.png"
                  />
                </span>
                <div
                  id="txt4"
                  class="form-control attachementlable"
                  onclick="javascript:document.getElementById('technicalagreement').click();"
                >
                  Upload Technical Agreement*
                </div>
                <input
                  id="technicalagreement"
                  type="file"
                  onchange="ChangeText(this, 'txt4');"
                  class="upload cursor_p"
                  name="technical_agreement"
                  required=""
                />

                <span class="success" id="techagreement">
                  <img src="https://proph.co.uk/public/front/assets/images/success.png" />
                </span>
                <span id="techagreementnovalidator" class="loaddata1"></span>
                <span id="techagreementvalidator" class="loaddata1"></span>
                <span
                  id="uploadBtnnovalidator"
                  class="loaddata"
                  style={{ display: "none" }}
                ></span>
              </div>
            </div>
            <div class="col-sm-6 sizetextdv hide">
              <span>Maximum upload file size is 10MB</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 form-group left_col" id="signaturedv">
              <lable for="signature" class="mat-label">
                Name Of Person Completing the Form*
              </lable>

              <input
                name="signature"
                value=""
                data-bvalidator="required"
                id="signature"
                class="form-control mat-input"
                maxlength="50"
                type="text"
                placeholder=""
              />

              <span
                id="signatureValidator"
                class="loaddata"
                style={{ display: "none" }}
              ></span>
            </div>

            <div class="col-sm-6 form-group right_col" id="signatureemaildv">
              <lable for="signatureemail" class="mat-label">
                Email*
              </lable>

              <input
                name="signatureEmailAddress"
                value=""
                data-bvalidator="email,required"
                data-bvalidator-msg="Please enter Email id"
                id="signatureEmailAddress"
                class="form-control mat-input"
                type="text"
                placeholder=""
              />

              <span
                id="signatureEmailAddressNoValidator"
                class="loaddata"
                style={{ display: "none" }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsOfSaleSupplier;
