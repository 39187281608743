import React, { useEffect } from "react";
import Navbar from "../globleComponents/Navbar";
import ContactUS from "../globleComponents/ContactUS";
import Footer from "../globleComponents/Footer";
import { useLocation } from "react-router-dom";

const TermsAndCondition = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(1, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row aboutusdv">
          <div class="col-lg-12">
            <h1>Terms &amp; Conditions of Sale</h1>

            <p>
              February 2019 Propharma - UK Limited
              <br />
              ("we", "us", "the Companies")
            </p>

            <p>
              These terms and conditions shall apply to all sales by the
              Companies which for the purposes of these terms shall mean
              Propharma - UK Limited, and Propharma and shall prevail and
              supercede over your terms and conditions (if any), whether printed
              on any invoice, order, quotation, specification or any other
              document provided by you.
            </p>

            <ol>
              <li>
                These conditions (as may be updated from time to time) apply to
                all orders for products placed by our customers ('you', 'your')
                to the exclusion of all other terms and conditions (including
                any of your terms and conditions under any purchase order,
                confirmation of order or any other document). These terms shall
                supersede any specific product terms that might apply to your
                order and in the event of any inconsistency with any other terms
                that might apply, these terms shall prevail.
              </li>

              <li>
                These conditions (as may be updated from time to time) apply to
                all orders for products placed by our customers ('you', 'your')
                to the exclusion of all other terms and conditions (including
                any of your terms and conditions under any purchase order,
                confirmation of order or any other document). These terms shall
                supersede any specific product terms that might apply to your
                order and in the event of any inconsistency with any other terms
                that might apply, these terms shall prevail.
              </li>

              <li>
                When we accept orders placed by your employees, agents or
                contractors we will not be responsible for vetting the
                authorisation of those employees, agents or contractors and we
                will not be liable to you for any financial losses incurred by
                you by virtue of our accepting any order.
              </li>
            </ol>

            <h4>AGREEING TO TERMS</h4>

            <p>
              If you do not agree to Propharma-UK Ltd's Terms of Service (ToS),
              including the company's Terms &amp; Conditions and Privacy Policy
              as posted here on this website, please do not use this site or any
              services offered by this site or Propharma-UK Ltd. Your use of
              this site indicates acceptance of the company's Terms of Service
              which can change at any time.
            </p>

            <h4>1. Application of Conditions</h4>

            <ol>
              <li>
                These conditions shall govern and be incorporated into every
                contract for the sale of goods and/or services made by, or on
                behalf of, the Seller with the Buyer.
              </li>

              <li>
                These conditions shall apply to the exclusion of, and shall
                prevail over any terms and conditions contained or referred to
                in, any documentation submitted by the Buyer, or in any
                correspondence, or elsewhere, or implied by trade custom,
                practice or course of dealing, unless specifically excluded or
                varied in writing by a director or other authorised
                representative of the Seller. Any provisions to the contrary are
                excluded.
              </li>

              <li>
                Acceptance by the Buyer of delivery of goods shall (without
                prejudice to any other manner in which acceptance of these terms
                may be evidenced) be deemed to constitute unqualified acceptance
                of these conditions.
              </li>

              <li>
                If subsequent to any contract of sale which is subject to these
                conditions, a contract of sale is made with the same Buyer
                without any reference to any conditions of sale or purchase,
                such a contract, however made, shall be deemed to be subject to
                these conditions.
              </li>
            </ol>

            <h4>2. Prices</h4>

            <ol>
              <li>
                The prices payable for the goods shall be the prices agreed by
                the Seller and the Buyer, or in the absence of any agreement
                shall be those set out in the Seller’s price lists current at
                the time of dispatch of the goods.
              </li>

              <li>
                The Seller shall have the right at any time to withdraw any
                discount from its normal prices and to revise prices to take
                into account of inflation and\or increases in costs including
                (without limitation) cost of any goods, materials, carriage,
                labour or overheads, the increase or imposition of any tax duty
                and other levy and any variation in exchange rates.
              </li>

              <li>
                Prices are exclusive of any applicable value added tax which the
                buyer shall be additionally liable to pay to the seller.
              </li>
            </ol>

            <h4>3. Terms of Payment</h4>

            <ol>
              <li>Subject to clause 7 the following conditions shall apply:</li>

              <li>
                The Seller may invoice the Buyer for the price of the goods on,
                or at any time after notifying the Buyer that the goods are
                ready for collection or dispatch. If the goods are to be
                delivered other than at the Seller’s premises, the Seller may
                invoice the Buyer for the price on, or at any time after
                delivery of the goods.
              </li>

              <li>
                Unless otherwise notified, the Buyer shall pay the Sellers
                invoices in full (without any deduction, set-off or
                counterclaim) prior to delivery, unless otherwise agreed.
              </li>

              <li>
                Time of payment shall be of the essence of all contracts between
                the Buyer and the Seller to which these conditions apply.
              </li>

              <li>
                The seller may suspend the provision of goods from the supplier
                where any amounts are overdue under the contract with the buyer
                until the amount has been paid.
              </li>

              <li>
                Any extension of credit allowed to the buyer may be changed or
                withdrawn at any time
              </li>

              <li>
                If in the Seller’s opinion the credit worthiness of the Buyer
                has deteriorated prior to the delivery of the goods the Seller
                may require full or partial payment of the price prior to
                delivery, or suspend delivery altogether.
              </li>

              <li>
                Where a payment is late as defined in the Late Payment of
                Commercial Debts (Interests) Act 1998 (LPCD), the interest rates
                set out in the LPCD as amended from time to time shall be
                applicable.
              </li>

              <li>
                The Seller reserves the right to charge interest (both before
                and after any judgement) at the rate of 4% per annum above the
                current base rate of Barclays Bank plc, on all outstanding
                payments from the date due and up to and including the date on
                which final settlement is made in full. The buyer shall pay the
                interest on demand.
              </li>

              <li>
                Seller reserves the right to charge late payment charges of £150
                per invoice and interest as above, if at anytime the account is
                left overdue without any agreement beyond the credit limit.
              </li>

              <li>
                The supplier may charge the buyer a reasonable administration
                fee in respect to re-presented and dishonoured cheques to cover
                its consequential direct and indirect costs.
              </li>
            </ol>

            <h4>4. Delivery</h4>

            <ol>
              <li>General</li>

              <ol>
                <li>
                  Delivery of the goods shall be facilitated by the Seller or by
                  one of the seller’s approved third-party service provider(s),
                  delivering the goods to a location specified by the Buyer at
                  point of sale or to an alternative delivery location that has
                  been agreed upon by the Buyer in liaison with the seller’s
                  third-party service provider.
                </li>

                <li>
                  Any delivery dates and times specified at point of sale by the
                  Seller are intended to be an estimate and are not the essence
                  of any contract between the Buyer and Seller.
                </li>

                <li>
                  The Sellers packaging is validated in compliance with product
                  specifications to ensure goods are maintained in transit. Cold
                  chain goods are sent via an MHRA accredited provider, namely,
                  Polar Speed. Unless otherwise agreed, goods may be shipped
                  using validated packaging for a minimum of 21hrs. The Seller
                  is unable to advise on the performance, integrity or
                  sustainability of the goods once the Buyer has claimed receipt
                  and they have entered into an uncontrolled environment. The
                  Buyer is under obligation to store the goods in a suitable
                  environment immediately upon receipt.
                </li>
              </ol>

              <li>Receipt of goods</li>

              <ol>
                <li>
                  The Buyer shall be prepared to accept receipt of goods within
                  a time period and a location specified at point of sale. If
                  the Buyer is unable to receive the goods upon the first
                  delivery attempt, the buyer will accept liability for the
                  goods hence forth.
                </li>

                <li>
                  Unless otherwise expressly agreed, the Seller may affect
                  delivery in one or more instalments. Where the goods are
                  delivered in instalments; each instalment shall be treated as
                  a separate contract. Failure by the Seller to deliver one or
                  more of the instalments in accordance with these conditions,
                  or any claim by the Buyer in respect of one or more
                  instalments, shall not entitle the Buyer to treat the contract
                  as a whole as repudiated.
                </li>

                <li>
                  The Seller is under no obligation to remunerate the buyer for
                  losses incurred as a result of a failure to receive goods on
                  the first delivery attempt in accordance with the terms and
                  conditions. The buyer is defined as the individual whom
                  places, pays for or signs for the consignment i.e account
                  holder.
                </li>

                <li>
                  If the Buyer refuses or fails to take delivery of the goods
                  delivered in accordance with the terms and conditions or fails
                  to take any action necessary on its part for delivery and/or a
                  shipment of the goods, the Seller shall be entitled to
                  terminate the contract with immediate effect, to dispose of
                  the goods as it deems fit, and to recover from the Buyer any
                  loss or associated costs.
                </li>

                <li>
                  The Seller shall not be held liable for goods, whether cold
                  chain or ambient, once they have been accepted by a third
                  party on behalf of the Buyer or left in a requested location
                  on behalf of the Buyer. This will include, but is not limited
                  to; employees of the Buyer, hotel/hospital/apartment building
                  staff, safe places, neighbours etc. The Buyer shall ensure
                  that any third parties authorised to accept deliveries on
                  behalf of the Buyer have been notified and are prepared for
                  the acceptance of the goods. The Buyer shall ensure that the
                  authorised third party accepting the delivery of the goods
                  store them in the correct conditions until the goods are
                  received by the Buyer.
                </li>
              </ol>

              <li>Third Party Service Providers</li>

              <p>
                The seller may choose to expedite delivery through the use of a
                verified third-party service provider, in such an event, the
                following shall apply:
              </p>

              <ol>
                <li>
                  The Seller will ensure that delivery is facilitated by an
                  “approved” third-party service provider, the actual provider
                  utilised will remain at the sole discretion of the Seller
                  subject to suitability. The seller will endeavour to satisfy a
                  Buyer’s specific courier usage requests however, the Buyer
                  understands that a number of factors may inhibit the Sellers
                  ability to offer the buyers chosen courier and as a result,
                  the Seller cannot guarantee the preferred courier service
                  option.
                </li>

                <li>
                  The Seller will not be held liable for, or acknowledge the
                  validity of, any agreements made between the Buyer and a
                  representative of any third-party service provider in relation
                  to delivery without the express written consent of the seller.
                </li>

                <li>
                  The Buyer understands that any changes to delivery details
                  must be made by contacting the seller or a member of the
                  third-party service providers customer service team. The Buyer
                  shall hold all records of contact until delivery of the goods
                  have been satisfied.
                </li>

                <li>
                  If a delivery has been attempted by a third-party service
                  provider, it is the Buyer’s responsibility to arrange a
                  collection from the depot or collection point.
                </li>

                <li>
                  The Buyer understands that any third-party value-added
                  service(s) or application(s) including but not limited to;
                  email notifications, SMS notifications, upgrades to delivery
                  times or parcel shop deliveries shall be considered as
                  additional services and are not guaranteed by the Seller. The
                  Seller shall not be held liable for any delays in delivery
                  caused by the failure of these additional services.
                </li>

                <li>
                  The Buyer shall ensure that goods accepted by a third-party
                  representative acting on their behalf are stored in accordance
                  with GDPR regulations to ensure there are no breaches in
                  relation to personal data displayed on the packaging upon
                  which the goods are held or its contents.
                </li>
              </ol>
            </ol>

            <h4>5. Cancellation</h4>

            <ol>
              <li>
                Orders may only be cancelled by the Buyer on written notice, and
                on payment to the Seller of cancellation charges, which take
                into account expenses incurred and commitments already made by
                the Seller as a consequence of the Buyer having placed its
                order. Orders for cold chain (refrigerated), goods cannot be
                cancelled after they have been dispatched.
              </li>
            </ol>

            <h4>6. Returns</h4>

            <ol>
              <li>No goods are supplied on a sale or return basis.</li>

              <li>
                Returns on cold chain (refrigerated) goods are not accepted
                under any circumstances, unless an error has occurred that is
                irrefutably proven to be the fault of the seller. Only Wholesale
                customers holding a Wholesale Dealers’ Licence are permitted to
                return items or those companies that have given confirmation of
                Temperature Monitoring and Fridge Mapping to Propharma-UK.
              </li>

              <li>
                Any Decommissioned goods cannot be returned after 72 hours as
                per MHRA guidelines.{" "}
              </li>

              <li>
                Any errors that may occur as a result of a third-party providers
                (such as courier companies) failure to deliver a service will
                not constitute grounds for a return. The seller accepts no
                responsibility for issues that may occur outside of their
                control.
              </li>

              <li>
                Returns are only considered if agreed in writing and accompanied
                with returns form issued by the Seller. Failure to adhere to the
                terms and conditions outlined within the sellers returns policy
                will nullify the buyers claim for return.
              </li>

              <li>
                Breakages, errors or faulty goods must be reported by email
                within 72 hours of receipt of the order. Incorrect goods
                (excluding cold chain goods) must be returned unopened in the
                original packaging within 5 days of receipt of order. Cold chain
                returns to only be accepted within 24hrs of receipt if there
                have been issues with the courier or picking errors on our part.
                Breakages must be kept with the packaging and retained for
                inspection by the carriers.
              </li>

              <li>
                The buyer is responsible for ensuring that goods are returned in
                a suitable condition. Contaminated and/or compromised goods that
                are considered a risk to health and safety are not authorised
                for return, this includes the return of exposed needles which
                are in breach of infection control protocols.
              </li>

              <li>
                The seller reserves the right to destroy any goods that are
                returned by the buyer without the express consent of the seller.
              </li>
            </ol>

            <h4>7. Risk and Title</h4>

            <ol>
              <li>
                Subject to clause 7, risk of damage to or loss of goods shall
                pass to the Buyer as follows:
              </li>

              <ol>
                <li>
                  in the case of goods to be delivered to the Sellers premises,
                  at the time when the Seller notifies the Buyer that the goods
                  are available for collection or delivery;
                </li>

                <li>
                  in the case of the goods to be delivered to the Buyer, at the
                  time of delivery or, if the Buyer wrongfully fails to take
                  delivery of the goods, at the time when the Seller has
                  tendered delivery of the goods. 7.2 Notwithstanding delivery
                  and the passing of risk, title of the goods shall not pass to
                  the Buyer until the Seller has received, in cash or cleared
                  funds, payment in full of the agreed price and all other
                  amounts owed under these conditions by the Buyer to the
                  Seller.
                </li>
              </ol>

              <li>
                Until title in the goods has passed to the Buyer, the Buyer
                shall hold the goods as the Seller’s fiduciary agent and bailee
                and shall
              </li>

              <ol>
                <li>
                  not part with possession of the goods otherwise than in
                  accordance with Clause 7.6;
                </li>

                <li>
                  keep the goods properly stored, protected and insured as the
                  Seller’s property, taking all reasonable steps to prevent any
                  damage to or deterioration of the goods;
                </li>

                <li>
                  keep the goods free from any charge, lien or other
                  encumbrance. If the Buyer fails to do so, all monies owing by
                  the Buyer to Seller shall (without prejudice to any other
                  right or remedy to the Seller) immediately become due and
                  payable;
                </li>

                <li>
                  notify the Seller forthwith upon the happening of any of the
                  events set out in clause 13 give the Seller such information
                  relating to the goods as the Seller may from time to time
                  require.
                </li>
              </ol>

              <li>
                Until title in the goods has passed to the Buyer (providing that
                the goods are still in existence and have not been re-sold) the
                Seller shall be entitled at any time to require the Buyer to
                deliver up the goods to the Seller and if the Buyer fails to do
                so with immediate effect, to enter any premises of the Buyer or
                any third party where the goods are stored, and inspect or
                repossess the goods.
              </li>

              <li>
                Any rights the Buyer may have to possession of the goods shall
                in any event cease if:
              </li>

              <ol>
                <li>
                  any sum owed by the Buyer to the Seller (whether in respect of
                  the goods or otherwise) is not paid to the Seller by the date
                  when it is due;
                </li>

                <li>
                  upon the happening of any of the events set out in clause
                  13.1.1;
                </li>

                <li>
                  the Buyer commits a breach of any contract with the Seller.
                </li>
              </ol>

              <li>
                Whilst the Buyer is in possession of the goods with the Seller’s
                consent (but not otherwise) the Buyer may in the ordinary course
                of its business sell the goods provided that:
              </li>

              <ol>
                <li>
                  as between the Buyer and its sub-buyer or customer, the Buyer
                  shall sell the goods as principal and shall not be empowered
                  to commit the Seller to any contractual relationship with, or
                  liability to, the sub-buyer or customer or any other person;
                </li>

                <li>
                  as between the Seller and the Buyer, the Buyer shall sell the
                  goods in a fiduciary capacity as agent for the Seller;
                </li>

                <li>
                  the Buyer shall hold the proceeds of such sales on trust for
                  the Seller;
                </li>

                <li>
                  the Seller shall be entitled to any interest earned on the
                  fiduciary account;
                </li>

                <li>
                  if proceeds of the sales received by the Seller exceed all
                  amounts owed buy the Buyer to the Seller in respect of the
                  goods, the Seller shall return the excess to the Buyer.
                </li>

                <li>
                  Notwithstanding the provisions of clause 7, the Seller shall
                  be entitled to bring an action against the Buyer for the price
                  of the goods in event of non-payment by the Buyer by the due
                  date even though property in the goods has not passed to the
                  Buyer and/or shall have the right by notice to the Buyer at
                  any time after delivery to pass property in the goods to the
                  Buyer as from the date of such notice.
                </li>
              </ol>
            </ol>

            <h4>8. Liability</h4>

            <ol>
              <li>
                The Seller warrants that the goods shall be of satisfactory
                quality and shall correspond with any specification at the time
                of delivery. All other conditions, warranties and
                representations expressed or implied by statute, common law or
                otherwise in relation to the goods are excluded to the fullest
                extent permitted by law.
              </li>

              <li>
                The Seller’s only obligation in relation to defective goods
                shall be at its option to make good any shortage or non-delivery
                and/or appropriate to replace any goods found to be damaged or
                defective and/or to refund the cost of such goods to the Buyer.
              </li>

              <li>
                The Seller’s aggregate liability to the Buyer whether for
                negligence, breach of contract, misrepresentation or otherwise
                shall in no circumstances exceed the price paid by the Buyer for
                the goods in respect of any occurrence or series of occurrences.
              </li>

              <li>The Seller shall not be liable to the Buyer:</li>

              <ol>
                <li>
                  for defects in the goods caused by abnormal or unsuitable
                  conditions of storage (including in the manner in which the
                  goods are stacked and moved within the warehouse or other
                  storage facility) or use by, or any act, neglect, or default
                  of the Buyer or any third party;
                </li>

                <li>
                  for defects in the goods or discrepancies in delivery, unless
                  notified to the seller within 72 hours of receipt of the goods
                  by the Buyer, or where the defect would not have been apparent
                  on reasonable inspection, within 12 months of delivery;
                </li>

                <li>
                  for defects in the goods arising from any drawing, design or
                  specification supplied by the Buyer;
                </li>

                <li>
                  for goods disposed of by the buyer without the express consent
                  of the seller.
                </li>

                <li>if the total price of the goods has not been paid;</li>

                <li>
                  unless the Buyer with the Seller’s prior authorisation,
                  returns alleged defective goods, freight prepaid to the
                  Seller’s point of shipment; or
                </li>

                <li>
                  for an indirect or consequential loss or damage (whether for
                  loss of profit, revenue, goodwill or any economic loss
                  including physical damage or otherwise), costs or expenses
                  suffered by the Buyer, howsoever caused.
                </li>
              </ol>

              <li>
                Nothing in these conditions shall exclude or limit the Seller’s
                liability for death or personal injury caused by its negligence.
              </li>
            </ol>

            <h4>9. Force Majeure</h4>

            <ol>
              <li>
                Neither party shall be liable for any delay in performance or
                failure to perform any of its obligations under this agreement
                insofar as the delay or failure due to any to any event beyond
                the reasonable control of that party.
              </li>
            </ol>

            <h4>10. Samples</h4>

            <ol>
              <li>
                Any samples supplied to the Buyer are supplied solely for
                information and in no way import any express or implied
                conditions or warrant as to qualify, description, fitness for
                purpose or merchantable quality and the Buyer shall be deemed to
                have satisfied itself as to such matters prior to ordering the
                goods.
              </li>
            </ol>

            <h4>11. Orders and Specification</h4>

            <ol>
              <li>
                The Buyer shall give the Seller any necessary information
                relating to the goods within a sufficient time to enable the
                Seller to perform its obligations under these conditions.
              </li>

              <li>
                Unless expressly agreed in writing by the Seller all drawings,
                designs, specifications and particulars of weights and
                dimensions submitted by the Seller are approximate only and the
                Seller shall have no liability in respect of any deviation from
                them.
              </li>

              <li>
                The Buyer shall provide the seller with its VAT registration
                number(s), country of registration and any information which the
                Seller requires to enable it to comply with its obligations
                under any applicable tax regime.
              </li>

              <li>
                The Seller may make changes in the specification of the goods
                which are required to conform with applicable health and safety
                or other statutory requirements or such changes as the Seller
                considers, in its reasonable opinion, will not reduce the
                quality of the goods.
              </li>

              <li>
                The Seller’s specification of the goods does not deal with, nor
                does the Seller make any warranties (express or implied),
                regarding compliance of the goods with national or local law
                requirements.
              </li>
            </ol>

            <h4>12. Terminations</h4>

            <ol>
              <li>
                The Seller may terminate this or any other subsisting contract
                with the Buyer immediately by giving notice in writing to the
                Buyer if:
              </li>

              <ol>
                <li>
                  the Buyer has a receiver or administrative receiver appointed,
                  passes a resolution for winding up (other than for the purpose
                  of a bona fide scheme of solvent amalgamation or
                  reconstruction) or a court of competent jurisdiction makes an
                  order to that effect, becomes subject to an administration
                  order enters into any voluntary arrangements with it’s
                  creditors or ceases or threatens to cease to carry on a
                  business or if any national or local law equivalent of any of
                  the following occurs;
                </li>

                <li>
                  the Buyer fails to pay on its due date or within any agreed
                  period of grace, any sum due under this or any other contract;
                </li>

                <li>
                  the Buyer commits any material breach of any terms of this
                  contract (other than under clause 13.1.2) or any other
                  contract with the Seller, which in the case of a breach
                  capable of being remedied, is not within 14 days of a written
                  request to do so;
                </li>

                <li>the Buyer exceeds its credit limit with the Seller;</li>

                <li>
                  the Seller reasonably apprehends that any of the above is
                  likely to occur and notifies the Buyer in writing.
                </li>
              </ol>

              <li>
                If the contract is terminated under clause 13, and the goods
                have been delivered but not paid for, the price shall become
                immediately payable notwithstanding any previous agreement to
                the contrary.
              </li>

              <li>
                Termination of this contract shall not affect any of the Sellers
                accrued rights or liabilities or affect the coming into force or
                the continuance in force of any provision which is expressly or
                by implication intended to come into or continue in force on or
                after termination.
              </li>
            </ol>

            <h4>13. Assignment</h4>

            <ol>
              <li>
                None of the rights and obligations of the Buyer under these
                conditions may be assigned or transferred in whole or in part
                without the prior written consent of the Seller. 14.2 The
                Contracts (Rights of Third Parties) Act 1999 shall not apply to
                these conditions. A person not party to these conditions shall
                not have or acquire any rights to enforce any of the terms of
                this document. This provision shall not affect any right or
                remedy of any third party which exists or is available otherwise
                than by reason of the Contracts (Rights of Third Parties) Act
                1999.
              </li>
            </ol>

            <h4>14. Health and Safety</h4>

            <ol>
              <li>
                The Buyer agrees to pay due regard to any information supplied
                by the Seller and relating to the use for which the goods are
                designed or have been tested or concerning conditions necessary
                that they will be safe and without risk to health at all times
                and the Buyer undertakes to take such as may be specified by the
                above information to ensure that as far as reasonably
                practicable the goods will be safe and without risks to health
                at all times.
              </li>
            </ol>

            <h4>15. Lien</h4>

            <ol>
              <li>
                The Seller shall be entitled to a general lien on all goods and
                property owned by the Buyer in the Seller's possession (although
                the Buyer may have paid for the same in full) in satisfaction of
                the whole or part, as the case may be, of the unpaid price of
                any goods sold and delivered to the Buyer under any contract.
                The Seller shall be entitled to offset any sum or sums owing to
                it from the Buyer, against any sums owed to the Buyer from the
                Seller.
              </li>
            </ol>

            <h4>16. Headings</h4>

            <ol>
              <li>
                The headings of these conditions are for convenience only and do
                not affect the interpretation or construction of these
                conditions.
              </li>
            </ol>

            <h4>17. Severability</h4>

            <ol>
              <li>
                If any provision of this agreement is held by any competent
                authority to be invalid, illegal or unenforceable in whole or to
                part, such invalidity, illegality or unenforceability shall not
                affect the other provisions of this agreement, which shall
                remain in fill force and effect. The parties shall attempt to
                substitute for any invalid or unenforceable provision a valid or
                enforceable provision which achieves to the greatest extent
                possible the economic, legal and commercial objectives of the
                invalid or unenforceable provision.
              </li>
            </ol>

            <h4>18. Waiver</h4>

            <ol>
              <li>
                No failure or delay by either party to exercise any right or
                remedy under this agreement shall he construed or operate as a
                waiver of that right or remedy, nor shall any single or partial
                exercise of any right or remedy preclude the further exercise of
                that right or remedy. No waiver by either party of any breach of
                this agreement shall be considered as a waiver of a preceding or
                subsequent breach. The rights and remedies provided in this
                agreement are cumulative and are not exclusive of any rights or
                remedies provided by law.
              </li>
            </ol>

            <h4>19. Representation</h4>

            <ol>
              <li>
                The Seller's employees or agents are not authorised to make any
                representations concerning the goods, unless confirmed by the
                Seller in writing. In entering into the contract, the Buyer
                acknowledges that it does, not rely on and waives any claim for
                breach of any representation which are not so confirmed.
              </li>
            </ol>

            <h4>20. Notices</h4>

            <ol>
              <li>
                All notices which are required to be given under these
                conditions, shall be in writing and shall be delivered
                personally or sent by prepaid first class post or facsimile
                transmission to the party concerned at its last known address.
                Notice delivered personally shall be deemed to have been served
                when delivered, and notice sent by first class post shall be
                deemed to have been served 48 hours after dispatch and notice,
                sent by facsimile shall be deemed to have been served on the
                date of dispatch.
              </li>
            </ol>

            <h4>21. Governing law</h4>

            <ol>
              <li>
                Any contract to which these conditions apply shall be governed
                by and construed in accordance with the Laws of England. The
                parties submit to the exclusive jurisdiction of the English
                Courts.
              </li>
            </ol>

            <h4>22. Variation</h4>

            <ol>
              <li>
                No variation to these conditions shall be binding unless agreed,
                in writing, between the authorised representative of the Buyer
                and Seller.
              </li>
            </ol>

            <h4>23. Prescription goods</h4>

            <ol>
              <li>
                Prescription goods can be reserved by email or phone call prior
                to receipt of the prescription. Before prescription goods are
                dispensed, the original prescription (not a copy), signed by a
                registered prescriber must be received by the Seller. Goods
                supplied against a prescription must be for the personal use of
                the named patient only and are not for re-sale. Prescription
                goods will be dispatched to the address nominated by the
                patient. Prescribers and their associates/employees acting as
                agents for patients in terms of receiving, storing and paying
                for goods on the patient’s behalf must do so with the patient’s
                permission. The cost of the prescription item remains the
                liability of the named patient, who is the intended end user,
                and in the event of non payment, the Seller reserves the right
                to pursue payment from the patient agent and the patient, joint
                and severally, as Buyers. In the event that the Seller incurs
                any VAT liability as a result of a patient agent reselling or
                otherwise abusing the zero rated status of prescription items
                for named patients, the Seller will be entitled to recover all
                such VAT from the Buyer(s).
              </li>
            </ol>

            <h4>24. Wholesale Orders</h4>

            <p>
              The Seller can process wholesale orders which are regulated by the
              MHRA.
            </p>

            <ol>
              <li>
                Restrictions are placed on those whom are entitled to receive
                Wholesale orders.
              </li>

              <p>
                Prescription only medicines (POM’s), pharmacy medicines (P’s)
                and medicinal classed general sales items, may be ordered on a
                signed order by UK registered doctors and dentists.
              </p>

              <p>
                Prescription only medicines (POM’s), pharmacy medicines (P’s)
                and medicinal classed general sales items, may be ordered on a
                signed order by UK registered doctors and dentists.
              </p>

              <p>
                Prescribing Nurses based in England, Wales and Northern Ireland
                are not eligible to purchase stock on a signed order basis
                within their own capacity as they are not listed in Schedule 22
                or 17 of the Human Medicines Regulations 2012.
              </p>

              <p>
                Exceptions are allowed for any prescribing nurses based in
                Scotland carrying on the business of an independent clinic that
                is listed on the “Healthcare Improvement Scotland” portal are
                able to order and stock prescription only and pharmacy medicines
                in connection with the running of the clinic.
              </p>

              <p>
                A company or individual that holds a verifiable WDA (Wholesale
                distribution authority) license and GDP that has been issued by
                MHRA is authorised to order on a wholesale basis.
              </p>

              <li>
                The Seller will only accept wholesale orders submitted in the
                following form:
              </li>

              <p>
                a)A signed order/requisition from a doctor, dentist or pharmacy.
              </p>

              <p>b)A WDA (wholesale distribution authorisation) transaction</p>

              <li>
                The Buyer understand that verification checks must be performed
                on the company and/or the individual prior to acceptance of any
                wholesale order. These may include but are not limited to the
                following:
              </li>

              <p>
                a) Delivery address – should be registered with one of the
                following CQC (Care Quality Commission), GPHC (General
                Pharmaceutical council), HIS (Healthcare Improvement Scotland)
                or WDA (Wholesale Distribution Authority). If not, the Seller
                will be entitled to perform additional checks including a
                comprehensive risk assessment to ensure the goods are being
                delivered to a secure location.
              </p>

              <p>
                b) Personal checks - we must be able to confirm the registration
                number of the signee (i.e. GMC number) and the identity of the
                individual (i.e. ID card/utility bill)
              </p>

              <p>
                Other checks - we may carry out other due diligence checks if
                all of the above cannot be fulfilled, including VAT checks and
                signed declarations which may need to be submitted to confirm
                responsibility of storage and handling of the goods.
              </p>

              <p>
                Propharma - UK Limited (Registered No. 10155095) has its
                registered office at 25 Meadow Hill, New Malden, Surrey, KT3 5RQ
              </p>
            </ol>

            <p>Copyright © 2012-2018 ProPharma-UK Limited</p>

            <p>
              Copyright © ProPharma-UK 2018
              <br />
              ProPharma-UK Limited
              <br />
              unit 1 Windsor Park,
              <br />
              50 Windsor Avenue,
              <br />
              London, SW19 2TJ, UK
            </p>
          </div>
        </div>
      </div>
      <ContactUS />
      <Footer />
    </>
  );
};

export default TermsAndCondition;
