import React, { useState } from "react";

const BankDetails = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    bankname: "",
    completeformname: "",
    bankaccountno: "",
    banksortcode: "",
    banktelephoneno: "",
    bankfaxno: "",
  });


  // Function to handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <div id="step5" className="step-content-body out1">
        <div className="formdv container">
          <p className="formdetailstitle">
            <span>Bank details</span>
          </p>
          <div className="row">
            <div className="col-sm-6 form-group left_col">
              <label htmlFor="bankname" className="mat-label">
                Bank Name
              </label>
              <input
                type="text"
                className="form-control mat-input"
                id="bankname"
                name="bankname"
                placeholder=""
                value={formData.bankname}
                onChange={handleChange}
              />
            </div>
            <div
              className="col-sm-6 form-group right_col"
              id="regCompleteformName"
            >
              <label htmlFor="completeformname" className="mat-label">
                Account Name
              </label>
              <input
                type="text"
                className="form-control mat-input"
                id="completeformname"
                name="completeformname"
                placeholder=""
                value={formData.completeformname}
                onChange={handleChange}
              />
              <span
                id="CompleteformNameNoValidator"
                className="loaddata"
                style={{ display: "none" }}
              ></span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group left_col">
              <label htmlFor="bankaccountno" className="mat-label">
                Bank Account No
              </label>
              <input
                type="text"
                className="form-control mat-input"
                id="bankaccountno"
                name="bankaccountno"
                placeholder=""
                value={formData.bankaccountno}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6 form-group right_col">
              <label htmlFor="banksortcode" className="mat-label">
                Sort Code
              </label>
              <input
                type="text"
                className="form-control mat-input"
                id="banksortcode"
                name="banksortcode"
                placeholder=""
                value={formData.banksortcode}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group left_col">
              <label htmlFor="banktelephoneno" className="mat-label">
                IBAN
              </label>
              <input
                type="text"
                className="form-control mat-input"
                id="banktelephoneno"
                name="banktelephoneno"
                placeholder=""
                value={formData.banktelephoneno}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6 form-group right_col">
              <label htmlFor="bankfaxno" className="mat-label">
                SWIFT
              </label>
              <input
                type="text"
                className="form-control mat-input"
                id="bankfaxno"
                name="bankfaxno"
                placeholder=""
                value={formData.bankfaxno}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
