import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Navbar from "../../globleComponents/Navbar";
import PrivateClinicRegistrationForm from "./privateClinicRegistration/PrivateClinicRegistrationForm";
import Attachment from "./globleForms/Attachment";
import { toast } from "react-toastify";
import { api } from "../../config/api";
import Footer from "../../globleComponents/Footer";
import TeamForm from "./GPSurgeryRegistration/YourTeamForm";
import { useNavigate, useParams } from "react-router-dom";
import { token } from "../../config/globleData";

// Validation function
const validateFormData = (data) => {
  const errors = {};
  const requiredFields = [
    "clinicName",
    "deliveryAddress",
    "postcode",
    "ownedBy",
    "tradingAs",
    "websiteURL",
    "vatNo",
    "companyRegistrationNo",
    "CQCRegistrationName",
    "contactPerson",
    "contactNumber",
    "contactEmail",
    "partnerName",
    "directorName",
    "directorEmail",
    "accountsEmail",
  ];

  requiredFields.forEach((field) => {
    if (!data[field]) {
      errors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
    }
  });

  if (
    data.websiteURL &&
    !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(data.websiteURL)
  ) {
    errors.websiteURL = "Invalid URL format";
  }

  if (data.professionals) {
    data.professionals.forEach((professional, index) => {
      if (!professional.name) {
        errors[`professionals[${index}].name`] = "Name is required";
      }
      if (!professional.authority) {
        errors[`professionals[${index}].authority`] = "Authority is required";
      }
      if (!professional.registrationNumber) {
        errors[`professionals[${index}].registrationNumber`] =
          "Registration Number is required";
      }
    });
  }

  return errors;
};

const PrivateClinicRegistration = () => {
  const [formData, setFormData] = useState({
    clinicName: "",
    deliveryAddress: "",
    postcode: "",
    ownedBy: "",
    tradingAs: "",
    websiteURL: "",
    vatNo: "",
    companyRegistrationNo: "",
    CQCRegistrationName: "",
    contactPerson: "",
    contactNumber: "",
    contactEmail: "",
    partnerName: "",
    directorName: "",
    directorEmail: "",
    professionals: [{ name: "", authority: "", registrationNumber: "" }],
    accountsEmail: "",
    accountsContactPerson: "",
    formCompletedBy: "",
    completionDate: "",
    files: {
      WDACertificate: null,
      GDPCertificate: null,
    },
    type: "Private Clinics",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const attachmentRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${api.privateClinicsList}/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // Assuming response.data contains the file URLs
          const fetchedData = response.data;
          // Set fetched data to formData, including file URLs
          setFormData({
            ...fetchedData,
            files: {
              WDACertificate: fetchedData.files?.WDACertificate || null,
              GDPCertificate: fetchedData.files?.GDPCertificate || null,
            }
          });
        } catch (error) {
          toast.error("Failed to fetch data");
        }
      };

      fetchData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleFileChange = (fileType, file) => {
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        [fileType]: file,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors and touched state
    setErrors({});
    setTouched({});

    // Perform validation
    const validationErrors = validateFormData(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setTouched({
        ...Object.keys(validationErrors).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {}),
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Create a new FormData object
      const formDataappend = new FormData();

      // Append regular fields
      Object.entries(formData).forEach(([key, value]) => {
        if (key === "files") {
          // Handle files separately
          const files = value || {}; // Ensure value is not undefined
          Object.entries(files).forEach(([fileKey, fileValue]) => {
            if (fileValue) {
              formDataappend.append(fileKey, fileValue);
            }
          });
        } else if (Array.isArray(value)) {
          // Handle arrays (e.g., professionals)
          value.forEach((item, index) => {
            Object.entries(item).forEach(([field, fieldValue]) => {
              formDataappend.append(
                `professionals[${index}][${field}]`,
                fieldValue
              );
            });
          });
        } else {
          // Append single values
          formDataappend.append(key, value);
        }
      });

      // Debug: Log FormData entries to verify
      // for (let pair of formDataappend.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      // Determine the URL and method based on whether it's an edit or create
      const url = id
        ? `${api.privateClinicsList}/${id}` // URL for edit
        : api.createPrivateClinicsRegistrations; // URL for create
      const method = id ? 'put' : 'post'; // Method for edit or create

      // Make the request
      const response = await axios[method](url, formDataappend, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      // Handle success
      toast.success(response.data.message);

      // Optionally reset the form data
      setFormData({
        clinicName: "",
        deliveryAddress: "",
        postcode: "",
        ownedBy: "",
        tradingAs: "",
        websiteURL: "",
        vatNo: "",
        companyRegistrationNo: "",
        CQCRegistrationName: "",
        contactPerson: "",
        contactNumber: "",
        contactEmail: "",
        partnerName: "",
        directorName: "",
        directorEmail: "",
        professionals: [{ name: "", authority: "", registrationNumber: "" }],
        accountsEmail: "",
        accountsContactPerson: "",
        formCompletedBy: "",
        completionDate: "",
        files: {
          WDACertificate: null,
          GDPCertificate: null,
        },
        type: "Private Clinics",
      });

      // Clear file inputs if there's a reference
      if (attachmentRef.current) {
        attachmentRef.current.clearFileInputs();
      }

      // Navigate to the appropriate page
      navigate(`/private-clinics-register-details/${response.data.privateClinicRegistration._id}`);
    } catch (error) {
      toast.error(
        "Error submitting form: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="background-container">
        <div className="form-container">
          <PrivateClinicRegistrationForm
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            touched={touched}
            handleInputChange={handleChange}
          />
          <TeamForm
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            setFormData={setFormData}
            touched={touched}
            setTouched={setTouched}
          />
          <Attachment
            formData={formData}
            setFormData={setFormData}
            handleFileChange={handleFileChange}
            ref={attachmentRef}
          />
          <div className="step-content-foot">
            <button
              type="button" // Use type "button" to prevent default form submission
              className="active out1"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Finish"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivateClinicRegistration;
