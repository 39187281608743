import React, { useState } from "react";

const AdditionalForm = ({
  formData,
  setFormData,
  errors,
  touched,
  handleChange,
}) => {
  const [ownershipChanged, setOwnershipChanged] = useState(
    formData.recentChangesToOwnership === "Yes"
  );

  const handleOwnershipChange = (e) => {
    const { value } = e.target;
    setOwnershipChanged(value === "Yes");
    setFormData({
      ...formData,
      recentChangesToOwnership: value,
      detailsOfChanges: value === "Yes" ? formData.detailsOfChanges : "",
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      addressSameAsCollection: e.target.checked,
    });
  };

  return (
    <div>
      <form
        method="post"
        action="https://proph.co.uk/registration_form"
        id="additional-form"
        encType="multipart/form-data"
        name="additionalDetails"
      >
        <p className="formlead">
          <span className="firsttitle"></span>&nbsp;Additional Information
        </p>
        <div id="additional-step" className="step-content-body out1">
          <div className="formdv container">
            <p className="formdetailstitle">
              <span>Additional Details</span>
            </p>

            <div className="row">
              <div
                className="col-xs-12 col-sm-6 form-group left_col"
                id="websiteURL"
              >
                <label htmlFor="websiteURL" className="mat-label">
                  Website URL:
                </label>
                <input
                  id="websiteURL"
                  name="websiteURL"
                  value={formData.websiteURL || ""}
                  type="url"
                  placeholder=""
                  onChange={handleChange}
                  className={`form-control mat-input ${
                    touched.websiteURL && errors.websiteURL ? "is-invalid" : ""
                  }`}
                />
                {touched.websiteURL && errors.websiteURL && (
                  <span className="loaddata">{errors.websiteURL}</span>
                )}
              </div>

              <div
                className="col-xs-12 col-sm-6 form-group right_col"
                id="directorFullName"
              >
                <label htmlFor="directorFullName" className="mat-label">
                  Full Name of Director:
                </label>
                <input
                  id="directorFullName"
                  name="directorFullName"
                  value={formData.directorFullName || ""}
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                  className={`form-control mat-input ${
                    touched.directorFullName && errors.directorFullName
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {touched.directorFullName && errors.directorFullName && (
                  <span className="loaddata">{errors.directorFullName}</span>
                )}
              </div>
            </div>

            <div className="row">
              <div
                className="col-xs-12 col-sm-6 form-group"
                id="recentChangesToOwnership"
              >
                <label className="mat-label">
                  Any Recent Changes to Ownership:
                </label>
                <br />
                <div className="row text-left">
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="recentChangesToOwnership"
                        value="Yes"
                        checked={
                          formData.recentChangesToOwnership === "Yes"
                            ? true
                            : false
                        }
                        onChange={handleOwnershipChange}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-square"></i>
                      </span>
                      Yes
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="recentChangesToOwnership"
                        value="No"
                        checked={
                          formData.recentChangesToOwnership === "No"
                            ? true
                            : false
                        }
                        onChange={handleOwnershipChange}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-square"></i>
                      </span>
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {formData.recentChangesToOwnership === "Yes" && (
              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 form-group left_col"
                  id="detailsOfChanges"
                >
                  <label htmlFor="detailsOfChanges" className="mat-label">
                    Details (if Yes is selected):
                  </label>
                  <textarea
                    id="detailsOfChanges"
                    name="detailsOfChanges"
                    value={formData.detailsOfChanges || ""}
                    placeholder=""
                    onChange={handleChange}
                    className={`form-control mat-input ${
                      touched.detailsOfChanges && errors.detailsOfChanges
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {touched.detailsOfChanges && errors.detailsOfChanges && (
                    <span className="loaddata">{errors.detailsOfChanges}</span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdditionalForm;
