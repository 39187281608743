import axios from "axios";
import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../config/api";

const ContactUS = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNo: "",
    companyName: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  // Validate form data
  const validate = useCallback(() => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    return newErrors;
  }, [formData]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        await axios.post(
          `${baseURL}/api/contact-us`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Form submitted successfully!");
        setFormData({
          name: "",
          email: "",
          contactNo: "",
          companyName: "",
          message: "",
        });
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Failed to submit form. Please try again later.");
        setErrors({ form: "Failed to submit form. Please try again later." });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container contact">
      <div className="row" style={{ marginBottom: "50px" }} id="contact-us">
        <div className="col-md-6 ctnform">
          <form id="contact-form" onSubmit={handleSubmit}>
            <input
              type="hidden"
              className="form-control"
              name="_token"
              value="2qDycU3T9ApLxAyo1fyeK593DfqysmgAbIoJ3ta1"
            />

            <input
              type="text"
              placeholder="Name*"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className="error-text">{errors.name}</span>}

            <input
              type="email"
              placeholder="Email*"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error-text">{errors.email}</span>}

            <input
              type="text"
              placeholder="Contact No*"
              name="contactNo"
              value={formData.contactNo}
              onChange={handleChange}
              required
            />

            <input
              type="text"
              placeholder="Company Name*"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />

            <textarea
              placeholder="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>

            <button type="submit" className="btn btn-success submitbtn" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>

        <div className="col-md-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2488.546376244378!2d-0.1874142848038884!3d51.41138922554048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876061fa33a0427%3A0x7b8021f02faf5cdc!2s1%2C%2050%20Windsor%20Ave%2C%20London%20SW19%202TJ%2C%20UK!5e0!3m2!1sen!2sin!4v1579520130563!5m2!1sen!2sin"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContactUS);
