import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ThankYou = () => {
  return (
    <>
      <Navbar />
      <div class="container">
        <div class="row thankyoudv">
          <center>
            <img
              src="https://proph.co.uk/public/front/assets/images/flower.png"
              class="img-responsive"
            />
          </center>
          <center>
            <h4>THANK YOU!</h4>
          </center>
          <center>
            <p>
              Thanks for registering! We will be in contact with you within 24
              hours to approve your request for registration...
            </p>
          </center>
        </div>
        <div class="progressbardv">
          <img
            src="https://proph.co.uk/public/front/assets/images/progress-img.png"
            class="img-responsive"
            alt="progressbar"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ThankYou;
