import React, { useState } from "react";

const SopsGDPTraining = ({ formData, handleChange }) => {
  return (
    <div>
      <div
        className="sops-details-section tabs-edits-section"
        style={{ display: "block" }}
      >
        <h3 className="form-main-title">Sops / GDP / Training</h3>
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <div className="sops-title-group">
              <span>Yes</span>
              <span>No</span>
              <span>N/A</span>
            </div>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              1. Do you work in accordance to an approved set of standard
              operating procedures?
              <span className="reuired-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL1"
                  className="radio-check"
                  id="sop_yes_1"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="sop_yes_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL1"
                  className="radio-check"
                  id="sop_no_1"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="sop_no_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL1"
                  className="radio-check"
                  id="sop_na_1"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="sop_na_1"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              2. Do you have a documented quality system?
              <span className="reuired-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL2"
                  className="radio-check"
                  id="sop_yes_2"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="sop_yes_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL2"
                  className="radio-check"
                  id="sop_no_2"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="sop_no_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL2"
                  className="radio-check"
                  id="sop_na_2"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="sop_na_2"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              3. Do you have a tested product recall procedure? And will you
              inform Propharma-UK of any product recall of batches that you
              become aware of?
              <span className="reuired-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL3"
                  className="radio-check"
                  id="sop_yes_3"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="sop_yes_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL3"
                  className="radio-check"
                  id="sop_no_3"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="sop_no_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL3"
                  className="radio-check"
                  id="sop_na_3"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="sop_na_3"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              4. Do you have a complaints procedure?
              <span className="reuired-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL4"
                  className="radio-check"
                  id="sop_yes_4"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="sop_yes_4"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL4"
                  className="radio-check"
                  id="sop_no_4"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="sop_no_4"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL4"
                  className="radio-check"
                  id="sop_na_4"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="sop_na_4"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              5. Do you maintain a bone fide supplier list?
              <span className="reuired-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL5"
                  className="radio-check"
                  id="sop_yes_5"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="sop_yes_5"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL5"
                  className="radio-check"
                  id="sop_no_5"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="sop_no_5"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL5"
                  className="radio-check"
                  id="sop_na_5"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="sop_na_5"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              6. Do you provide GDP training to appropriate staff?
              <span className="reuired-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL6"
                  className="radio-check"
                  id="sop_yes_6"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="sop_yes_6"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL6"
                  className="radio-check"
                  id="sop_no_6"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="sop_no_6"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL6"
                  className="radio-check"
                  id="sop_na_6"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="sop_na_6"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group mb-0">
          <div className="col-md-10">
            <label className="form-label-title">
              7. Do you record staff training?
              <span className="reuired-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL7"
                  className="radio-check"
                  id="sop_yes_7"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="sop_yes_7"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL7"
                  className="radio-check"
                  id="sop_no_7"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="sop_no_7"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL7"
                  className="radio-check"
                  id="sop_na_7"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="sop_na_7"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SopsGDPTraining;
