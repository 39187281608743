import React from "react";
import Navbar from "../globleComponents/Navbar";
import ContactUS from "../globleComponents/ContactUS";
import Footer from "../globleComponents/Footer";
import { registerLinks } from "../config/globleData";
import { RegisterBox } from "../globleComponents/RegisterBox";

const Compliance = () => {
  return (
    <div class="overflow-x-h">
      <Navbar />
      <div className="register-page-link">
        <div className="container">
          <p className="i-want-to-register">I Want to register as a</p>
          <div className="register-link-group">
            {registerLinks.map((link, index) => (
              <RegisterBox
                key={index}
                to={link.to}
                imgSrc={link.imgSrc}
                altText={link.altText}
                text={link.text}
              />
            ))}
          </div>
        </div>
      </div>
      <ContactUS />
      <Footer />
    </div>
  );
};

export default Compliance;
