import "./App.css";
import React from "react";
import "./components/config/style";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Button, Result } from "antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Pages
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import ServicesPage from "./components/pages/ServicesPage";
import News from "./components/pages/News";
import ContactPage from "./components/pages/ContactPage";
import Compliance from "./components/pages/Compliance";
import TermsAndCondition from "./components/pages/TermsAndCondition";
import CookieAndWebsitePolicy from "./components/pages/CookieAndWebsitePolicy";
import DataProtectionNotice from "./components/pages/DataProtectionNotice";
import GPSurgeryRegistration from "./components/pages/ComplianceForms/GPSurgeryRegistration";
import Supplier from "./components/pages/ComplianceForms/Supplier";
import PrivateClinicRegistration from "./components/pages/ComplianceForms/PrivateClinicRegistration";
import WholesaleValidationQuestionnaire from "./components/pages/ComplianceForms/wholesaleValidation/WholesaleValidationQuestionnaire";
// Admin components
import LoginForm from "./components/admin/loginComponents/LoginForm";
import ForgotPassword from "./components/admin/loginComponents/ForgotPassword";
import Dashboard from "./components/admin/loginComponents/authComponents/Dashboard";
import RegisterList from "./components/admin/loginComponents/authComponents/registerListPages/RegisterList";
import PrivateClinicsList from "./components/admin/loginComponents/authComponents/registerListPages/PrivateClinicsList";
import ViewRegister from "./components/admin/loginComponents/authComponents/registerDetailsPages/ViewRegister";
import ViewPrivateClinicRegister from "./components/admin/loginComponents/authComponents/registerDetailsPages/ViewPrivateClinicRegister";
// Utility components
import ProtectedRoute from "./components/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import PharmacyRegistration from "./components/pages/ComplianceForms/pharmacyRegistration/PharmacyRegistration";
import PharmacyRegistrationList from "./components/admin/loginComponents/authComponents/registerListPages/PharmacyRegistrationList";
import ViewPharmacyRegister from "./components/admin/loginComponents/authComponents/registerDetailsPages/ViewPharmacyRegister";
import CustomerList from "./components/admin/loginComponents/authComponents/registerListPages/CustomerList";
import ViewCustomerRegister from "./components/admin/loginComponents/authComponents/registerDetailsPages/ViewCustomerRegister";
import ViewEditPageGPSurgery from "./components/pages/ComplianceForms/GPSurgeryRegistration/ViewEditPageGPSurgery";
import ThankYou from "./components/globleComponents/ThankYou";
import ViewEditPagePrivateClinic from "./components/pages/ComplianceForms/privateClinicRegistration/ViewEditPagePrivateClinic";
import ViewEditPagePharmacy from "./components/pages/ComplianceForms/pharmacyRegistration/ViewEditPagePharmacy";
import CustomerRegistration from "./components/pages/ComplianceForms/CustomerRegistration/CustomerRegistration";
import ViewEditPageCustomer from "./components/pages/ComplianceForms/CustomerRegistration/ViewEditPageCustomer";

const App = () => {
  const token = localStorage.getItem("token");
  const publicRoutes = [
    { path: "/", element: <Home /> },
    { path: "/about", element: <About /> },
    { path: "/services", element: <ServicesPage /> },
    { path: "/news", element: <News /> },
    { path: "/contact", element: <ContactPage /> },
    { path: "/terms-and-condition", element: <TermsAndCondition /> },
    { path: "/cookie-and-website-policy", element: <CookieAndWebsitePolicy /> },
    { path: "/data-protection-notice", element: <DataProtectionNotice /> },
    { path: "/wholesaler-register-form", element: <CustomerRegistration /> },
    { path: "/wholesaler-supplier-register-form", element: <Supplier /> },
    {
      path: "/wholesaler-customer-supplier-register-form",
      element: <Supplier />,
    },
    {
      path: "/wholesale-validation-questionnaire",
      element: <WholesaleValidationQuestionnaire />,
    },

    // GP-surgery-registration
    {
      path: "/GP-surgery-registration/:id",
      element: <GPSurgeryRegistration />,
    },
    { path: "/GP-surgery-registration", element: <GPSurgeryRegistration /> },
    {
      path: "/GP-Surgery-register-details/:id",
      element: <ViewEditPageGPSurgery />,
    },

    // private-clinics
    { path: "/private-clinics", element: <PrivateClinicRegistration /> },
    { path: "/private-clinics/:id", element: <PrivateClinicRegistration /> },
    {
      path: "/private-clinics-register-details/:id",
      element: <ViewEditPagePrivateClinic />,
    },

    // pharmacy
    { path: "/pharmacy-registration", element: <PharmacyRegistration /> },
    { path: "/pharmacy-registration/:id", element: <PharmacyRegistration /> },
    {
      path: "/pharmacy-register-details/:id",
      element: <ViewEditPagePharmacy />,
    },

    // customer

    { path: "/wholesaler-register-form", element: <CustomerRegistration /> },
    {
      path: "/wholesaler-register-form/:id",
      element: <CustomerRegistration />,
    },
    {
      path: "/wholesaler-register-details/:id",
      element: <ViewEditPageCustomer />,
    },

    { path: "/compliance", element: <Compliance /> },
    { path: "/thank-you", element: <ThankYou /> },
  ];

  const adminRoutes = [
    { path: "/admin", element: <LoginForm /> },
    { path: "/admin/forgot_password", element: <ForgotPassword /> },
    { path: "/admin/dashboard", element: <Dashboard />, protected: true },

    // gp-surgery admin routes
    {
      path: "/admin/gp-surgery-list",
      element: <RegisterList />,
      protected: true,
    },
    {
      path: "/admin/gp-surgery-list/:id",
      element: <ViewRegister />,
      protected: true,
    },

    // private-clinics admin routes
    {
      path: "/admin/private-clinics-list",
      element: <PrivateClinicsList />,
      protected: true,
    },
    {
      path: "/admin/private-clinics/:id",
      element: <ViewPrivateClinicRegister />,
      protected: true,
    },

    // pharmacy admin routes
    {
      path: "/admin/pharmacy-list",
      element: <PharmacyRegistrationList />,
      protected: true,
    },
    {
      path: "/admin/pharmacy-list/:id",
      element: <ViewPharmacyRegister />,
      protected: true,
    },

    // customer admin routes
    {
      path: "/admin/customer-list",
      element: <CustomerList />,
      protected: true,
    },
    {
      path: "/admin/customer/:id",
      element: <ViewCustomerRegister />,
      protected: true,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          {publicRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          {adminRoutes.map(({ path, element, protected: isProtected }) => (
            <Route
              key={path}
              path={path}
              element={
                isProtected ? (
                  <ProtectedRoute>{element}</ProtectedRoute>
                ) : (
                  element
                )
              }
            />
          ))}
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link to={token ? "/admin/gp-surgery-list" : "/"}>
                    <Button type="primary">Back Home</Button>
                  </Link>
                }
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
