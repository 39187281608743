import React from "react";

const RegulatoryContactDetails = ({ formData, handleChange }) => {
  return (
    <div
      className="regulatory-contact-details-section tabs-edits-section"
      style={{ display: "block" }}
    >
      <h3 className="form-main-title">
        Regulatory Contact Details and WDA Checks
      </h3>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              Name of Responsible Person (RP)
              <span className="reuired-sign">*</span>
            </label>
            <input
              type="text"
              name="responsiblePersonName"
              value={formData.responsiblePersonName}
              onChange={handleChange}
              className="form-control validation"
            />
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              Responsible Person Email<span className="reuired-sign">*</span>
            </label>
            <input
              type="email"
              name="responsiblePersonEmail"
              value={formData.responsiblePersonEmail}
              onChange={handleChange}
              className="form-control validation email"
            />
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              Responsible Person Tel No.<span className="reuired-sign">*</span>
            </label>
            <input
              type="text"
              name="responsiblePersonTel"
              value={formData.responsiblePersonTel}
              onChange={handleChange}
              className="form-control validation tel_number"
              onKeyPress={(e) => {
                // Allow only numbers
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                  e.preventDefault();
                }
              }}
            />
            <span className="error_message"></span>
          </div>
        </div>
      </div>
      <span className="border-saprate-row"></span>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              WDA No.<span className="reuired-sign">*</span>
            </label>
            <input
              type="text"
              name="wda_number"
              value={formData.wda_number}
              onChange={handleChange}
              className="form-control validation"
            />
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              Date Granted<span className="reuired-sign">*</span>
            </label>
            <input
              type="text"
              name="wda_date_granted"
              value={formData.wda_date_granted}
              onChange={handleChange}
              className="form-control validation from_date validate-date hasDatepicker"
              autoComplete="off"
              id="dp1721127891045"
            />
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">Expiry Date</label>
            <input
              type="text"
              name="wda_expiry_date"
              value={formData.wda_expiry_date}
              onChange={handleChange}
              className="form-control to_date validate-date hasDatepicker"
              autoComplete="off"
              id="dp1721127891046"
            />
            <span className="error_message"></span>
          </div>
        </div>
      </div>
      <span className="border-saprate-row"></span>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              GDP No.<span className="reuired-sign">*</span>
            </label>
            <input
              type="text"
              name="gdp_number"
              value={formData.gdp_number}
              onChange={handleChange}
              className="form-control validation"
            />
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              Date Granted<span className="reuired-sign">*</span>
            </label>
            <input
              type="text"
              name="gdp_date_granted"
              value={formData.gdp_date_granted}
              onChange={handleChange}
              className="form-control validation start_date validate-date hasDatepicker"
              autoComplete="off"
              id="dp1721127891047"
            />
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">Expiry Date</label>
            <input
              type="text"
              name="gdp_expiry_date"
              value={formData.gdp_expiry_date}
              onChange={handleChange}
              className="form-control end_date validate-date hasDatepicker"
              autoComplete="off"
              id="dp1721127891048"
            />
            <span className="error_message"></span>
          </div>
        </div>
      </div>
      <span className="border-saprate-row"></span>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              WDA Attached<span className="reuired-sign">*</span>
            </label>
            <div className="radio-main-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="wdaAttachedOption"
                  className="radio-check wda_attach"
                  id="wda_yes"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="wda_yes">Yes</label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="wdaAttachedOption"
                  className="radio-check wda_attach"
                  id="wda_no"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="wda_no">No</label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">
              GDP Attached<span className="reuired-sign">*</span>
            </label>
            <div className="radio-main-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="gdpCertOption"
                  className="radio-check gdp_attach"
                  id="gdpCert_yes"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="gdpCert_yes">Yes</label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="gdpCertOption"
                  className="radio-check gdp_attach"
                  id="gdpCert_no"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="gdpCert_no">No</label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label-title">CD License</label>
            <div className="radio-main-groups">
              <div className="radio-group">
                <input
                  type="radio"
                  name="cdAttachedOption"
                  className="radio-check cd_attach"
                  id="cd_yes"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="cd_yes">Yes</label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="cdAttachedOption"
                  className="radio-check cd_attach"
                  id="cd_no"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="cd_no">No</label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group long">
            <label className="form-label-title">
              Will you or Propharma-UK be collecting goods
              <span className="reuired-sign">*</span>{" "}
              <span className="which-type-test">i</span>
              <div className="test-tooltip-section">
                <p>If ‘Yes’ please fill in the Technical Agreement</p>
              </div>
            </label>
            <span className="text-techinical-agreement">
              <a
                className="text-techinical"
                href="https://proph.co.uk/s2.4.4Propharma_Technical_Agreement_WCS.docx"
              >
                Click here to download technical agreement.
              </a>
            </span>
            <div className="radio-main-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="propharma_UKOption"
                  className="radio-check aggrement_attach"
                  id="collecting_yes"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="collecting_yes">Yes</label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="propharma_UKOption"
                  className="radio-check aggrement_attach"
                  id="collecting_no"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="collecting_no">No</label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
      </div>
      <span className="border-saprate-row"></span>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="form-label-title">
              When were you last inspected by the MHRA?
              <span className="reuired-sign">*</span>
            </label>
            <input
              type="date"
              name="MHRA"
              value={formData.MHRA}
              onChange={handleChange}
              className="form-control validation datepicker_set validate-date dateicon hasDatepicker"
              autoComplete="off"
              id="dp1721127891043"
            />
            <span className="error_message"></span>
          </div>
          <div className="form-group mb-0">
            <label className="form-label-title">
              List any third party Quality accreditations (eg. ISO)
              <span className="reuired-sign">*</span>
            </label>
            <textarea
              name="ISO"
              value={formData.ISO}
              onChange={handleChange}
              className="form-control validation"
              rows="3"
            ></textarea>
            <span className="error_message"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegulatoryContactDetails;
