import React from "react";
import Navbar from "../globleComponents/Navbar";
import ContactUS from "../globleComponents/ContactUS";
import Footer from "../globleComponents/Footer";
import Services from "../globleComponents/Services";
import CarouselSlider from "../globleComponents/CarouselSlider";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Services />
      <CarouselSlider />
      <ContactUS/>
      <Footer/>
    </>
  );
};

export default ContactPage;
