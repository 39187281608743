import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { api, baseURL } from "../../../../config/api";
import { getRegionDisplayName, token } from "../../../../config/globleData";
import { Button } from "antd";
import {
  contentWrapperStyle,
  dashboardWrapperStyle,
  sidebarStyle,
} from "../../../../config/style";

const ViewCustomerRegister = () => {
  const { id } = useParams();
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [certificates, setCertificates] = useState({
    WDACertificate: null,
    GDPCertificate: null,
  });
  
  const navigate = useNavigate();
  const getFileByType = async (id, type) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/registrations/registration/${id}/file/${type}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching file:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch customer data
        const response = await axios.get(
          `${api.customerRegistrationsList}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCustomerData(response.data);

        // Fetch certificates if available
        const [wdaResponse, gdpResponse] = await Promise.all([
          getFileByType(id, "WDACertificate"),
          getFileByType(id, "GDPCertificate"),
        ]);

        setCertificates({
          WDACertificate: URL.createObjectURL(wdaResponse.data),
          GDPCertificate: URL.createObjectURL(gdpResponse.data),
        });
      } catch (error) {
        setError("Failed to fetch customer data or certificates");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div style={dashboardWrapperStyle}>
      <Sidebar style={sidebarStyle} />
      <div style={contentWrapperStyle}>
        <main role="main" className="col-md-9 ms-sm-auto col-lg-12 px-4">
          <div className="heading" style={{ margin: "0px 0px 50px -29px" }}>
            View Customer Register Details
            <span
              className="heading_btn"
              style={{
                marginLeft: "10px",
                display: "inline-flex",
              }}
            >
              <Button onClick={() => navigate(-1)}>Back to List</Button>
            </span>
          </div>

          <div className="card mb-4 border-success details-card">
            <div className="card-header bg-success text-white">
              <h2 className="card-title mb-0">
                <u>Customer Register Details</u>
              </h2>
            </div>
            <div className="card-body">
              {/* Customer Details */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Customer Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Registered Company Name:</strong>
                        </td>
                        <td>{customerData?.RegisteredCompanyName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Trading Name:</strong>
                        </td>
                        <td>{customerData?.Tradingname}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Address 1:</strong>
                        </td>
                        <td>{customerData?.Address1}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Address 2:</strong>
                        </td>
                        <td>{customerData?.Address2}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>City:</strong>
                        </td>
                        <td>{customerData?.City}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Country/Region:</strong>
                        </td>
                        <td>{getRegionDisplayName(customerData?.CountryRegion)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Postcode:</strong>
                        </td>
                        <td>{customerData?.Postcode}</td>
                      </tr>
                      {customerData?.DeliveryAddress && (
                        <>
                          <tr>
                            <td>
                              <strong>Delivery Address:</strong>
                            </td>
                            <td>{customerData?.DeliveryAddress}</td>
                          </tr>
                          {customerData?.DeliveryAddress2 && (
                            <tr>
                              <td>
                                <strong>Delivery Address 2:</strong>
                              </td>
                              <td>{customerData?.DeliveryAddress2}</td>
                            </tr>
                          )}
                          {customerData?.DeliveryCity && (
                            <tr>
                              <td>
                                <strong>Delivery City:</strong>
                              </td>
                              <td>{customerData?.DeliveryCity}</td>
                            </tr>
                          )}
                          {customerData?.DeliveryPostcode && (
                            <tr>
                              <td>
                                <strong>Delivery Postcode:</strong>
                              </td>
                              <td>{customerData?.DeliveryPostcode}</td>
                            </tr>
                          )}
                        </>
                      )}
                      {customerData?.CollectionAddress && (
                        <>
                          <tr>
                            <td>
                              <strong>Collection Address:</strong>
                            </td>
                            <td>{customerData?.CollectionAddress}</td>
                          </tr>
                          {customerData?.CollectionAddress2 && (
                            <tr>
                              <td>
                                <strong>Collection Address 2:</strong>
                              </td>
                              <td>{customerData?.CollectionAddress2}</td>
                            </tr>
                          )}
                          {customerData?.CollectionCity && (
                            <tr>
                              <td>
                                <strong>Collection City:</strong>
                              </td>
                              <td>{customerData?.CollectionCity}</td>
                            </tr>
                          )}
                          {customerData?.CollectionPostcode && (
                            <tr>
                              <td>
                                <strong>Collection Postcode:</strong>
                              </td>
                              <td>{customerData?.CollectionPostcode}</td>
                            </tr>
                          )}
                        </>
                      )}
                      <tr>
                        <td>
                          <strong>VAT No:</strong>
                        </td>
                        <td>{customerData?.vatNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Registration No:</strong>
                        </td>
                        <td>{customerData?.companyRegistrationNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Business Account Contact Department:</strong>
                        </td>
                        <td>{customerData?.businessAccountContactDept}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Signature:</strong>
                        </td>
                        <td>{customerData?.signature}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Signature Email:</strong>
                        </td>
                        <td>{customerData?.signatureEmailAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Directors & Shareholders */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Directors & Shareholders</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Directors:</strong>
                        </td>
                        <td>{customerData?.directors.join(", ")}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Shareholders:</strong>
                        </td>
                        <td>{customerData?.shareholders.join(", ")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Attachments Section */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Certificates</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>WDA Certificate:</strong>
                        </td>
                        <td>
                          {customerData?.files?.WDACertificate ? (
                            <a
                              href={customerData.files.WDACertificate}
                              download="WDA_Certificate.webp"
                              target="_blank"
                            >
                              View WDA Certificate
                            </a>
                          ) : (
                            "No WDA Certificate available"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>GDP Certificate:</strong>
                        </td>
                        <td>
                          {customerData?.files?.WDACertificate ? (
                            <a
                              href={customerData?.files?.GDPCertificate}
                              download="GDP_Certificate.webp"
                              target="_blank"
                            >
                              View GDP Certificate
                            </a>
                          ) : (
                            "No GDP Certificate available"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ViewCustomerRegister;
