import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Heading from "../../../globleComponents/Heading";
import { token } from "../../../config/globleData";
import { api } from "../../../config/api";

const Dashboard = () => {
  const [gpRegistrations, setGpRegistrations] = useState(0);
  const [privateClinics, setPrivateClinics] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [gpResponse, privateResponse] = await Promise.all([
          axios.get(api.GPregistrationsList, {
            headers: { Authorization: `Bearer ${token}` } // Add the Authorization header
          }),
          axios.get(api.privateClinicsList, {
            headers: { Authorization: `Bearer ${token}` } // Add the Authorization header
          }),
        ]);
        setGpRegistrations(gpResponse.data.totalRegistrations); // Adjust based on API response structure
        setPrivateClinics(privateResponse.data.totalRegistrations); // Adjust based on API response structure
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts


  return (
    <div className="dashboard-wrapper" style={{ height: "100vh", display: 'flex' }}>
      <Sidebar />
      <div className="content-wrapper" style={{ flex: 1, overflowY: 'auto' }}>
        <Heading heading="Dashboard"/>
        <div className="order-sec">
          <div className="custom-col">
            <div className="custom-card">
              <div className="custom-media">
                <div className="custom-media-left custom-media-middle1">
                  <span>
                    <i className="fa fa-shopping-cart custom-icon"></i>
                  </span>
                </div>
                <div className="custom-media-body custom-media-middle">
                  <h2>{gpRegistrations}</h2>
                  <p className="m-b-0">GP Surgery Registrations</p>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-col">
            <div className="custom-card">
              <div className="custom-media">
                <div className="custom-media-left custom-media-middle">
                  <span>
                    <i className="fa fa-user custom-icon"></i>
                  </span>
                </div>
                <div className="custom-media-body">
                  <h2>{privateClinics}</h2>
                  <p className="m-b-0">Private Clinics Registrations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  );
};

export default Dashboard;
