import React, { useState } from "react";
import { countries } from "../../../config/globleData";

const RegistrationForm = (props) => {
  const { formData, title, errors, touched ,handleChange} = props;
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const [showCollectionAddress, setShowCollectionAddress] = useState(false);

  const handleCheckboxChange = () => {
    setShowAddressDetails(!showAddressDetails);
  };

  const handleCollectionCheckboxChange = () => {
    setShowCollectionAddress(!showCollectionAddress);
  };

  // Function to render error messages
  const renderError = (fieldName) =>
    errors[fieldName] && touched[fieldName] ? (
      <span className="loaddata">{errors[fieldName]}</span>
    ) : null;

  return (
    <div>
      <form
        method="post"
        action="https://proph.co.uk/registration_form"
        id="form1"
        encType="multipart/form-data"
        name="details"
      >
        <p className="formlead">
          <span className="firsttitle"></span>&nbsp;Register as{" "}
          <span className="formtitle"></span>
        </p>
        <div id="step3" className="step-content-body out1">
          <div className="formdv container">
            <p className="formdetailstitle">
              <span>{title ? title : "Registration"}</span>
            </p>

            <div className="row">
              <div
                className="col-xs-12 col-sm-6 form-group left_col is"
                id="regcompanyname"
              >
                <label htmlFor="company name" className="mat-label">
                  Registered Company Name*
                </label>
                <input
                  className="form-control mat-input"
                  value={formData.RegisteredCompanyName || ""}
                  name="RegisteredCompanyName"
                  maxLength="50"
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                />
                {renderError("RegisteredCompanyName")}
              </div>
              <div className="col-xs-12 col-sm-6 form-group right_col">
                <label htmlFor="trading name" className="mat-label">
                  Trading Name
                </label>
                <input
                  className="form-control mat-input"
                  id="Tradingname"
                  value={formData.Tradingname || ""}
                  name="Tradingname"
                  maxLength="100"
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                />
                {renderError("Tradingname")}
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-6 form-group left_col"
                id="regaddress1"
              >
                <label htmlFor="address1" className="mat-label">
                  Address 1*
                </label>
                <input
                  className="form-control mat-input"
                  name="Address1"
                  id="Address1"
                  value={formData.Address1 || ""}
                  maxLength="50"
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                />
                {/* {renderError("Address1")} */}
              </div>
              <div className="col-xs-12 col-sm-6 form-group right_col">
                <label htmlFor="address2" className="mat-label">
                  Address 2
                </label>
                <input
                  className="form-control mat-input"
                  name="Address2"
                  id="Address2"
                  value={formData.Address2 || ""}
                  maxLength="50"
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                />
                {/* {renderError("Address2")} */}
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-6 form-group left_col"
                id="regcity"
              >
                <label htmlFor="city" className="mat-label">
                  City*
                </label>
                <input
                  className="form-control mat-input"
                  id="City"
                  name="City"
                  maxLength="30"
                  value={formData.City || ""}
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                />
                {renderError("City")}
              </div>
              <div
                className="col-xs-12 col-sm-6 form-group right_col is-completed"
                id="regcountryregion"
              >
                <select
                  className="form-control mat-input select_opt"
                  id="CountryRegion"
                  value={formData.CountryRegion || ""}
                  name="CountryRegion"
                  onChange={handleChange}
                  required
                >
                  {countries.map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </select>
                {renderError("CountryRegion")}
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-6 form-group left_col"
                id="regpostcode"
              >
                <label htmlFor="postcode" className="mat-label">
                  Post Code*
                </label>
                <input
                  className="form-control mat-input"
                  value={formData.Postcode || ""}
                  type="text"
                  id="Postcode"
                  name="Postcode"
                  placeholder=""
                  onChange={handleChange}
                />
                {renderError("Postcode")}
              </div>
              <div className="col-xs-12 col-sm-6 form-group right_col">
                <label htmlFor="wdl_number" className="mat-label">
                  WDA Number*
                </label>
                <input
                  className="form-control mat-input"
                  id="register_info"
                  value={formData.wdl_number || ""}
                  name="wdl_number"
                  maxLength="100"
                  type="number"
                  placeholder=""
                  onChange={handleChange}
                />
                {renderError("wdl_number")}
              </div>
            </div>

            <div className="row text-left">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    value="true"
                    name="address123"
                    className="addresses"
                    id="save_information"
                    onChange={handleCheckboxChange}
                  />
                  <span className="cr">
                    <i className="cr-icon fa fa-square"></i>
                  </span>
                  Delivery Address Details If Same or{" "}
                  <span className="addanother">Add Another</span>
                </label>
              </div>
            </div>
            {showAddressDetails && (
              <div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 form-group left_col">
                    <label htmlFor="DeliveryAddress" className="mat-label">
                      Address 1
                    </label>
                    <input
                      className="form-control mat-input"
                      id="DeliveryAddress"
                      name="DeliveryAddress"
                      value={formData.DeliveryAddress || ""}
                      maxLength="100"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                    {renderError("DeliveryAddress")}
                  </div>
                  <div className="col-xs-12 col-sm-6 form-group right_col">
                    <label htmlFor="DeliveryAddress2" className="mat-label">
                      Address 2
                    </label>
                    <input
                      className="form-control mat-input"
                      value={formData.DeliveryAddress2 || ""}
                      id="DeliveryAddress2"
                      name="DeliveryAddress2"
                      maxLength="50"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                    {renderError("DeliveryAddress2")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 form-group left_col">
                    <label htmlFor="DeliveryCity" className="mat-label">
                      City
                    </label>
                    <input
                      className="form-control mat-input"
                      value={formData.DeliveryCity || ""}
                      name="DeliveryCity"
                      id="DeliveryCity"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                    {renderError("DeliveryCity")}
                  </div>
                  <div className="col-xs-12 col-sm-6 form-group right_col is-completed">
                    <select
                      className="form-control mat-input select_opt"
                      id="DeliveryCountryRegion"
                      value={formData.DeliveryCountryRegion || ""}
                      name="DeliveryCountryRegion"
                      onChange={handleChange}
                      required
                    >
                      {countries.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    {renderError("DeliveryCountryRegion")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 form-group left_col">
                    <label htmlFor="DeliveryPostcode" className="mat-label">
                      Post Code
                    </label>
                    <input
                      className="form-control mat-input"
                      value={formData.DeliveryPostcode || ""}
                      name="DeliveryPostcode"
                      id="DeliveryPostcode"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                    {renderError("DeliveryPostcode")}
                  </div>
                </div>
              </div>
            )}

            <div className="row text-left">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    value="true"
                    name="address212"
                    className="addresses"
                    onChange={handleCollectionCheckboxChange}
                  />
                  <span className="cr">
                    <i className="cr-icon fa fa-square"></i>
                  </span>
                  Collection Address Details If Same or{" "}
                  <span className="addanother">Add Another</span>
                </label>
              </div>
            </div>

            {showCollectionAddress && (
              <div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 form-group left_col">
                    <label htmlFor="primary contact no" className="mat-label">
                      Address 1
                    </label>
                    <input
                      className="form-control mat-input"
                      id="CollectionAddress"
                      value={formData.CollectionAddress || ""}
                      name="CollectionAddress"
                      maxLength="100"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 form-group right_col">
                    <label htmlFor="primary contact no" className="mat-label">
                      Address 2
                    </label>
                    <input
                      className="form-control mat-input"
                      id="CollectionAddress2"
                      value={formData.CollectionAddress2 || ""}
                      name="CollectionAddress2"
                      maxLength="50"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 form-group left_col">
                    <label htmlFor="primary contact no" className="mat-label">
                      City
                    </label>
                    <input
                      className="form-control mat-input"
                      type="text"
                      value={formData.CollectionCity || ""}
                      placeholder=""
                      name="CollectionCity"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 form-group right_col is-completed">
                    <select
                      className="form-control mat-input select_opt"
                      id="CollectionCountryRegion"
                      value={formData.CollectionCountryRegion || ""}
                      name="CollectionCountryRegion"
                      onChange={handleChange}
                      required
                    >
                      {countries.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 form-group left_col">
                    <label htmlFor="primary contact no" className="mat-label">
                      Post Code
                    </label>
                    <input
                      className="form-control mat-input"
                      name="CollectionPostcode"
                      value={formData.CollectionPostcode || ""}
                      id="CollectionPostcode"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 form-group right_col">
                    <label htmlFor="primary contact no" className="mat-label">
                      Fax No
                    </label>
                    <input
                      className="form-control mat-input"
                      name="CollectionFaxNo"
                      value={formData.CollectionFaxNo || ""}
                      id="CollectionFaxNo"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 form-group left_col">
                    <label htmlFor="primary contact no" className="mat-label">
                      Telephone No
                    </label>
                    <input
                      className="form-control mat-input"
                      id="CollectionTelephoneNo"
                      value={formData.CollectionTelephoneNo || ""}
                      name="CollectionTelephoneNo"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 form-group right_col">
                    <label htmlFor="primary contact no" className="mat-label">
                      Primary Contact No
                    </label>
                    <input
                      className="form-control mat-input"
                      name="CollectionPrimaryContactNo"
                      value={formData.CollectionPrimaryContactNo || ""}
                      id="CollectionPrimaryContactNo"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
