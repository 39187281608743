import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'antd';

const Heading = (props) => {
  const { heading } = props;
  const { id } = useParams();
  const navigate = useNavigate()

  return (
    <div className="hdr_sec">
    <div className="heading">
      {/* <i className="fa fa-user"></i> */}
      {heading}
      {id ? (
        <span className="heading_btn" style={{
          marginLeft: "10px",
          display: "inline-flex"
        }}>
          <Button onClick={() => navigate(-1)}>Back to List</Button>
        </span>
      ) : (
        ""
      )
      }
    </div>
    </div>
  );
};

export default Heading;
