import $ from "jquery";

$(document).ready(function() {
  // Function to apply the 'is-completed' class based on the value
  function updateInputState() {
    $(".mat-input").each(function() {
      var getValue = $(this).val();
      if (getValue !== "") {
        $(this).parent().addClass("is-completed");
      } else {
        $(this).parent().removeClass("is-completed");
      }
    });
  }

  // Set a timeout to apply the class after 3 seconds
  setTimeout(function() {
    updateInputState();
  }, 1000); // 3000 milliseconds = 3 seconds
});

$(".mat-input").each(function () {
  var getValue = $(this).val();
  if (getValue != "") {
    $(this).parent().addClass("is-completed");
  }
});

$(document).on("focus", ".mat-input", function () {
  $(this).parent().addClass("is-completed");
});
$(document).on("change", "input", function () {
  if ($(".passautofill").val() != "") {
    $(".mat-input.passautofill").parent().addClass("is-completed");
  }
});
$(document).on("focusout", ".mat-input", function () {
  if ($(this).val() === "") $(this).parent().removeClass("is-completed");
});

export const dashboardWrapperStyle = {
  display: "flex",
  height: "100vh",
};

export const sidebarStyle = {
  width: "250px",
  backgroundColor: "#f8f9fa",
  padding: "15px",
  overflowY: "auto",
};

export const contentWrapperStyle = {
  flex: 1,
  margin: "70px 0 0 0",
  backgroundColor: "#ffffff",
  padding: "15px",
  overflowY: "auto",
  
};

$(document).on('click','#icon-mobile',function(){
  $('#menubar').toggleClass('open-menu');
});
var mobilesidebarToggler = $("#system_mobile_menu_toggler");
var sidebarId = $("#system_sidebar_id");

/****
 *
 *  mobile sidebar toggler
 *
 *****/

// $(mobilesidebarToggler).click(function () {
//     $(sidebarId).toggleClass("system_sidebar_active");
// });
$(document).on('click','#system_mobile_menu_toggler',function(){
  $('#system_sidebar_id').toggleClass("system_sidebar_active");
});

