import React, { useState } from "react";
import { Modal } from "antd";
import TermsConditionModel from "../../../globleComponents/TermsConditionModel";

const TermsAndConditionsOfSale = ({
  formData,
  setFormData,
  errors,
  touched,
  setErrors
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function to handle input changes
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  
    // setTouched((prevTouched) => ({
    //   ...prevTouched,
    //   [name]: true,
    // }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  
  // Show modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Hide modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div id="step8" className="step-content-body out1">
        <div className="formdv container">
          <p className="formdetailstitle">
            <span>Terms & Conditions Of Sale</span>
          </p>

          <div className="row">
            <div className="col-sm-6 form-group left_col" id="signaturedv">
              <label htmlFor="signature" className="mat-label">
                Name Of Person Completing the Form*
              </label>
              <input
                name="signature"
                value={formData.signature}
                onChange={handleChange}
                className="form-control mat-input"
                maxLength="50"
                type="text"
                placeholder=""
              />
              {touched.signature && errors.signature && (
                <span className="loaddata">{errors.signature}</span>
              )}
            </div>

            <div
              className="col-sm-6 form-group right_col"
              id="signatureemaildv"
            >
              <label htmlFor="signatureEmailAddress" className="mat-label">
                Email*
              </label>
              <input
                name="signatureEmailAddress"
                value={formData.signatureEmailAddress}
                onChange={handleChange}
                className="form-control mat-input"
                type="text"
                placeholder=""
              />
              {touched.signatureEmailAddress &&
                errors.signatureEmailAddress && (
                  <span className="loaddata">
                    {errors.signatureEmailAddress}
                  </span>
                )}
            </div>
          </div>

          <div className="margin">
            <div className="row text-left">
              <div className="checkbox">
                <label>
                  <input
                    id="termsAndConditionCheckbox"
                    name="termsAndConditionCheckbox"
                    checked={formData.termsAndConditionCheckbox || false}
                    onChange={handleChange}
                    type="checkbox"
                  />
                  <span className="cr">
                    <i className="cr-icon fa fa-square"></i>
                  </span>
                  I have read the terms for refund and returns under &nbsp;
                  <a
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#008000",
                    }}
                    onClick={showModal}
                  >
                    Terms & Conditions*
                  </a>
                </label>
                {touched.termsAndConditionCheckbox &&
                  errors.termsAndConditionCheckbox && (
                    <span className="loaddata">
                      <span className="custom-message">
                        Please review the terms and conditions.
                      </span>
                    </span>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Terms & Conditions"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        centered
      >
        <TermsConditionModel />
      </Modal>
    </div>
  );
};

export default TermsAndConditionsOfSale;
