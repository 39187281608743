import React, { useState } from "react";

const SiteVisitsAudits = ({ formData, handleChange }) => {
  return (
    <div>
      <div
        className="site-visit-details-section tabs-edits-section"
        style={{ display: "block" }}
      >
        <h3 className="form-main-title">Site Visits / Audits</h3>
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <div className="sops-title-group">
              <span>Yes</span>
              <span>No</span>
              <span>N/A</span>
            </div>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              1. Has any health authority or regulatory body inspected your
              premises in the past 12 months?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL8"
                  className="radio-check"
                  id="site_yes_1"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="site_yes_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL8"
                  className="radio-check"
                  id="site_no_1"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="site_no_1"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL8"
                  className="radio-check"
                  id="site_na_1"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="site_na_1"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-10">
            <label className="form-label-title">
              2. Do you carry out a program of self-inspections on your premises
              and systems?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL9"
                  className="radio-check"
                  id="site_yes_2"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="site_yes_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL9"
                  className="radio-check"
                  id="site_no_2"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="site_no_2"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL9"
                  className="radio-check"
                  id="site_na_2"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="site_na_2"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
        <div className="row form-group mb-0">
          <div className="col-md-10">
            <label className="form-label-title">
              3. Would you approve Propharma-UK carrying out a site audit of
              your premises if required?
              <span className="required-sign">*</span>
            </label>
          </div>
          <div className="col-md-2">
            <div className="sops-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL10"
                  className="radio-check"
                  id="site_yes_3"
                  value="YES"
                  onChange={handleChange}
                />
                <label htmlFor="site_yes_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL10"
                  className="radio-check"
                  id="site_no_3"
                  value="NO"
                  onChange={handleChange}
                />
                <label htmlFor="site_no_3"></label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="GENERAL10"
                  className="radio-check"
                  id="site_na_3"
                  value="N/A"
                  onChange={handleChange}
                />
                <label htmlFor="site_na_3"></label>
              </div>
            </div>
            <span className="error_message"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteVisitsAudits;
