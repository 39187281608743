import React from "react";
import Navbar from "../globleComponents/Navbar";
import Footer from "../globleComponents/Footer";

const DataProtectionNotice = () => {
  return (
    <>
      <Navbar />
      <div class="container">
        <div class="row aboutusdv">
          <div class="col-lg-12">
            <h1>Data Protection Notice </h1>
            <p>
              We, being the ProPharma-UK Limited, hold and process all personal
              information (“Information”) in accordance with the General Data
              Protection Regulations 2018.{" "}
            </p>
            <p>
              By submitting your Information (which may include sensitive
              personal information) to us at any time in connection with
              administering your membership and supporting membership services,
              you consent to your information being processed by us in
              accordance with this Privacy Statement. If your information
              changes or you wish to change your preferences, please inform us
              of the change so that we can update our records.
            </p>
            <h4>Members</h4>
            <p>
              We will use information to contact you in connection to the
              private prescription member services that the ProPharma-UK provide
              by post, telephone, electronically and by other means for the
              following purposes:{" "}
            </p>
            <ul>
              <li>
                to administer the relationship between you and the ProPharma-UK,
                and manage business processes provided within your membership in
                support of this (which may include using third parties)
              </li>
              <li>to promote the interests of members to the public, and </li>
              <li>
                to offer you access to facilities such as ProPharma-UK
                information and ProPharma-UK products and services.
              </li>
            </ul>

            <p>We also reserve the right: </p>
            <ul>
              <li>to use your information for statistical analysis,</li>
              <li>
                to analyse your use of our website and related services using,
                for example, “cookies” when you access those services,{" "}
              </li>
              <li>to use your information to deliver membership services, </li>
              <li>
                to communicate new and existing resources, information, products
                and services available to you within your membership,{" "}
              </li>
              <li>
                to transfer our business assets or our rights under any Group
                product (which include Information) on sale or merger of the
                whole or part of the ProPharma-UK.{" "}
              </li>
            </ul>

            <p>We may do this without contacting you, and </p>

            <ul>
              <li>
                to transfer our Information as required to obtain legal advice,
                comply with legal and regulatory requirements, protect our
                rights and property, and the safety of our employees, clients,
                suppliers and others.
              </li>
            </ul>

            <p>We will also ask your consent: </p>
            <ul>
              <li>
                to transfer Information to other companies (“Other Companies”),
                with your consent, which we screen on your behalf to make sure
                that they are offering products or services that are likely to
                be of value to pharmacy businesses and professionals. In the
                unlikely event that you find communication from these Other
                Companies unwelcome, you may at any time elect not to receive
                such communication in future. Likewise, you may elect not to
                receive marketing communications from us. Should you opt out of
                either, you will not have to opt out again upon renewal of your
                membership.{" "}
              </li>
            </ul>

            <p>
              You must obtain consent from and show this privacy statement to
              anyone whose personal information you have submitted to us as part
              of your joining or renewal information. You have the right to
              request copies of the Information we hold about you. If you would
              like to know what information we hold about you, contact the
              Compliance Manager, ProPharmaUK, Earlsfield, London SW18 4LT, UK.
            </p>
            <p>We will charge a fee of £10 for this. </p>

            <h4>Customers and other contacts </h4>
            <p>
              Others who receive or provide information, products and services
              to/from the ProPharmaUK which include, but are to limited to,
              Contracted Customers, Associates, Students, Affiliates, Suppliers
              and LPCs will receive information relevant to their preferences
              from the ProPharma-UK provide by post, telephone, electronically
              and by other means for the following purposes:{" "}
            </p>

            <ul>
              <li>
                to administer the relationship between you and the ProPharma-UK,
                and manage business processes you have subscribed to or
                contracted through the ProPharma-UK in support of this (which
                may include using third parties),
              </li>
              <li>to provide Community Pharmacy Sector information, and</li>
              <li>
                to offer you access to facilities such as ProPharma-UK
                information and ProPharma-UK products and services
              </li>
            </ul>

            <h4>We also reserve the right: </h4>
            <ul>
              <li>
                to analyse your use of our website and related services using,
                for example, “cookies” when you access those services,{" "}
              </li>
              <li>
                to communication new and existing resources, information,
                products and services available to you which you have consented
                to, and
              </li>
              <li>
                to transfer our Information as required to obtain legal advice,
                comply with legal and regulatory requirements, protect our
                rights and property, and the safety of our employees, clients,
                suppliers and others.
              </li>
            </ul>

            <h4>We will also ask your consent: </h4>
            <ul>
              <li>
                to transfer Information to other companies (“Other Companies”),
                with your consent, which we screen on your behalf to make sure
                that they are offering products or services that are likely to
                be of value to pharmacy businesses and professionals. In the
                unlikely event that you find communication from these Other
                Companies unwelcome, you may at any time elect not to receive
                such communication in future. Likewise, you may elect not to
                receive marketing communications from us. Should you opt out of
                either, you will not have to opt out again upon renewal of your
                membership{" "}
              </li>
            </ul>

            <p>
              You must obtain consent from and show this privacy statement to
              anyone whose personal information you have submitted to us as part
              of your joining or renewal information. You have the right to
              request copies of the Information we hold about you. If you would
              like to know what information we hold about you, contact the
              contact the Compliance Manager, ProPharma-UK, Earlsfield, London
              SW18 4LT, UK.
            </p>
            <p>We will charge a fee of £10 for this. </p>

            <h4>The ProPharma-UK Limited comprises: </h4>
            <p>
              ProPharma-UK
              <br />
              Saturn Pharmacy
            </p>
            <p>
              The ProPharma-UK follow a Data Protection Breach Reporting process
              and train its staff to recognise when a breach has occurred, to
              avoid Data Protection breaches and how to report to the relevant
              supervisory authority.{" "}
            </p>
            <p>
              Please refer also to the{" "}
              <u>ProPharma-UK Cookie and Website Usage Policy</u>, and the{" "}
              <u>ProPharma-UK Terms &amp; Conditions</u>.{" "}
            </p>

            <p>Date: 12/07/2024 </p>
            <p>
              Copyright © 2012-2018 ProPharma-UK Limited
              <br />
              unit 1 Windsor Park,
              <br />
              50 Windsor Avenue,
              <br />
              London, SW19 2TJ, UK
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DataProtectionNotice;
