export const baseURL = process.env.REACT_APP_API_URL;

export const api = {
  // admin apis end points

  // GP forms add and listing apis
  CreateGPregistration: `${baseURL}/api/registrations/gp-surgery-registrations`,
  GPregistrationsList: `${baseURL}/api/registrations/gp-surgery-registrations`,

  // Private Clinics forms add and listing apis
  createPrivateClinicsRegistrations: `${baseURL}/api/registrations/private-clinic-registrations`,
  privateClinicsList: `${baseURL}/api/registrations/private-clinic-registrations`,

  // pharmacy forms add and listing apis
  CreatePharmacyRegistrations: `${baseURL}/api/registrations/pharmacy-registrations`,
  pharmacyRegistrationsList: `${baseURL}/api/registrations/pharmacy-registrations`,

  // customer forms add and listing apis
  CreateCustomerRegistrations: `${baseURL}/api/registrations/customer-registrations`,
  customerRegistrationsList: `${baseURL}/api/registrations/customer-registrations`,

  // auth apis
  login: `${baseURL}/api/auth/login`,
};
