import React, { useState } from "react";

const GDPQuestionnaire = ({formData, setFormData}) => {
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    switch (type) {
      case "radio":
        // For radio buttons, update the form data with the selected value
        setFormData({ ...formData, [name]: value });
        break;
      case "checkbox":
        // For checkboxes, handle checked state
        setFormData({ ...formData, [name]: checked });
        break;
      case "date":
        // For date inputs, use the value as is
        setFormData({ ...formData, [name]: value });
        break;
      case "text":
      case "email":
      case "number":
        // Add other input types you might be using
        // For text inputs, use the value as is
        setFormData({ ...formData, [name]: value });
        break;
      default:
        // For any other input types, fall back to default handling
        setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div id="step7" className="step-content-body out1">
      <div className="formdv container">
        <p className="formdetailstitle">
          <span>GDP Questionnaire</span>
        </p>
        <div className="table-responsive">
          <table className="table custom-table-style">
            <tbody>
              <tr>
                <td>
                  Does your company operate to current EU guidelines on GDP?
                </td>
                <td>
                  <input
                    name="GENERAL1"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL1 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL1"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL1 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Does your company have a Quality Manual and/or a Site Master
                  File?
                </td>
                <td>
                  <input
                    name="GENERAL2"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL2 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL2"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL2 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Does your company have a formal Quality Management System in
                  place?
                </td>
                <td>
                  <input
                    name="GENERAL3"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL3 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL3"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL3 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Is your company allowed to distribute narcotic & psychotropic
                  drugs (Controlled Drugs)?
                  <br /> If yes, please attach a copy. <br />
                  <span
                    id="collection_upload"
                    style={{ display: "none", float: "right" }}
                  >
                    <input
                      type="File"
                      name="drug_colllection"
                      className="drug_colllection"
                    />
                  </span>
                </td>
                <td>
                  <input
                    name="GENERAL4"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL4 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL4"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL4 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Does your company operate to Standard Operating Procedures?
                </td>
                <td>
                  <input
                    name="GENERAL6"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL6 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL6"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL6 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Is your company allowed to distribute Cold Chain products?
                </td>
                <td>
                  <input
                    name="GENERAL5"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL5 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL5"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL5 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Is there a formal process for the control, review and updating
                  of SOPs?
                </td>
                <td>
                  <input
                    name="GENERAL7"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL7 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL7"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL7 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Will you allow a company representative to perform an
                  inspection of your facility?
                </td>
                <td>
                  <input
                    name="GENERAL8"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL8 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL8"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL8 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Has any health authority or regulatory body inspected your
                  facility within the past 24 months?
                </td>
                <td>
                  <input
                    name="GENERAL9"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL9 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL9"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL9 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>If yes, what date was the inspection?</td>
                <td>
                  <div className="input-group date">
                    <span className="input-group-addon bg-white">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      className="form-control"
                      type="date"
                      name="GENERAL10"
                      id="start_date"
                      placeholder="Select date"
                      style={{ cursor: "pointer" }}
                      value={formData.GENERAL10}
                      onChange={handleChange}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  What is the name of the Regulatory Body that performed the
                  inspection?
                </td>
                <td>
                  <input
                    name="GENERAL11"
                    type="text"
                    required
                    value={formData.GENERAL11}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Does your company carry out self-inspections of your facility
                  and systems?
                </td>
                <td>
                  <input
                    name="GENERAL12"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL12 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL12"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL12 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Does your company maintain an Approved Suppliers List?</td>
                <td>
                  <input
                    name="GENERAL13"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL13 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL13"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL13 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Does your system allow you to use Unapproved Suppliers?</td>
                <td>
                  <input
                    name="GENERAL14"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL14 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL14"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL14 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Is your warehouse Temperature Controlled?</td>
                <td>
                  <input
                    name="GENERAL15"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL15 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL15"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL15 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Does your company use Temperature Monitoring Equipment?</td>
                <td>
                  <input
                    name="GENERAL16"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL16 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL16"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL16 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Is your Temperature Monitoring Equipment calibrated?</td>
                <td>
                  <input
                    name="GENERAL17"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL17 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL17"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL17 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Does your company keep Temperature records?</td>
                <td>
                  <input
                    name="GENERAL18"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL18 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL18"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL18 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Are products checked for Falsification/Counterfeit medicines?
                </td>
                <td>
                  <input
                    name="GENERAL19"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL19 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL19"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL19 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Does your company have an emergency plan for Product Recalls?
                </td>
                <td>
                  <input
                    name="GENERAL20"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL20 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL20"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL20 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Does your company have a Quarantine system in place?</td>
                <td>
                  <input
                    name="GENERAL21"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL21 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL21"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL21 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Does your company use a system to maintain the Traceability of
                  products?
                </td>
                <td>
                  <input
                    name="GENERAL22"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL22 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL22"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL22 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  Does your company operate a "First expiry, First Out" system?
                </td>
                <td>
                  <input
                    name="GENERAL23"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL23 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL23"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL23 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Does your company provide GDP training to staff?</td>
                <td>
                  <input
                    name="GENERAL24"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL24 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL24"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL24 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Is your staff trained to handle cold chain products?</td>
                <td>
                  <input
                    name="GENERAL25"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL25 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL25"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL25 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>Does your company have a pest control system in place?</td>
                <td>
                  <input
                    name="GENERAL26"
                    value="Yes"
                    type="radio"
                    checked={formData.GENERAL26 === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
                  <input
                    name="GENERAL26"
                    value="No"
                    type="radio"
                    checked={formData.GENERAL26 === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;No
                </td>
              </tr>
              <tr>
                <td>How often is your facility inspected for pests?</td>
                <td>
                  <input
                    name="GENERAL27"
                    type="text"
                    required
                    value={formData.GENERAL27}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GDPQuestionnaire;
