import React from "react";

const PharmacyRegistrationTeamForm = ({ formData, errors, touched, setFormData, handleChange }) => {
  return (
    <div className="formdv container">
      <p className="formdetailstitle">
        <span>Your Team</span>
      </p>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="superintendentPharmacistName" className="mat-label">
            Superintendent Pharmacist Name
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.superintendentPharmacistName && touched.superintendentPharmacistName
                ? "is-invalid"
                : ""
            }`}
            id="superintendentPharmacistName"
            name="superintendentPharmacistName"
            placeholder=""
            value={formData.superintendentPharmacistName}
            onChange={handleChange}
          />
          {errors.superintendentPharmacistName && touched.superintendentPharmacistName && (
            <span className="loaddata">{errors.superintendentPharmacistName}</span>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 form-group right_col">
          <label htmlFor="superintendentPharmacistGPHCNumber" className="mat-label">
            Superintendent Pharmacist GPHC Number
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.superintendentPharmacistGPHCNumber && touched.superintendentPharmacistGPHCNumber
                ? "is-invalid"
                : ""
            }`}
            id="superintendentPharmacistGPHCNumber"
            name="superintendentPharmacistGPHCNumber"
            placeholder=""
            value={formData.superintendentPharmacistGPHCNumber}
            onChange={handleChange}
          />
          {errors.superintendentPharmacistGPHCNumber && touched.superintendentPharmacistGPHCNumber && (
            <span className="loaddata">{errors.superintendentPharmacistGPHCNumber}</span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="residentPharmacistName" className="mat-label">
            Name of Resident Pharmacist
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.residentPharmacistName && touched.residentPharmacistName
                ? "is-invalid"
                : ""
            }`}
            id="residentPharmacistName"
            name="residentPharmacistName"
            placeholder=""
            value={formData.residentPharmacistName}
            onChange={handleChange}
          />
          {errors.residentPharmacistName && touched.residentPharmacistName && (
            <span className="loaddata">{errors.residentPharmacistName}</span>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 form-group right_col">
          <label htmlFor="residentPharmacistGPHCNumber" className="mat-label">
            Their GPHC Registration Number
          </label>
          <input
            type="text"
            className={`form-control mat-input ${
              errors.residentPharmacistGPHCNumber && touched.residentPharmacistGPHCNumber
                ? "is-invalid"
                : ""
            }`}
            id="residentPharmacistGPHCNumber"
            name="residentPharmacistGPHCNumber"
            placeholder=""
            value={formData.residentPharmacistGPHCNumber}
            onChange={handleChange}
          />
          {errors.residentPharmacistGPHCNumber && touched.residentPharmacistGPHCNumber && (
            <span className="loaddata">{errors.residentPharmacistGPHCNumber}</span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 form-group left_col">
          <label htmlFor="residentPharmacistEmail" className="mat-label">
            Resident Pharmacist Email
          </label>
          <input
            type="email"
            className={`form-control mat-input ${
              errors.residentPharmacistEmail && touched.residentPharmacistEmail
                ? "is-invalid"
                : ""
            }`}
            id="residentPharmacistEmail"
            name="residentPharmacistEmail"
            placeholder=""
            value={formData.residentPharmacistEmail}
            onChange={handleChange}
          />
          {errors.residentPharmacistEmail && touched.residentPharmacistEmail && (
            <span className="loaddata">{errors.residentPharmacistEmail}</span>
          )}
        </div>
        <div className="col-xs-12 col-sm-6 form-group right_col">
          <label htmlFor="completionDate" className="mat-label">
            Completion Date of the Form
          </label>
          <input
            type="date"
            className={`form-control mat-input ${
              errors.completionDate && touched.completionDate
                ? "is-invalid"
                : ""
            }`}
            id="completionDate"
            name="completionDate"
            placeholder=""
            value={formData.completionDate}
            onChange={handleChange}
          />
          {errors.completionDate && touched.completionDate && (
            <span className="loaddata">{errors.completionDate}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PharmacyRegistrationTeamForm;
