import { Link } from "react-router-dom";

export const RegisterBox = ({ to, imgSrc, altText, text }) => (
  <Link to={to}>
    <div className="registerbox">
      <img src={imgSrc} alt={altText} className="img-centered" />
      <h4>{text}</h4>
    </div>
  </Link>
);
