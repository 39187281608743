import React from 'react';

const SearchInput = ({ searchTerm, setSearchTerm }) => {
  return (
    <input
      style={{ height: "32px", width: "256px" }}
      type="search"
      className="form-control input-sm"
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  );
};

export default SearchInput;
