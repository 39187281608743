import React, { useEffect, useState } from "react";
import GPSurgeryRegistrationForm from "./GPSurgeryRegistration/GPSurgeryRegistrationForm";
import Navbar from "../../globleComponents/Navbar";
import Footer from "../../globleComponents/Footer";
import TeamForm from "./GPSurgeryRegistration/YourTeamForm";
import CommentSection from "./GPSurgeryRegistration/CommentSection";
import axios from "axios";
import { toast } from "react-toastify";
import { api } from "../../config/api";
import { useNavigate, useParams } from "react-router-dom";
import { token } from "../../config/globleData";
import { Spin } from "antd";

const initialFormData = {
  surgeryName: "",
  Address1: "",
  Postcode: "",
  ownedBy: "",
  tradingAs: "",
  websiteURL: "",
  vatNo: "",
  companyRegistrationNo: "",
  CQCRegistrationName: "",
  contactPerson: "",
  contactNumber: "",
  contactEmail: "",
  partnerName: "",
  directorName: "",
  directorEmail: "",
  professionals: [{ name: "", authority: "", registrationNumber: "" }],
  accountsEmail: "",
  accountsContactPerson: "",
  formCompletedBy: "",
  completionDate: "",
  comment: "",
  // showDisclaimer: true,
  type: "GP Surgery Registration",
};

const validateFormData = (data) => {
  const errors = {};
  const requiredFields = [
    "surgeryName",
    "Address1",
    "Postcode",
    "ownedBy",
    "tradingAs",
    "websiteURL",
    "vatNo",
    "companyRegistrationNo",
    "CQCRegistrationName",
    "contactPerson",
    "contactNumber",
    "contactEmail",
    "partnerName",
    "directorName",
    "directorEmail",
    "accountsEmail",
    "accountsContactPerson",
    "formCompletedBy",
    "completionDate",
    "professionals",
  ];

  requiredFields.forEach((field) => {
    if (!data[field]) {
      errors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
    }
  });

  if (
    data.websiteURL &&
    !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(data.websiteURL)
  ) {
    errors.websiteURL = "Invalid URL format";
  }

  let errorMessages = [];

  if (data.professionals) {
    data.professionals.forEach((professional, index) => {
      let hasError = false;
      if (!professional.name) {
        const errorMessage = `Professional ${index + 1}: Name is required`;
        errors[`professionals[${index}].name`] = errorMessage;
        errorMessages.push(errorMessage);
        hasError = true;
      }
      if (!professional.authority) {
        const errorMessage = `Professional ${index + 1}: Authority is required`;
        errors[`professionals[${index}].authority`] = errorMessage;
        errorMessages.push(errorMessage);
        hasError = true;
      }
      if (!professional.registrationNumber) {
        const errorMessage = `Professional ${
          index + 1
        }: Registration Number is required`;
        errors[`professionals[${index}].registrationNumber`] = errorMessage;
        errorMessages.push(errorMessage);
        hasError = true;
      }
    });

    if (errorMessages.length > 0) {
      // Show a single toast with all error messages
      toast.error(
        <div>
          {errorMessages.map((msg, i) => (
            <div key={i}>{msg}</div>
          ))}
        </div>
      );
    }
  }

  return errors;
};
const GPSurgeryRegistration = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(id !== undefined);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${api.GPregistrationsList}/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setFormData(response.data);
        } catch (error) {
          toast.error("Failed to fetch data");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setTouched({});

    const validationErrors = validateFormData(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const touchedFields = Object.keys(validationErrors).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      setTouched(touchedFields);
      return;
    }

    setIsSubmitting(true);
    try {
      const url = id
        ? `${api.GPregistrationsList}/${id}`
        : api.CreateGPregistration;
      const method = id ? "put" : "post";
      const response = await axios[method](url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(response.data.message);
      navigate(`/GP-Surgery-register-details/${response.data.registration._id}`); // Navigate back to the view page after successful submission
    } catch (error) {
      toast.error(`Error submitting form: ${error.message}`);
      setErrors(error.response?.data?.errors || {});
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
          width: "100vw", // Full viewport width
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="background-container">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <GPSurgeryRegistrationForm
              formData={formData}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
            />
            <TeamForm
              formData={formData}
              handleChange={handleChange}
              errors={errors}
              setFormData={setFormData}
              touched={touched}
              setTouched={setTouched}
            />
            <CommentSection
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
            />
            <div className="step-content-foot">
              <button
                type="submit"
                className="active out1"
                disabled={isSubmitting}
                id="changebutton"
              >
                {isSubmitting ? "Submitting..." : "Finish"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GPSurgeryRegistration;
