import React, { useState, useRef, useEffect } from "react";

const Agreement = ({ formData, setFormData }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    initializeCanvas();
  }, []);

  const initializeCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.strokeStyle = "#000";
      ctx.lineWidth = 2;
      ctx.lineCap = "round";
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    const ctx = canvasRef.current.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = nativeEvent;
    const ctx = canvasRef.current.getContext("2d");
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    if (canvas) {
      const dataURL = canvas.toDataURL();
      setFormData((prevData) => ({
        ...prevData,
        signature: dataURL,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Reset form state
    setFormData({
      name: "",
      date: "",
      position: "",
      signature: "",
      iAgree: false,
    });
    initializeCanvas();
  };

  const clearSignature = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setFormData((prevData) => ({ ...prevData, signature: "" }));
  };

  return (
    <div>
      <div
        className="agreement-details-section tabs-edits-section"
        style={{ display: "block" }}
      >
        <h3 className="form-main-title">Agreement</h3>
        <p className="form-body-title">
          By confirming the following statements, you will contribute to
          maintaining a high level of standards and quality in the trading
          business of medicines in the European Economic Area (EEA).
        </p>
        <ul class="agreement-point-section">
          <li>
            1. You agree to have and maintain the legal authorisation granted by
            the MHRA to sell pharmaceutical products, such as a valid wholesale
            dealer’s license.
          </li>
          <li>
            2. You agree to notify Propharma-UK Ltd about revocation, expiration
            and any other changes.
          </li>
          <li>
            3. You trade in respect and accordance with the Good Distribution
            Practices.
          </li>
          <li>
            4. You confirm that the products supplied to Propharma-UK Ltd are
            entitled to be sold in the EEA to authorised persons and that you
            will maintain good product traceability in cases of a product
            recall.
          </li>
          <li>
            5. You will inform Propharma-UK Ltd immediately of every product
            recall.
          </li>
          <li>
            6. You confirm that the products supplied to Propharma-UK Ltd come
            from authorised suppliers within the EEA who themselves maintains a
            good product traceability. (Supplier Use Only)
          </li>
          <li>
            7. You confirm that the products supplied to Propharma-UK Ltd are
            not counterfeit products, and that you maintain a strong process of
            control and prevention to avoid any counterfeit from entering the
            distribution channel.
          </li>
          <li>
            8. You confirm that the cold chain has been carefully inspected
            until the goods are at our disposal.
          </li>
          <li>
            9. You confirm that the ambient storage of pharmaceuticals is
            controlled and monitored to stay between +8°C to +25°C
          </li>
          <li>
            10. You confirm that all temperature-controlled products are stored
            between +2°C to +8°C in cool chamber or fridge.
          </li>
          <li class="checkbox-group mb-0">
            <input
              type="checkbox"
              name="GENERAL21"
              class="checkbox-check"
              id="i_agree"
              value="YES"
            />
            <label for="i_agree">
              I agree to the above statement:<span class="reuired-sign">*</span>
            </label>
          </li>
          <span class="error_message"></span>
        </ul>
        <span className="border-separate-row"></span>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label-title">
                  Name<span className="required-sign">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="form-control validation"
                />
                <span className="error_message"></span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label-title">
                  Date<span className="required-sign">*</span>
                </label>
                <input
                  type="text"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="form-control validation"
                  autoComplete="off"
                />
                <span className="error_message"></span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label-title">
                  Position<span className="required-sign">*</span>
                </label>
                <input
                  type="text"
                  name="position"
                  value={formData.position}
                  onChange={handleChange}
                  className="form-control validation"
                />
                <span className="error_message"></span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group mb-0">
                <label className="form-label-title">
                  Signature<span className="required-sign">*</span>
                </label>
                <div id="signature-pad" class="signature-group">
                  <canvas
                    style={{ touchAction: "none" }}
                    ref={canvasRef}
                    width={300}
                    height={150}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={endDrawing}
                    onMouseOut={endDrawing}
                  ></canvas>
                  <button
                    type="button"
                    id="clear"
                    class="cancel-sign-btn"
                    onClick={clearSignature}
                  >
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
                <span className="error_message"></span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Agreement;
