import React from "react";

const CompanyDetails = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div>
      <h3 className="form-main-title">Company Details</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label-title">
              Name<span className="reuired-sign">*</span>
            </label>
            <input
              type="text"
              name="registeredcompanyname"
              value={formData.registeredcompanyname}
              onChange={handleChange}
              className="form-control validation"
            />
            <span className="error_message"></span>
          </div>
          <div className="form-group">
            <label className="form-label-title">
              Registered Address<span className="reuired-sign">*</span>
            </label>
            <textarea
              name="Address1"
              value={formData.Address1}
              onChange={handleChange}
              className="form-control validation"
              rows="4"
            ></textarea>
            <span className="error_message"></span>
          </div>
          <div className="form-group mb-0">
            <label className="form-label-title">
              Site Address (If Different)
            </label>
            <textarea
              name="Address2"
              value={formData.Address2}
              onChange={handleChange}
              className="form-control"
              rows="4"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
