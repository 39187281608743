import React from "react";

const PrivateClinicRegistrationForm = ({
  formData,
  setFormData,
  errors,
  touched,
  handleInputChange,
}) => {
  return (
    <div id="step4" className="step-content-body out1">
      <div className="formdv container">
        <p className="formdetailstitle">
          <span>Private Clinics/Occupational Health Registration</span>
        </p>
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="clinicName" className="mat-label">
              Clinic Name
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.clinicName && touched.clinicName ? "is-invalid" : ""
              }`}
              value={formData.clinicName}
              onChange={handleInputChange}
              id="clinicName"
              name="clinicName"
              placeholder=""
            />
            {errors.clinicName && touched.clinicName && (
              <span className="loaddata">{errors.clinicName}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="deliveryAddress" className="mat-label">
              Registered Delivery Address
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.deliveryAddress && touched.deliveryAddress
                  ? "is-invalid"
                  : ""
              }`}
              value={formData.deliveryAddress}
              onChange={handleInputChange}
              id="deliveryAddress"
              name="deliveryAddress"
              placeholder=""
            />
            {errors.deliveryAddress && touched.deliveryAddress && (
              <span className="loaddata">{errors.deliveryAddress}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="postcode" className="mat-label">
              Postcode
            </label>
            <input
              type="number"
              className={`form-control mat-input ${
                errors.postcode && touched.postcode ? "is-invalid" : ""
              }`}
              value={formData.postcode}
              onChange={handleInputChange}
              id="postcode"
              name="postcode"
              placeholder=""
            />
            {errors.postcode && touched.postcode && (
              <span className="loaddata">{errors.postcode}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="ownedBy" className="mat-label">
              Owned By
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.ownedBy && touched.ownedBy ? "is-invalid" : ""
              }`}
              value={formData.ownedBy}
              onChange={handleInputChange}
              id="ownedBy"
              name="ownedBy"
              placeholder=""
            />
            {errors.ownedBy && touched.ownedBy && (
              <span className="loaddata">{errors.ownedBy}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="tradingAs" className="mat-label">
              Trading As
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.tradingAs && touched.tradingAs ? "is-invalid" : ""
              }`}
              value={formData.tradingAs}
              onChange={handleInputChange}
              id="tradingAs"
              name="tradingAs"
              placeholder=""
            />
            {errors.tradingAs && touched.tradingAs && (
              <span className="loaddata">{errors.tradingAs}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="websiteURL" className="mat-label">
              Website URL
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.websiteURL && touched.websiteURL ? "is-invalid" : ""
              }`}
              value={formData.websiteURL}
              onChange={handleInputChange}
              id="websiteURL"
              name="websiteURL"
              placeholder=""
            />
            {errors.websiteURL && touched.websiteURL && (
              <span className="loaddata">{errors.websiteURL}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="vatNo" className="mat-label">
              VAT No
            </label>
            <input
              type="number"
              className={`form-control mat-input ${
                errors.vatNo && touched.vatNo ? "is-invalid" : ""
              }`}
              value={formData.vatNo}
              onChange={handleInputChange}
              id="vatNo"
              name="vatNo"
              placeholder=""
            />
            {errors.vatNo && touched.vatNo && (
              <span className="loaddata">{errors.vatNo}</span>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 form-group right_col">
            <label htmlFor="companyRegistrationNo" className="mat-label">
              Company Registration No
            </label>
            <input
              type="number"
              className={`form-control mat-input ${
                errors.companyRegistrationNo && touched.companyRegistrationNo
                  ? "is-invalid"
                  : ""
              }`}
              value={formData.companyRegistrationNo}
              onChange={handleInputChange}
              id="companyRegistrationNo"
              name="companyRegistrationNo"
              placeholder=""
            />
            {errors.companyRegistrationNo && touched.companyRegistrationNo && (
              <span className="loaddata">{errors.companyRegistrationNo}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 form-group left_col">
            <label htmlFor="CQCRegistrationName" className="mat-label">
              CQC Registration Name
            </label>
            <input
              type="text"
              className={`form-control mat-input ${
                errors.CQCRegistrationName && touched.CQCRegistrationName
                  ? "is-invalid"
                  : ""
              }`}
              value={formData.CQCRegistrationName}
              onChange={handleInputChange}
              id="CQCRegistrationName"
              name="CQCRegistrationName"
              placeholder=""
            />
            {errors.CQCRegistrationName && touched.CQCRegistrationName && (
              <span className="loaddata">{errors.CQCRegistrationName}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateClinicRegistrationForm;
