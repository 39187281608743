import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CSidebar,
  CSidebarNav,
  CSidebarHeader,
  CSidebarBrand,
  CNavItem,
  CNavGroup,
  CSidebarToggler,
} from "@coreui/react";
import { cilSpeedometer, cilPuzzle } from "@coreui/icons";
import { CIcon } from "@coreui/icons-react";
import "@coreui/coreui/dist/css/coreui.min.css";
import { Modal } from "antd";

const Sidebar = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(
    JSON.parse(localStorage.getItem("isSubMenuOpen")) || true
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("isSubMenuOpen", JSON.stringify(isSubMenuOpen));
  }, [isSubMenuOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    localStorage.removeItem("token");
    toast.error("Logged out successfully!");
    navigate("/");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen((prevState) => !prevState);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <CSidebar
        className="leftbar"
        colorScheme="dark"
        style={{ height: "100vh" }}
      >
        <div className="mobile-menu md:hidden">
          <div className="mobile-menu-bar">
            <a href="#" className="flex mr-auto">
              <span className="logotxt-admin1">PRO</span>
              <span className="logotxt-admin2">PHARMA</span>
            </a>
            <a href="javascript:;" id="system_mobile_menu_toggler">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <CSidebarHeader className="border-bottom">
          <CSidebarBrand>
            <div>
              <span className="logotxt-admin1">PRO</span>
              <span className="logotxt-admin2">PHARMA</span>
            </div>
          </CSidebarBrand>
        </CSidebarHeader>
        <div className="" id="system_sidebar_id">
          <CSidebarNav>
            {/* <Link to="/admin/dashboard">
            <CNavItem
              to="/admin/dashboard"
              onClick={() => navigate("/admin/dashboard")}
              active={isActive("/admin/dashboard")}
            >
              <CIcon customClassName="nav-icon" icon={cilSpeedometer} />{" "}
              Dashboard
            </CNavItem>
          </Link> */}
            {/* <CNavGroup
            toggler={
              <>
                <CIcon customClassName="nav-icon" icon={cilPuzzle} />{" "}
                Registrations
              </>
            }
            visible={isSubMenuOpen}
            onClick={toggleSubMenu}
          > */}
            <CNavItem
              to="/admin/gp-surgery-list"
              onClick={() => navigate("/admin/gp-surgery-list")}
              active={isActive("/admin/gp-surgery-list")}
            >
              <i class="fa fa-stethoscope" aria-hidden="true"></i>
              <span>GP Surgery</span>
            </CNavItem>
            <CNavItem
              to="/admin/private-clinics-list"
              onClick={() => navigate("/admin/private-clinics-list")}
              active={isActive("/admin/private-clinics-list")}
            >
              <i class="fa fa-hospital-o" aria-hidden="true"></i>
              <span>Private Clinics</span>
            </CNavItem>
            <CNavItem
              to="/admin/pharmacy-list"
              onClick={() => navigate("/admin/pharmacy-list")}
              active={isActive("/admin/pharmacy-list")}
            >
              <i class="fa fa-plus-square" aria-hidden="true"></i>

              <span>Pharmacy </span>
            </CNavItem>
            {/* <CNavItem
              to="/admin/customer-list"
              onClick={() => navigate("/admin/customer-list")}
              active={isActive("/admin/customer-list")}
            >
              Customer Collection
            </CNavItem> */}
            {/* </CNavGroup> */}
            <CNavItem to="/admin/dashboard" onClick={showModal}>
              {/* <CIcon customClassName="nav-icon" icon={cilSpeedometer} /> */}
              <i class="fa fa-sign-out" aria-hidden="true"></i>
              <span> Logout</span>
            </CNavItem>
          </CSidebarNav>
          <CSidebarHeader className="border-top">
            <CSidebarToggler />
          </CSidebarHeader>
        </div>
      </CSidebar>

      <Modal
        title="Are you sure you want to logout?"
        visible={isModalOpen}
        okText="Yes"
        onOk={handleOk}
        onCancel={handleCancel}
      ></Modal>
    </>
  );
};

export default Sidebar;
