import React from "react";
import OwlCarousel from "react-owl-carousel";
import { clientLogos, options } from "../config/globleData";

const CarouselSlider = () => {
  return (
    <div>
      <aside className="clients">
        <div className="container">
          <div className="row clientlogo_margin">
            <OwlCarousel className="owl-theme" {...options}>
              {clientLogos.map((logo, index) => (
                <div className="item" key={index}>
                  <img src={logo} alt={`Client Logo ${index + 1}`} />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default CarouselSlider;
