import React from "react";
import WholesaleValidationForm from "./WholesaleValidationForm";
import Navbar from "../../../globleComponents/Navbar";
import Footer from "../../../globleComponents/Footer";
import ContactUS from "../../../globleComponents/ContactUS";

const WholesaleValidationQuestionnaire = () => {
  return (
    <div>
      <Navbar />
      <WholesaleValidationForm />
      <ContactUS />
      <Footer />
    </div>
  );
};

export default WholesaleValidationQuestionnaire;
