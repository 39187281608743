import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DateRangePicker = ({ setStartDate, setEndDate }) => {
  return (
    <RangePicker
      placeholder={["Start date", "End date"]}
      format="YYYY-MM-DD"
      onChange={(dates, dateStrings) => {
        setStartDate(dateStrings[0]);
        setEndDate(dateStrings[1]);
      }}
      disabledDate={(current) => current && current > moment().endOf("day")}
    />
  );
};

export default DateRangePicker;
